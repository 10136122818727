import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsCheckboxConfig } from '@cds/ng-core/checkbox';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { MliAnalyticsService } from '@mli/analytics';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MakePaymentComponent } from '../_modal/make-payment/make-payment.component';
import { PolicyComponent } from '../_modal/policy/policy.component';
import { StatementComponent } from '../_modal/statement/statement.component';
import { CustomerInfoDetails } from '../_model/customer-info-details.model';
import { CustomerDetails } from '../_model/customerdetails-model';
import { ErrorServicesService } from '../_services/error-services.service';
import { GeneralService } from '../_services/general.service';
import { DbsRedirectionService } from '../_shared/dbs-redirection.service';
import { SharedService } from '../_shared/shared.service';

declare var require: any;
const FileSaver = require('file-saver');
@Component({
  selector: 'app-application-summary',
  templateUrl: './application-summary.component.html',
  styleUrls: ['./application-summary.component.scss']
})
export class ApplicationSummaryComponent implements OnInit, OnDestroy {
  @Output() selectedIndex = new EventEmitter();
  checkboxConfig: CdsCheckboxConfig = {};
  customer: any = {};
  customerDetails: any = {};
  customerInfoDetailReq: CustomerInfoDetails = new CustomerInfoDetails();
  calculatedProduct: any = {};
  beneficiary: any = {};
  paymentMethod: any = {};
  applicationSummary: any = {};
  hasChecked = false;
  errorContinued = false;
  applicationIsEmpty = true;
  hasPopClicked = false; // For Testing
  steps: any = [];
  destroySub = new Subject();
  model: CustomerDetails = {};
  currentDate: any = new Date();
  dueDate: any = new Date();
  policyDetails: any = {
    policeNo: '123456789'
  };

  newYear = 0;
  newMonth = 0;
  newDay = 0;

  continueConfig: CdsButtonConfig = {
    label: 'Lakukan Pembayaran',
    buttonType: 'button',
    style: 'primary'
  };
  errorButtonConfig: CdsButtonConfig = {
    label: 'Go To Error Page Test',
    buttonType: 'button',
    style: 'primary'
  };
  backConfig: CdsButtonConfig = {
    label: 'Kembali',
    buttonType: 'button',
    style: 'secondary'
  };
  // ForTesting
  hasPopUpClicked = false;
  hasLanjutClicked = false;
  hasKembaliClicked = false;
  dbsParams: any = {};


  constructor(
    private _router: Router,
    private cdsPopup: CdsPopupService,
    public _sharedService: SharedService,
    private _service: GeneralService,
    public datePipe: DatePipe,
    private mliAnalyticsService: MliAnalyticsService,
    private _dbsSharedService: DbsRedirectionService,
    private _error: ErrorServicesService
  ) { }

  ngOnInit(): void {
    sessionStorage.setItem('current-step', JSON.stringify(3));
    this.steps = JSON.parse(sessionStorage.getItem('completed-steps') || '[]');
    this.applicationSummary = JSON.parse(sessionStorage.getItem("Application Summary") || '{}');
    if (Object.keys(this.applicationSummary).length === 0 && this.applicationSummary.constructor === Object) {
      this.hasChecked = false;
      this.applicationIsEmpty = true;
    } else {
      this.hasChecked = JSON.parse(sessionStorage.getItem('Application Summary') || '{}').checkedStatus === 'checked' ? true : false;
      this.applicationIsEmpty = false;
    }

    if (sessionStorage.getItem('CustomerDetails')) {
      this.customer = JSON.parse(sessionStorage.getItem('CustomerDetails') || '{}');
      this.customerInfoDetailReq = {
        fields: ['fullName', 'dob', 'docNo', 'placeOfBirth', 'customerEmail', 'phone', 'premiumAmount', 'sumInsured', 'policyTerm', 'beneficiary', 'relationship', 'promoCode', 'customerID', 'adobeMarketingCloudId', 'adobeId']
      };
      try {
        this._service.findById(this.customer.savedId, this.customerInfoDetailReq)
          .pipe(takeUntil(this.destroySub))
          .subscribe(response => {

            this.customerDetails = {
              fullName: response.fullName ? response.fullName : '-',
              docNo: response.docNo ? response.docNo : '-',
              placeOfBirth: response.placeOfBirth ? response.placeOfBirth : '-',
              email: response.customerEmail ? response.customerEmail : '-',
              phone: response.phone ? response.phone : '-',
              dob: response.dob ? response.dob : '-',
              frequency: 'Bulanan'
            };
            this.calculatedProduct = {
              firstValue: response.premiumAmount ? 'Rp ' + ('' + response.premiumAmount).replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '-',
              secondValue: response.sumInsured ? 'Rp ' + ('' + response.sumInsured).replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '-',
              thirdValue: response.policyTerm ? response.policyTerm : '-'
            };
            this.beneficiary = {
              beneficiary: response.beneficiary ? response.beneficiary : '-',
              relationship: response.relationship ? response.relationship : '-',
              promo: response.promoCode ? response.promoCode : '-'
            };
            this.dbsParams = {
              adobeId: response.adobeId,
              marketingId: response.adobeMarketingCloudId,
              authCode: response.customerID
            };
          }, (error: any) => {
            this._error.handleError(error, {
              details: '/v1/IDN/customer-info/customerId Error: ' + error.message,
              secondary_details: 'API Payload: ' + this.customer.savedId
            });
          });
      } catch (e) {
        this._router.navigate(['/error-occured']);
      }
    } else {
      this.customer = {};
    }
  }

  formatValue(value: number): string {
    return 'Rp ' + (value.toString()).replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }

  popUp(location: string): void {
    const loc = location === 'policy' ? PolicyComponent : StatementComponent;
    const popupRef = this.cdsPopup?.open(loc, {
      size: 'md',
      data: {
        name: 'John Doe',
      },
    });

    popupRef?.afterClosed().pipe(takeUntil(this.destroySub)).subscribe(() => {
      this.mliAnalyticsService?.trackEvent({
        event_label: 'Terms and conditions',
        event_category: 'Registration form',
        event_type: 'Click button_close Ringkasan Informasi Produk & Layanan',
        dbs_adobe_id: this.dbsParams.adobeId,
        dbs_marketing_id: this.dbsParams.marketingId,
        dbs_authcode: this.dbsParams.authCode
      });
    });
  }

  // ForTesting
  isPopUpClicked(): any {
    this.mliAnalyticsService?.trackEvent({
      event_label: 'Terms and conditions',
      event_category: 'Registration form',
      event_type: 'Click button_Ringkasan Informasi Produk & Layanan',
      dbs_adobe_id: this.dbsParams.adobeId,
      dbs_marketing_id: this.dbsParams.marketingId,
      dbs_authcode: this.dbsParams.authCode
    });
    this.hasPopUpClicked = true;
  }

  lanjut(): void {
    this.mliAnalyticsService?.trackEvent({
      event_label: 'Proceed with payment',
      event_category: 'Registration form',
      event_type: 'Click button_Proceed with payment',
      dbs_adobe_id: this.dbsParams.adobeId,
      dbs_marketing_id: this.dbsParams.marketingId,
      dbs_authcode: this.dbsParams.authCode
    });
    if (this.steps[3].completed < 1) {
      this.steps[3].completed = 1;
      sessionStorage.setItem('completed-steps', JSON.stringify(this.steps));
    }
    this.model.applicationSummaryChecked = true;
    this.hasPopClicked = true; // For Testing
    this.hasLanjutClicked = true;
    this.save('continue');
  }

  openPaymentPopUp(): void {
    const popupRef = this.cdsPopup?.open(MakePaymentComponent, {
      size: 'md',
      data: {
        name: 'John Doe',
      },
    });

    popupRef?.afterClosed().pipe(takeUntil(this.destroySub)).subscribe(() => {
      // This is intentional
    });
  }

  lanjutError(): void {
    this.mliAnalyticsService?.trackEvent({
      event_label: 'Proceed with payment',
      event_category: 'Registration form',
      event_type: 'Click button_Proceed with payment',
      dbs_adobe_id: this.dbsParams.adobeId,
      dbs_marketing_id: this.dbsParams.marketingId,
      dbs_authcode: this.dbsParams.authCode
    });
    this.errorContinued = true;
    this.applicationSummary = { checkedStatus: 'unchecked' };
    sessionStorage.setItem('Application Summary', JSON.stringify(this.applicationSummary));
  }

  kembali(): void {
    this.mliAnalyticsService?.trackEvent({
      event_label: 'Back',
      event_category: 'Registration form',
      event_type: 'Click button_Back',
      dbs_adobe_id: this.dbsParams.adobeId,
      dbs_marketing_id: this.dbsParams.marketingId,
      dbs_authcode: this.dbsParams.authCode
    });
    this._sharedService?.setData(2);
    this.hasKembaliClicked = true;
    this.save('back');
  }

  isChecked(): void {
    this.hasChecked = !this.hasChecked;
    this.applicationSummary = JSON.parse(sessionStorage.getItem('Application Summary') || '{}');
    if (this.hasChecked === true) {
      this.applicationSummary.checkedStatus = 'checked';
      sessionStorage.setItem('Application Summary', JSON.stringify(this.applicationSummary));
    } else {
      this.applicationSummary.checkedStatus = 'unchecked';
      sessionStorage.setItem('Application Summary', JSON.stringify(this.applicationSummary));
    }
  }

  save(value: string): void {
    if (this.currentDate) {
      this.newYear = +(this._sharedService?.getDueDate(this.currentDate, this.datePipe)[2])!;
      this.newMonth = +(this._sharedService?.getDueDate(this.currentDate, this.datePipe)[1])! - 1;
      this.newDay = +(this._sharedService?.getDueDate(this.currentDate, this.datePipe)[0])!;

      this.dueDate = new Date(this.newYear, this.newMonth, this.newDay);
    } else {
      this.dueDate = '-';
    }

    this.model.lastAccessedDate = '' + this.dueDate;
    this.model.id = this.customer.savedId;
    console.log('save: model value ==> ', this.model);

    try {
      this._service.updateCustomerInformation(this.customer.savedId, this.model)
        .pipe(takeUntil(this.destroySub))
        .subscribe(() => {
          console.log('save: Successfully Saved in MongoDB');
          if (value === 'continue') {
            this.openPaymentPopUp();
          } else {
            this._router.navigate(['/mi-flip/add-other-information']);
          }
        }, (error: any) => {
          console.log('save: Unsuccessfully Saved in MongoDB ==> ', error);
          this._error.handleError(error, {
            details: 'PATCH /v1/IDN/customer-info/customerID Error: ' + error.message,
            secondary_details: 'API Payload: ' + JSON.stringify(this.model)
          });
          this._router.navigate(['/error-occured']);
        });
    } catch (e) {
      this._router.navigate(['/error-occured']);
    }
  }

  translateRelation(value: string): string {
    switch (value) {
      case 'Husband':
        return 'Suami';
      case 'Wife':
        return 'Istri';
      case 'Father':
        return 'Ayah';
      case 'Mother':
        return 'Ibu';
      case 'Son':
        return 'Anak Laki-laki';
      case 'Daughter':
        return 'Anak Perempuan';
      case 'Brother':
        return 'Saudara kandung Laki-laki';
      case 'Sister':
        return 'Saudara kandung Perempuan';
      default:
        return '-';
    }
  }

  downloadPdf(): void {
    this._dbsSharedService?.navigateToDownload();
  }

  ngOnDestroy(): void {
    this.destroySub.next();
    this.destroySub.complete();
  }
}

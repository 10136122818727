import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@cds/ng-web-components/popup';
import { Router } from '@angular/router';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsTextfieldConfig } from '@cds/ng-core/textfield';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CustomerInfoDetails } from 'src/app/_model/customer-info-details.model';
import { CustomerDetails } from 'src/app/_model/customerdetails-model';
import { TopLevelDomains } from 'src/app/_model/top-level-domains.model';
import { ErrorServicesService } from 'src/app/_services/error-services.service';
import { GeneralService } from 'src/app/_services/general.service';
import { DbsRedirectionService } from 'src/app/_shared/dbs-redirection.service';

@Component({
  selector: 'change-email-address',
  templateUrl: './change-email-address.component.html',
  styleUrls: ['./change-email-address.component.scss']
})
export class ChangeEmailAddressComponent implements OnInit, OnDestroy {
  @Input() previousAddress?: string;
  customerDetails: any = {};
  topLevel: any = '';
  currentEmail: any = '';
  emailNewValue = null;
  newEmVal = '';
  destroySub = new Subject();

  formGroup: FormGroup;
  isValidEmail = true;
  noValueEmail = true;
  onEmailFocused = false;
  isSameEmail = false;

  newEmailAddress: CdsTextfieldConfig = {
    type: 'email',
    label: 'Alamat email baru',
    placeholder: 'Masukkan email baru di sini'
  };
  changeEmailButton: CdsButtonConfig = {
    label: 'Ganti email',
    buttonType: 'button',
    style: 'primary'
  };
  model: CustomerDetails = {};
  customerInfoDetailsReq: CustomerInfoDetails = new CustomerInfoDetails();

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ChangeEmailAddressComponent>,
    private _service: GeneralService,
    private _router: Router,
    public _dbsShared: DbsRedirectionService,
    private _error: ErrorServicesService
  ) {
    this.formGroup = this.formBuilder?.group({
      newEmail: new FormControl('', Validators.required)
    });
  }

  ngOnInit(): void {
    this.customerDetails = JSON.parse(sessionStorage?.getItem('CustomerDetails') || '{}');
    this?.formGroup?.patchValue({
      newEmail: this.emailNewValue
    });
    this.newEmVal = this?.formGroup?.get('newEmail')?.value;

    this.customerInfoDetailsReq = {
      fields: ['customerEmail']
    };
    try {
      this._service.findById(this?.customerDetails.savedId, this.customerInfoDetailsReq)
        .pipe(takeUntil(this.destroySub))
        .subscribe(response => {
          this.newEmailAddress.placeholder = response.customerEmail;
          this.currentEmail = response.customerEmail;
        }, (error: any) => {
          this._error.handleError(error, {
            details: '/v1/customer-info/customerId Error: ' + error.message,
            secondary_details: 'API Payload: ' + this.customerDetails.savedId
          });
        });
    } catch (e) {
      this._router.navigate(['/error-occured']);
    }

    this.topLevel = TopLevelDomains;
  }

  isBacked(): void {
    this.dialogRef.close({ agree: false, emailValue: 'not updated' });
  }

  isGantiEmailClicked(): void {
    this.model.customerEmail = this?.formGroup?.get('newEmail')?.value;
    this.model.id = this.customerDetails.savedId;
    this.newEmVal = this?.formGroup?.get('newEmail')?.value;
    try {
      this._service.updateCustomerInformation(this.customerDetails.savedId, this.model)
        .pipe(takeUntil(this.destroySub))
        .subscribe(() => {
          console.log('isGantiEmailClicked: Successfully Saved in MongoDB');
          this.dialogRef.close({ agree: true, emailValue: this.model.customerEmail });
      }, (error: any) => {
        console.log('isGantiEmailClicked: Unsuccessfully Saved in MongoDB ==> ', error);
        this._error.handleError(error, {
          details: 'PATCH /v1/IDN/customer-info/customerID Error: ' + error.message,
          secondary_details: 'API Payload: ' + JSON.stringify(this.model)
        });
        this._router.navigate(['/error-occured']);
        this.dialogRef.close({ agree: false, emailValue: 'not updated' });
      });
    } catch (e) {
      this._router.navigate(['/error-occured']);
      this.dialogRef.close({ agree: false, emailValue: 'not updated' });
    }
  }

  onEnterEmail(event: any): void {
    this.newEmailAddress.placeholder = '';
    this.isValidEmail = false;
    const newEmailValue = ('' + event.target?.value).split('');

    if (newEmailValue.length === 0 || newEmailValue.length > 50) {
      this.noValueEmail = true;
      this.newEmailAddress.placeholder = this.currentEmail;
    } else if (newEmailValue.indexOf(' ') >= 0) {
      this.isValidEmail = false;
    } else {
      this.noValueEmail = false;
      if (newEmailValue.includes('@', 0)) {
        const username = (((newEmailValue.join('')).split('@'))[0]);
        if (((!/[^a-zA-Z0-9_.]/.test(username)) === true) && username !== '' && !this?.detectMultipleDots(username)) {
          const index = newEmailValue.findIndex((x: string) => x === '@');
          const secondArray = newEmailValue.slice(index + 1);
          if (secondArray.includes('.')) {
            const secondIndex = secondArray.findIndex((x: string) => x === '.');
            const site = secondArray.slice(0, secondIndex);
            const tld = secondArray.slice(secondIndex + 1);
            if (newEmailValue.join('') === this.currentEmail) {
              this.isSameEmail = true;
              this.isValidEmail = true;
            } else {
              this.isSameEmail = false;
              if (Object.values(this.topLevel).includes(tld.join('').toUpperCase()) && !/[^a-zA-Z]/.test(site.join('').toUpperCase())) {
                this.isValidEmail = true;
              } else {
                this.isValidEmail = false;
              }
            }
          } else {
            this.isValidEmail = false;
          }
        } else {
          this.isValidEmail = false;
        }
      } else {
        this.isValidEmail = false;
      }
    }
  }

  onFocusedOut(): void {
    this.onEmailFocused = false;
    if (this.noValueEmail === true) {
      this.newEmailAddress.placeholder = this.currentEmail;
    }
  }

  detectMultipleDots(value: string): boolean {
    const arr = value.split('.');
    if (arr.length > 2) {
      return true;
    } else {
      return false;
    }
  }

  emailFieldClicked(): void {
    this.newEmailAddress.placeholder = undefined;
    if (this._dbsShared.getAndroidNativeBridge()) {
      this.onEmailFocused = true;
    } else if (this._dbsShared.getiOSNativeBridge()) {
      this.onEmailFocused = false;
    } else {
      this.onEmailFocused = true;
    }
  }

  ngOnDestroy(): void {
    this.destroySub.next();
    this.destroySub.complete();
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { Subscription } from 'rxjs';
import { DbsRedirectionService } from 'src/app/_shared/dbs-redirection.service';

@Component({
  selector: 'app-error-occured',
  templateUrl: './error-occured.component.html',
  styleUrls: ['./error-occured.component.scss']
})
export class ErrorOccuredComponent implements OnInit, OnDestroy {
  config: CdsIconConfig = {
    size: 'md',
    color: 'default',
  };
  buttonConfig: CdsButtonConfig = {
    label: 'Kembali',
    buttonType: 'button',
    style: 'primary'
  };
  sub: Subscription = new Subscription();
  errorMsg = '';
  MohonMaafClicked = false;

  constructor(
    private _dbsshared: DbsRedirectionService
  ) {
    // This is intentional
  }

  ngOnInit(): void {
    this.errorMsg = sessionStorage.getItem('Error Status') || 'No Error Logs';
  }

  onBackClicked(): void {
    sessionStorage.clear();
    this._dbsshared.navigateToDashboard();
  }

  isMohonMaafClicked(): void {
    this.MohonMaafClicked = false;
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
    sessionStorage.removeItem('current-error');
  }

}

import { Component, OnInit } from '@angular/core';
import { MatDialogRef, CdsPopupService } from '@cds/ng-web-components/popup';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SessionExpiredComponent } from '../session-expired/session-expired.component';

@Component({
  selector: 'app-remaining-time',
  templateUrl: './remaining-time.component.html',
  styleUrls: ['./remaining-time.component.scss']
})
export class RemainingTimeComponent implements OnInit {
  destroySub = new Subject();
  isSessionTimeout = false;
  interval: any;
  seconds = 30;

  constructor(
    private dialogRef: MatDialogRef<RemainingTimeComponent>,
    private cdsPopup: CdsPopupService
  ) {
    // This is intentional
  }

  ngOnInit(): void {
    this.interval = self.setInterval(() => {
      if (this.seconds === 0 && this.isSessionTimeout === false) {
        this.isSessionTimeout = true;
        this.sessionTimeout();
        clearInterval(this.interval);
      } else {
        this.seconds = this.seconds - 1;
      }
    }, 1000);
  }

  sessionTimeout(): void {
    const popupRef = this.cdsPopup?.open(SessionExpiredComponent, {
      size: 'md',
      data: {
        name: 'John Doe',
      },
    });

    popupRef?.afterClosed()
      .pipe(takeUntil(this.destroySub))
      .subscribe(() => {
        // This is intentional
      });
  }

  isContinue(): void {
    clearInterval(this.interval);
    this.dialogRef.close({ agree: true });
  }
}

<app-header></app-header>
<div class="ambiguous-page">
    <p id="ambiguous-title" class="title">Pengajuan Asuransi Anda sedang dalam Proses</p>
    <p id="ambiguous-first-details" class="ambiguous-first-message">Terima kasih karena telah memilih Manulife untuk melindungi Anda dan orang yang Anda sayangi</p>
    <p id="ambiguous-second-details" class="ambiguous-second-message">Pengajuan Asuransi MiFirst Life Protector Anda sedang dalam proses.</p>
    <p id="ambiguous-third-details" class="ambiguous-third-message">Manulife akan mengirimkan notifikasi hasil pengajuan Anda melalui email yang telah terdaftar pada pengajuan ini dalam kurun waktu maksimal 7 hari kerja</p>
    <p id="ambiguous-fourth-details" class="ambiguous-fourth-message">Mohon Anda dapat memeriksa email Anda yang terdaftar dalam pengajuan ini secara berkala.</p>
    <p id="ambiguous-fifth-details" class="ambiguous-fifth-message">Anda dapat menutup halaman ini untuk kembali ke aplikasi DBS Digibank Anda</p>
    <div class="ambiguous-page-buttons">
        <cds-button 
            id="back-button" 
            class="back" 
            [config]="backDigibank" 
            (click)="backToDigibank()">
        </cds-button>
    </div>
</div>
<app-footer></app-footer>

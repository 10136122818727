import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// CDS Component
import { CdsInlineAlertModule } from '@cds/ng-web-components/inline-alert';
import { CdsNavigationModule } from '@cds/ng-web-components/navigation';
import { CdsConversationalModule } from '@cds/ng-web-components/conversational';
import { CdsDropdownModule } from '@cds/ng-web-components/dropdown';
import { CdsStepperModule } from '@cds/ng-web-components/stepper';
import { CdsButtonModule } from '@cds/ng-web-components/button';
import { CdsProgressModule } from '@cds/ng-web-components/progress';
import { CdsLinkModule } from '@cds/ng-web-components/link';
import { CdsFormFieldModule } from '@cds/ng-web-components/form-field';
import { CdsAccordionModule } from '@cds/ng-web-components/accordion';
import { CdsAssistiveTextModule } from '@cds/ng-web-components/assistive-text';
import { CdsIconModule } from '@cds/ng-web-components/icon';
import { CdsPaginationModule } from '@cds/ng-web-components/pagination';
import { CdsPopupModule } from '@cds/ng-web-components/popup';
import { CdsTabsModule } from '@cds/ng-web-components/tabs';
import { CdsTooltipModule } from '@cds/ng-web-components/tooltip';
import { CdsTextfieldModule } from '@cds/ng-web-components/textfield';
import { CdsTextareaModule } from '@cds/ng-web-components/textarea';
import { CdsSwitchModule } from '@cds/ng-web-components/switch';
import { CdsSearchModule } from '@cds/ng-web-components/search';
import { CdsPillModule } from '@cds/ng-web-components/pill';
import { CdsCheckboxListModule, CdsCheckboxModule } from '@cds/ng-web-components/checkbox';
import { CdsToastService } from '@cds/ng-web-components/toast';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// Material
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
// Components
import { BuildYourPlanComponent } from './build-your-plan/build-your-plan.component';
import { HealthDeclarationComponent } from './health-declaration/health-declaration.component';
import { AddOtherInformationComponent } from './add-other-information/add-other-information.component';
import { ApplicationSummaryComponent } from './application-summary/application-summary.component';
import { CompletionComponent } from './completion/completion.component';
import { LoadingPageComponent } from './loading-page/loading-page.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ProtectionExceptionComponent } from './_modal/protection-exception/protection-exception.component';
import { TermsAndConditionsComponent } from './_modal/terms-and-conditions/terms-and-conditions.component';
import { TemplateComponent } from './template/template.component';

import { ErrorOccuredComponent } from './error-pages/error-occured/error-occured.component';
import { ReachedLimitComponent } from './error-pages/reached-limit/reached-limit.component';
import { NotElligableComponent } from './error-pages/not-elligable/not-elligable.component';
import { AuthService } from './_services/auth.service';
import { ChangeEmailAddressComponent } from './_modal/change-email-address/change-email-address.component';
import { CarouselComponent } from './_modal/carousel/carousel.component';
import { DataErrorComponent } from './error-pages/data-error/data-error.component';
import { FailedCompletionComponent } from './failed-completion/failed-completion.component';
import { InProgressComponent } from './in-progress/in-progress.component';
import { DatePipe } from '@angular/common';
import { MakePaymentComponent } from './_modal/make-payment/make-payment.component';
import { FormRedirectComponent } from './_modal/make-payment/paymentform-redirect/paymentform-redirect.component';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { RemainingTimeComponent } from './_modal/remaining-time/remaining-time.component';
import { BnNgIdleService } from 'bn-ng-idle';
import { SessionExpiredComponent } from './_modal/session-expired/session-expired.component';
import { PolicyComponent } from './_modal/policy/policy.component';
import { StatementComponent } from './_modal/statement/statement.component';
import { CookieModule, CookieService } from 'ngx-cookie';
import { CompletionLoaderComponent } from './completion-loader/completion-loader.component';
import { MliAnalyticsModule } from '@mli/analytics';
import { environment } from 'src/environments/environment';
import { TokenInterceptorService } from './_services/token-interceptor.service';
import { AmbiguousComponent } from './error-pages/ambiguous/ambiguous.component';
import { ErrorServicesService } from './_services/error-services.service';

@NgModule({
  declarations: [
    AppComponent,
    LoadingPageComponent,
    BuildYourPlanComponent,
    HealthDeclarationComponent,
    AddOtherInformationComponent,
    ApplicationSummaryComponent,
    CompletionComponent,
    HeaderComponent,
    FooterComponent,
    ProtectionExceptionComponent,
    TermsAndConditionsComponent,
    TemplateComponent,
    ErrorOccuredComponent,
    ReachedLimitComponent,
    NotElligableComponent,
    ChangeEmailAddressComponent,
    CarouselComponent,
    DataErrorComponent,
    FailedCompletionComponent,
    InProgressComponent,
    MakePaymentComponent,
    FormRedirectComponent,
    RemainingTimeComponent,
    SessionExpiredComponent,
    PolicyComponent,
    StatementComponent,
    CompletionLoaderComponent,
    AmbiguousComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CdsConversationalModule,
    CdsDropdownModule,
    CdsStepperModule,
    CdsInlineAlertModule,
    CdsNavigationModule,
    CdsButtonModule,
    CdsProgressModule,
    CdsLinkModule,
    CdsFormFieldModule,
    CdsAccordionModule,
    CdsAssistiveTextModule,
    CdsIconModule,
    CdsPaginationModule,
    CdsPopupModule,
    CdsTabsModule,
    CdsTooltipModule,
    CdsTextfieldModule,
    CdsTextareaModule,
    CdsSwitchModule,
    CdsSearchModule,
    CdsPillModule,
    CdsCheckboxListModule,
    CdsCheckboxModule,
    MatDialogModule,
    MatIconModule,
    HttpClientModule,
    CookieModule.forRoot(),
    BackButtonDisableModule.forRoot({
      preserveScrollPosition: true
    }),
    MliAnalyticsModule.forRoot(environment.analyticsUrl)
  ], exports: [
    HeaderComponent,
    FooterComponent,
  ],
  providers: [
    AuthService,
    CdsToastService,
    BnNgIdleService,
    DatePipe,
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: ErrorServicesService
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export class CustomerDetails {
    id?: string;
    age?: number;
    aliasName?: string;
    annualIncome?: string;
    cif?: string;
    customerID?: string;
    dob?: string;
    customerEmail?: string;
    fullName?: string;
    gender?: string;
    insuredIsOwner?: string;
    isEligible?: string;
    isExistingCustomer?: string;
    ktpAddress?: string;
    maritalStatus?: string;
    nationalId?: string;
    nationality?: string;
    occupation?: string;
    phone?: string;
    placeOfBirth?: string;
    policyId?: string;
    premiumAmountSummary?: number;
    sourceOfFund?: string;
    watchlistIndicator?: string;
    addressCity?: string;
    addressCountry?: string;
    addressProvince?: string;
    addressStreet1?: string;
    addressStreet2?: string;
    addressType?: string;
    addressState?: string;
    sumInsured?: number;
    policyTerm?: string;
    premiumAmount?: number;
    beneficiary?: string;
    destination?: string;
    relationship?: string;
    isSuccess?: string;
    policy?: string;
    policyCode?: string;
    lastAccessedDate?: string;
    lastModefiedDate?: string;
    createdDate?: string;
    isDelete?: string;
    applicationSummaryChecked?: boolean;
    promoCode?: string;
    hashCode?: string;
    addressTypeWork?: string;
    addressLine1Work?: string;
    addressLine2Work?: string;
    addressLine3Work?: string;
    postalCode?: string;
    postalCodeWork?: string;
    stateWork?: string;
    cityWork?: string;
    countryWork?: string;
    msgId?: string;
    timeStamp?: string;
    orderRef?: string;
    currencyCode?: string;
    paymentMode?: string;
    salutation?: string;
    adobeMarketingCloudId?: string;
    adobeId?: string;
    annualizedPremium?: number;
}

<div class="statement-container">
    <div class="statement-header">
        <cds-icon 
            id="close-btn" 
            class="close-button" 
            icon="action:button_x" 
            (click)="buttonClicked('close')" 
            width="2.5rem" 
            height="2.5rem">
        </cds-icon>
        <p id="header-sbtle" class="header-subtitle">Pernyataan & Kuasa</p>
    </div>
    <div class="statement-details">
        <div class="statement-first-container">
            <p id="first-container-title" class="first-container-subtitle">Dengan menyetujui Formulir Pendaftaran ini, Saya menyatakan hal-hal sebagai berikut:</p>
            <ol id="first-container-listdetails" class="first-container-dtls">
                <li id="first-container-firstdetails" class="first-container-firstdtls first-container-details">Saya setuju bahwa jika pada saat Saya mendaftarkan diri, Saya menderita penyakit yang telah didiagnosa, mendapatkan perawatan kesehatan, memperoleh resep obat-obatan dan yang sudah diketahui sebelum berlakunya Polis, maka pertanggungan menjadi tidak berlaku untuk penyakit tersebut sebagaimana dalam Pernyataan Kesehatan, kecuali apabila Polis telah berjalan selama 24 (dua puluh empat) bulan sejak Tanggal Berlakunya Pertanggungan yang telah ditetapkan Manulife Indonesia.</li>
                <li id="first-container-seconddetails" class="first-container-seconddtls first-container-details">Bahwa seluruh informasi dan keterangan yang menyangkut Pernyataan Kesehatan sebagaimana tercantum dalam Formulir Pendaftaran adalah lengkap dan benar.</li>
                <li id="first-container-thirddetails" class="first-container-thirddtls first-container-details">Saya telah membaca, mengerti dan menjawab pertanyaan-pertanyaan pada Formulir Pendaftaran ini dengan lengkap dan benar serta seluruh keterangan telah Saya baca dan periksa kembali kebenarannya sebelum menandatangani Formulir Pendaftaran. Saya memahami bahwa keterangan, pernyataan dan penjelasan tersebut menjadi dasar Pertanggungan dan merupakan bagian yang tidak terpisahkan dari Polis yang diminta. Oleh karenanya, Saya, dengan ini memberikan kuasa yang tidak dapat ditarik kembali kepada Manulife Indonesia dengan hak substitusi untuk:</li>
                <ol id="first-container-list" class="first-container-lst first-container-details" type="a">
                    <li id="first-container-firstoption" class="first-container-firstoptn first-container-details">Melakukan verifikasi terhadap informasi yang telah saya berikan kepada pihak manapun serta untuk memperoleh segala catatan dan keterangan mengenai diri dan keadaan/kesehatan Saya dari dokter, klinik, rumah sakit, puskesmas, bank, perusahaan asuransi, badan hukum, instansi pemerintah, perorangan atau organisasi lainnya dan saya bertanggung jawab atas segala isinya. Apabila dalam verifikasi tersebut ditemukan suatu keterangan yang tidak benar, maka Saya akan tunduk pada syarat dan ketentuan yang terdapat di dalam Polis</li>
                    <li id="first-container-secondoption" class="first-container-secondoptn first-container-details">Menggunakan, mengungkapkan, menyediakan data/informasi mengenai Saya yang diperoleh dan dikumpulkan oleh Manulife Indonesia atau afiliasinya kepada pihak yang berkepentingan (termasuk namun tidak terbatas pada pihak reasuransi, penyidik klaim, bank/lembaga keuangan, asosiasi industri dan badan hukum lain, baik di dalam maupun luar negeri) baik yang memiliki kerja sama atau tidak dengan Manulife Indonesia atau afiliasinya untuk tujuan pembayaran klaim dan/atau melakukan proses lainnya yang terkait dengan polis Saya maupun untuk penawaran produk asuransi atau produk keuangan lain,untuk tujuan pencegahan pencucian uang, suap dan pendanaan terorisme atau terkait dengan sanksi ekonomi, yang berkaitan dengan setiap pidana/perdata atau proses arbitrase, investigasi, audit atau pemeriksaan (termasuk penyelidikan internal, audit atau pemeriksaan) yang Manulife Indonesia, afiliasi, atau karyawan dikenakan atau untuk tujuan lain sebagaimana yang diperkenankan oleh pedoman Manulife Indonesia dan kode etik Manulife Indonesia, hukum dan peraturan perundang-undangan</li>
                </ol>
                <li id="first-container-fourthdetails" class="first-container-fourthdtls first-container-details">Seluruh salinan dokumen yang diberikan sehubungan dengan permohonan keikutsertaan asuransi jiwa ini adalah sesuai dengan dokumen aslinya dan masih berlaku.</li>
                <li id="first-container-fifthdetails" class="first-container-fifthdtls first-container-details">Saya telah mengerti serta memahami segala konsekuensi pemanfaatan produk, termasuk manfaat, risiko dan biaya-biaya yang melekat pada produk tersebut sebagaimana dicantumkan dalam Ringkasan Informasi Produk dan Layanan.</li>
                <li id="first-container-sixthdetails" class="first-container-sixthdtls first-container-details">Saya menyetujui bahwa Manulife Indonesia berhak untuk menolak transaksi, membatalkan transaksi, dan/atau menutup hubungan usaha dengan Saya dalam hal Saya tidak memenuhi ketentuan peraturan terkait mengenai penerapan anti pencucian uang dan pencegahan pendanaan terorisme.</li>
                <li id="first-container-seventhdetails" class="first-container-seventhdtls first-container-details">Saya menyetujui bahwa semua alamat korespondensi dan nomor telepon Saya yang telah diinformasikan dalam Formulir Pendaftaran ini dapat dipergunakan sebagai media komunikasi Polis. Saya akan memberitahu Manulife Indonesia sesegera mungkin apabila terdapat perubahan alamat korespondensi/nomor telepon. Saya mengerti bahwa Manulife Indonesia tidak bertanggung jawab atas gagalnya komunikasi Polis akibat tidak diberitahukan perubahan alamat korespondensi/nomor telepon Saya.</li>
                <li id="first-container-eighthdetails" class="first-container-eighthdtls first-container-details">Apabila Manulife Indonesia tidak pernah menerima dokumen pendukung yang menyatakan hubungan kepentingan antara calon Tertanggung dan Yang Ditunjuk atau apabila tidak terdapat hubungan kepentingan antara calon Tertanggung dan Yang Ditunjuk, maka jika calon Tertanggung meninggal, Manulife Indonesia akan membayarkan Manfaat Pertanggungan kepada kepada Ahli Waris calon Tertanggung. sesuai dengan ketentuan Perundang-undangan yang berlaku.</li>
                <li id="first-container-ninthdetails" class="first-container-ninthdtls first-container-details">Saya menyetujui untuk menerima polis dan/atau setiap surat pemberitahuan terkait Polis dalam bentuk media elektronik apabila disediakan oleh Manulife Indonesia.</li>
            </ol>
        </div>
        <div class="statement-second-container">
            <p id="second-container-title" class="second-container-subtitle">Catatan - catatan lainnya:</p>
            <ol id="second-container-listdetails" class="second-container-dtls">
                <li id="second-container-firstdetails" class="second-container-firstdtls second-container-details">Sesuai peraturan perundang-undangan yang berlaku di bidang asuransi, PT Asuransi Jiwa Manulife Indonesia (“<strong>Manulife Indonesia</strong>”) dapat menerbitkan polis dalam bentuk elektronik (“<strong>ePolicy</strong>” atau “<strong>Polis Elektronik</strong>”).</li>
                <li id="second-container-seconddetails" class="second-container-seconddtls second-container-details">Polis Elektronik merupakan dokumen yang sah yang diterbitkan oleh Manulife Indonesia sebagai bentuk kesepakatan Manulife Indonesia dan Pemegang Polis atas pertanggungan yang diberikan oleh Manulife Indonesia serta memiliki kekuatan hukum dan kekuatan pembuktian yang sama seperti halnya Buku Polis dalam bentuk cetak. Pemegang Polis akan menerima Polis Elektronik melalui email terdaftar di Manulife Indonesia.</li>
                <li id="second-container-thirddetails" class="second-container-thirddtls second-container-details">Manulife Indonesia akan menyampaikan Ikhtisar Polis dalam bentuk cetak ke alamat Pemegang Polis yang terdaftar sesuai ketentuan perundang-undangan yang berlaku dibidang asuransi. Polis Elektronik dan komunikasi penting lainnya akan disampaikan oleh Manulife Indonesia ke alamat email Pemegang Polis yang terdaftar.</li>
                <li id="second-container-fourthdetails" class="second-container-fourthdtls second-container-details">Apabila Pemegang Polis tidak berkenan untuk memilih Polis Elektronik Manulife Indonesia dapat mengenakan biaya pencetakan dan mengirim Polis dalam bentuk cetak dengan persetujuan Pemegang Polis.</li>
            </ol>
        </div>
    </div>
    <div class="button-container">
        <cds-popup-actions>
            <cds-button id="back-button" class="back" [config]="backButton" (click)="buttonClicked('back')"></cds-button>
        </cds-popup-actions>
    </div>
</div>

import { Component, OnInit } from '@angular/core';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { DbsRedirectionService } from 'src/app/_shared/dbs-redirection.service';

@Component({
  selector: 'app-not-elligable',
  templateUrl: './not-elligable.component.html',
  styleUrls: ['./not-elligable.component.scss']
})
export class NotElligableComponent implements OnInit {
  errorMsg = '';
  MohonMaafClicked = false;
  config: CdsIconConfig = {
    size: 'md',
    color: 'default',
  };

  buttonConfig: CdsButtonConfig = {
    label: 'Kembali ke digibank',
    buttonType: 'button',
    style: 'primary'
  };

  constructor(
    private _dbsshared: DbsRedirectionService
  ) {
    // This is intentional
  }

  ngOnInit(): void {
    this.errorMsg = sessionStorage.getItem('Error Status') || 'No Error Logs';
  }

  onBackClicked(): void {
    sessionStorage.clear();
    this._dbsshared.navigateToDashboard();
  }

  isMohonMaafClicked(): void {
    this.MohonMaafClicked = false;
  }
}

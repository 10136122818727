<div class="container">
    <div id="remaining-time-details" class="details">
        Sesi Anda akan berakhir dalam 
        <strong id="details-seconds" class="seconds">{{seconds}} detik</strong> 
        karena ketidakaktifan. Klik lanjutkan untuk kembali mengatur perlindungan Anda 
    </div>
    <div class="button-container">
        <cds-button 
            id="continue" 
            class="continue-button" 
            (click)="isContinue()">Lanjutkan
        </cds-button>
    </div>
</div>

export class ProductEligibility {
    customerInfo?: Product;
}

export class Product {
    fullName?: string;
    dob?: string;
    gender?: string;
    cif?: string;
}

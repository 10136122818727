import { Injectable } from '@angular/core';
import { INativeActionPayload } from '../_model/dbs-redirection.model';

declare global {
  interface Window {
    webkit: any;
    android: any;
  }
}

@Injectable({
  providedIn: 'root'
})
export class DbsRedirectionService {

  constructor() {
    // This is intentional
  }

  navigateToDownload(): void {
    if (this.isMobileWebview()) {
      const nativeActPayload: INativeActionPayload = {
        action: 'navigateToDownload',
        actionData: {
          downloadUrl: 'https://dbsbanca.manulife.co.id/assets/Ringkasan%20Informasi%20Produk%20dan%20Layanan_Umum%20-%20MiFirst%20Life%20Protector.pdf'
        }
      };
      this.navigateToNativePage(nativeActPayload);
    } else {
      console.log('Not viewing in webview or bridge is not initialized');
    }
  }

  navigateToDashboard(): void {
    if (this.isMobileWebview()) {
      const nativeActionPayload: INativeActionPayload = {
          action: 'navigateToDashboard',
          actionData: {},
      };
      this.navigateToNativePage(nativeActionPayload);
    } else {
       console.log('Not viewing in webview or bridge is not initialized');
    }
  }

  navigateToNativePage(payload: INativeActionPayload): void {
    const iosBridge = this.getiOSNativeBridge();
    const androidBridge = this.getAndroidNativeBridge();
    if (iosBridge) {
      if (
        iosBridge.messageHandlers !== 'undefined' &&
        iosBridge.messageHandlers !== null &&
        iosBridge.messageHandlers['bancaiOS'] !== 'undefined' &&
        iosBridge.messageHandlers['bancaiOS'] !== null &&
        iosBridge.messageHandlers['bancaiOS'].postMessage !==
        'undefined' &&
        iosBridge.messageHandlers['bancaiOS'].postMessage !== null
      ) {
        iosBridge.messageHandlers['bancaiOS'].postMessage(payload);
      } else {
        console.log('Message Handler for iOS not found');
      }
    } else if (androidBridge) {
      const payloadStr = JSON.stringify(payload);
      androidBridge.handleMessage(payloadStr);
    } else {
      console.log('Not viewing in webview or bridge is not initialized');
    }
  }

  isMobileWebview(): boolean {
    return !!this.getiOSNativeBridge() || !!this.getAndroidNativeBridge();
  }

  getiOSNativeBridge(): any {
    return typeof window !== 'undefined' &&
    window.webkit &&
    typeof window.webkit !== 'undefined' &&
    window.webkit !== null &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers['bancaiOS']
    ? window.webkit : null;
  }

  getAndroidNativeBridge(): any {
    return typeof window !== 'undefined' &&
    window.android &&
    typeof window.android !== 'undefined' &&
    window.android !== null
    ? window.android : null;
  }
}

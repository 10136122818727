import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddOtherInformationComponent } from './add-other-information/add-other-information.component';
import { ApplicationSummaryComponent } from './application-summary/application-summary.component';
import { BuildYourPlanComponent } from './build-your-plan/build-your-plan.component';
import { CompletionComponent } from './completion/completion.component';
import { DataErrorComponent } from './error-pages/data-error/data-error.component';
import { ErrorOccuredComponent } from './error-pages/error-occured/error-occured.component';
import { NotElligableComponent } from './error-pages/not-elligable/not-elligable.component';
import { ReachedLimitComponent } from './error-pages/reached-limit/reached-limit.component';
import { FailedCompletionComponent } from './failed-completion/failed-completion.component';
import { HealthDeclarationComponent } from './health-declaration/health-declaration.component';
import { LoadingPageComponent } from './loading-page/loading-page.component';
import { TemplateComponent } from './template/template.component';
import { InProgressComponent } from './in-progress/in-progress.component';
import { CompletionLoaderComponent } from './completion-loader/completion-loader.component';
import { AmbiguousComponent } from './error-pages/ambiguous/ambiguous.component';

export const routes: Routes = [

  { path: 'loading', component: LoadingPageComponent },
  {
    path: 'mi-flip', component: TemplateComponent,
    children: [
      {
        path: 'inprogress', component: InProgressComponent
      },
      {
        path: 'build-your-plan', component: BuildYourPlanComponent
      }, {
        path: 'add-other-information', component: AddOtherInformationComponent
      }, {
        path: 'health-declaration', component: HealthDeclarationComponent
      }, {
        path: 'application-summary', component: ApplicationSummaryComponent
      }
    ]
  },
  { path: 'completion-loader', component: CompletionComponent },
  { path: 'completion', component: CompletionLoaderComponent },
  { path: 'inprogress', component: InProgressComponent },
  { path: 'failed-completion', component: FailedCompletionComponent },
  { path: 'reached-limit', component: ReachedLimitComponent },
  { path: 'not-elligible', component: NotElligableComponent },
  { path: 'ambiguous', component: AmbiguousComponent },
  { path: 'data-error', component: DataErrorComponent },
  { path: 'error-occured/', component: ErrorOccuredComponent },
  { path: '**', component: ErrorOccuredComponent }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', enableTracing: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

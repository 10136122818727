import { Component, OnInit } from '@angular/core';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { DbsRedirectionService } from 'src/app/_shared/dbs-redirection.service';

@Component({
  selector: 'app-ambiguous',
  templateUrl: './ambiguous.component.html',
  styleUrls: ['./ambiguous.component.scss']
})
export class AmbiguousComponent implements OnInit {
  backDigibank: CdsButtonConfig = {
    label: 'Kembali ke digibank',
    buttonType: 'button',
    style: 'secondary',
    size: 'sm'
  };

  constructor(
    private _dbsshared: DbsRedirectionService
  ) {
    // This is intentional
  }

  ngOnInit(): void {
    // This is intentional
  }

  backToDigibank(): any {
    console.log('backToDigibank: backToDigibank is Called');
    sessionStorage.clear();
    this._dbsshared?.navigateToDashboard();
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { PaymentService } from '../_services/payment-service.service';
import { Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { DbsRedirectionService } from '../_shared/dbs-redirection.service';

@Component({
  selector: 'app-in-progress',
  templateUrl: './in-progress.component.html',
  styleUrls: ['./in-progress.component.scss']
})
export class InProgressComponent implements OnInit, OnDestroy {
  hasKembaliClicked = false;
  backConfig: CdsButtonConfig = {
    label: 'Kembali ke Digibank',
    buttonType: 'button',
    style: 'secondary'
  };
  subscription: Subscription = new Subscription();
  unSub = new Subject<any>();

  constructor(
    private _router: Router,
    private _service: PaymentService,
    private _dbsshared: DbsRedirectionService
  ) { }

  ngOnInit(): void {
    // This is intentional
  }

  kembali(): void {
    sessionStorage.clear();
    // ForTesting
    this.hasKembaliClicked = true;
    this._dbsshared.navigateToDashboard();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

<div class="policy-container">
    <div class="policy-header">
        <cds-icon 
            id="close-btn" 
            class="close-button" 
            icon="action:button_x" 
            (click)="buttonClicked('close')" 
            width="2.5rem" 
            height="2.5rem">
        </cds-icon>
        <p id="header-sbtle" class="header-subtitle">Ringkasan Informasi Produk & Layanan Personal</p>
    </div>
    <div class="policy-details">
        <div id="details-logo" class="policy-logo"></div>
        <p id="details-scnd" class="details-second">
            MiFirst Life Protector (MiFLIP) merupakan produk asuransi jiwa berjangka yang diterbitkan oleh PT Asuransi Jiwa Manulife Indonesia yang pemasarannya bekerja sama dengan PT Bank DBS Indonesia Tbk.
        </p>
        <p id="details-thrd" class="details-third">
            MiFLIP menyediakan manfaat perlindungan terhadap risiko meninggal dunia dan ketidakmampuan total tetap. Jika tidak terdapat klaim selama masa pertanggungan, maka akan dibayarkan manfaat akhir pertanggungan berupa pengembalian 100% premi yang telah Anda bayarkan.
        </p>
        <div class="details-fourth-container">
            <p id="details-frth-title" class="details-fourth-title">Usia masuk:</p>
            <p id="details-frth" class="details-fourth">Min. 18 tahun, Maks. 50 tahun (usia masuk ditambah masa pertanggungan harus ≤ 58 tahun)</p>
        </div>
        <div class="details-fifth-container">
            <p id="ffth-main-title" class="dtlffth-main-title">Istilah - Istilah</p>
            <div class="ffth-first-container">
                <p id="ffthdtl-first-title" class="ffth-first-subtitle">Pemegang polis</p>
                <p id="ffthdtl-first-details" class="ffth-first-details">Pemegang polis adalah perorangan yang mengadakan perjanjian Asuransi dengan Manulife Indonesia.</p>
            </div>
            <div class="ffth-second-container">
                <p id="ffthdtl-second-title" class="ffth-second-subtitle">Tertanggung</p>
                <p id="ffthdtl-second-details" class="ffth-second-details">Orang yang atas dirinya diadakan Pertanggungan dan ditanggung oleh Manulife Indonesia.</p>
            </div>
            <div class="ffth-third-container">
                <p id="ffthdtl-third-title" class="ffth-third-subtitle">Uang Pertanggungan</p>
                <p id="ffthdtl-third-details" class="ffth-third-details">Sejumlah nilai uang yang tercantum dalam Ringkasan Polis atau addendum sebagai dasar perhitungan Manfaat Pertanggungan.</p>
            </div>
            <div class="ffth-fourth-container">
                <p id="ffthdtl-fourth-title" class="ffth-fourth-subtitle">Akhir Masa Pertanggungan</p>
                <p id="ffthdtl-fourth-details" class="ffth-fourth-details">Pada saat Tertanggung mencapai usia 58 (lima puluh delapan) tahun atau Polis mencapai tanggal akhir masa pertanggungan (mana yang lebih dahulu).</p>
            </div>
            <div class="ffth-fifth-container">
                <p id="ffthdtl-fifth-title" class="ffth-fifth-subtitle">Yang Ditunjuk</p>
                <p id="ffthdtl-fifth-details" class="ffth-fifth-details">Orang yang mempunyai hubungan kepentingan (insurable interest) dengan Tertanggung dan ditunjuk untuk menerima Manfaat Pertanggungan apabila Tertanggung meninggal dunia.</p>
            </div>
        </div>
        <div class="details-sixth-container">
            <p id="sxth-main-title" class="dtlsxth-main-title">Ikhtisar Perlindungan</p>
            <div class="sxth-first-container">
                <p id="sxthdtl-first-title" class="sxth-first-subtitle">Masa pertanggungan</p>
                <p id="sxthdtl-first-details" class="sxth-first-details">8-15 tahun</p>
            </div>
            <div class="sxth-second-container">
                <p id="sxthdtl-second-title" class="sxth-second-subtitle">Masa pembayaran premi</p>
                <p id="sxthdtl-second-details" class="sxth-second-details">8-15 tahun</p>
            </div>
            <div class="sxth-third-container">
                <p id="sxthdtl-third-title" class="sxth-third-subtitle">Frekuensi Pembayaran Premi</p>
                <p id="sxthdtl-third-details" class="sxth-third-details">Bulanan</p>
            </div>
            <div class="sxth-fourth-container">
                <p id="sxthdtl-fourth-title" class="sxth-fourth-subtitle">Mata Uang</p>
                <p id="sxthdtl-fourth-details" class="sxth-fourth-details">Rupiah</p>
            </div>
            <div class="sxth-seventh-container">
                <p id="sxthdtl-seventh-title" class="sxth-seventh-subtitle">Metode Underwriting</p>
                <p id="sxthdtl-seventh-details" class="sxth-seventh-details">Simplified Issuance Offer (SIO) with one health declaration</p>
            </div>
            <div class="sxth-eighth-container">
                <p id="sxthdtl-eighth-title" class="sxth-eighth-subtitle">Akhir Masa Pertanggungan</p>
                <p id="sxthdtl-eighth-details" class="sxth-eighth-details">Pada saat Tertanggung mencapai usia 58 (lima puluh delapan) tahun atau Polis mencapai tanggal akhir masa pertanggungan (mana yang lebih dahulu).</p>
            </div>
        </div>
        <div class="details-seventh-container">
            <p id="svnth-main-title" class="dtlsvnth-main-title">Manfaat Pertanggungan</p>
            <div class="svnth-first-container">
                <p id="svnthdtl-first-title" class="svnth-first-subtitle">Manfaat meninggal dunia atau ketidakmampuan total tetap</p>
                <div id="svnth-subcontainer" class="dtlsvnth-subcontainer">
                    <p id="svtndtl-frstdtl-sub" class="seventh-first-sub">a. Meninggal dunia atau Ketidakmampuan Total Tetap yang disebabkan karena kecelakaan mulai dari tahun pertama polis, atau</p>
                    <p id="svtndtl-scnddtl-sub" class="seventh-second-sub">b. Meninggal dunia atau ketidakmampuan total tetap yang disebabkan karena sebab lainnya (sakit) mulai dari tahun kedua polis sampai akhir masa pertanggungan.</p>
                </div>
                <div id="svnth-scnd-subcontainer" class="dtlsvnth-scnd-subcontainer">
                    <p id="svtndtl-frstdtl-scndsub" class="seventh-first-scndsub">Manfaat meninggal dunia atau ketidakmampuan total tetap = Premi bulanan x faktor pengali (multiplier).</p>
                    <p id="svtndtl-scnddtl-scndsub" class="seventh-second-scndsub">Faktor pengali (multiplier) mengacu pada tabel berikut:</p>
                </div>
                <div id="svnth-thrd-subcontainer" class="dtlsvnth-thrd-subcontainer"></div>
            </div>
            <div class="svnth-second-container">
                <p id="svnthdtl-second-title" class="svnth-second-subtitle">Manfaat akhir masa pertanggungan</p>
                <p id="svnthdtl-second-details" class="svnth-second-details">Pada akhir masa pertanggungan, Anda berhak untuk menerima kembali 100% (seratus persen) dari seluruh Premi yang telah Anda bayar secara sekaligus selama tidak ada premi yang tertunggak dan pertanggungan masih aktif.</p>
            </div>
            <div class="svnth-third-container">
                <p id="svnthdtl-third-title" class="svnth-third-subtitle">Manfaat Pengembalian Premi</p>
                <div id="svnth-thrd-subcontainer" class="dtlsvnth-thrd-subcontainer">
                    <p id="svtndtl-frstdtl-thrdsub" class="seventh-first-thrdsub">Jika Pemegang Polis mengakhiri Polis sebelum atau pada tahun Polis ke-8, maka tidak ada manfaat pengembalian Premi yang dibayarkan.</p>
                    <p id="svtndtl-scnddtl-thrdsub" class="seventh-second-thrdsub">Jika Pemegang Polis mengakhiri Polis pada tahun Polis ke-9 atau setelahnya, maka 70% dari Premi yang telah dibayarkan akan dikembalikan sebagai manfaat pengembalian Premi dan selanjutnya Polis berakhir.</p>
                </div>
            </div>
        </div>
        <div class="details-eighth-container">
            <p id="eighth-main-title" class="dtlseighth-main-title">Premi</p>
            <p id="eighth-first-details" class="dtlseighth-first-details">Premi adalah sejumlah uang yang wajib dibayar oleh Pemegang Polis kepada Manulife Indonesia. Premi yang berlaku dibedakan berdasarkan usia masuk Tertanggung sebagai berikut (dalam rupiah):</p>
            <div id="eighth-first-table" class="dtlseighth-first-table"></div>
            <div id="eighth-second-table" class="dtlseighth-second-table"></div>
        </div>
        <div class="details-ninth-container">
            <p id="ninth-main-title" class="dtlsninth-main-title">Risiko – Risiko</p>
            <p id="ninth-first-details" class="dtlsninth-first-details">Bagian ini menjelaskan risiko - risiko yang dapat terjadi selama berjalannya perlindungan asuransi Anda</p>
            <div class="ninth-first-container">
                <p id="ninthdtls-first-title" class="ninth-first-subtitle">Risiko Operasional</p>
                <p id="ninthdtls-first-details" class="ninth-first-details">Suatu risiko kerugian yang disebabkan karena tak berjalannya atau gagalnya proses internal, manusia dan sistem, serta oleh peristiwa eksternal.</p>
            </div>
            <div class="ninth-second-container">
                <p id="ninthdtls-second-title" class="ninth-second-subtitle">Risiko Kredit dan Likuiditas</p>
                <p id="ninthdtls-second-details" class="ninth-second-details">Anda akan terekspos pada risiko kredit dan likuiditas Manulife Indonesia sebagai penyeleksi risiko dari produk asuransi. Risiko kredit dan likuiditas berkaitan dengan kemampuan Manulife Indonesia membayar kewajiban terhadap nasabahnya.</p>
            </div>
        </div>
        <div class="details-tenth-container">
            <p id="tenth-main-title" class="dtlstenth-main-title">Pengecualian</p>
            <p id="tenth-first-details" class="dtlstenth-first-details">Pertanggungan asuransi MiFirst Life Protector tidak berlaku dalam keadaan sebagai berikut:</p>
            <div class="tenth-first-container">
                <p id="tenthdtls-first-title" class="tenth-first-subtitle">A. Keadaan Yang Sudah Ada Sebelumnya (Pre-Existing Condition) yaitu segala jenis penyakit, kondisi, cedera, atau ketidakmampuan yang:</p>
                <div class="tenth-first-subcontainer">
                    <p id="tenthdtl-firstdtl-sub" class="tenth-first-sub">(i). yang sudah ada atau telah ada; atau</p>
                    <p id="tenthdtl-seconddtl-sub" class="tenth-second-sub">(ii). dimana penyebabnya ada atau telah ada; atau</p>
                    <p id="tenthdtl-thirddtl-sub" class="tenth-third-sub">(iii). dimana Tertanggung telah mengetahui, telah ada tanda-tanda atau gejala-gejala atau penyakit; atau</p>
                    <p id="tenthdtl-fourthdtl-sub" class="tenth-fourth-sub">(iv). adanya hasil tes laboratorium atau investigasi lain yang menunjukkan adanya kemungkinan kondisi atau penyakit tertentu, sebelum tanggal penerbitan polis atau tanggal penerbitan Addendum atau tanggal mulai berlakunya pemulihan polis, mana yang paling akhir, kecuali Tertanggung yang menderita sakit/cedera ini telah dipertanggungkan dalam polis ini selama 24 (dua puluh empat) bulan</p>
                </div>
            </div>
            <p id="tenth-third-details" class="dtlstenth-third-details third-set">B. Tindakan bunuh diri dalam jangka waktu 24 bulan dari tanggal masuk</p>
            <p id="tenth-fourth-details" class="dtlstenth-fourth-details third-set">C. Perbuatan melanggar hukum yang dilakukan oleh Tertanggung, atau ikut sertanya Tertanggung dalam suatu huru-hara, perkelahian atau kejadian yang sejenis</p>
            <p id="tenth-fifth-details" class="dtlstenth-fifth-details third-set">D. Perbuatan melanggar hukum yang dilakukan oleh Ahli Waris atau dengan bantuan seseorang yang mempunyai kepentingan atas pertanggungan dalam Polis Induk ini</p>
            <p id="tenth-sixth-details" class="dtlstenth-sixth-details third-set">E. Bencana alam atau wabah penyakit yang dinyatakan oleh pemerintah</p>
            <p id="tenth-seventh-details" class="dtlstenth-seventh-details third-set">F. Perang, invasi, aksi musuh asing, atau peperangan lainnya (baik perang yang dinyatakan ataupun yang tidak dinyatakan) yang melibatkan Indonesia</p>
            <p id="tenth-eighth-details" class="dtlstenth-eighth-details third-set">G. Aktivitas Tertanggung yang berhubungan dengan tugasnya dalam angkatan bersenjata dari negara manapun atau autoritas internasional yang manapun</p>
            <p id="tenth-ninth-details" class="dtlstenth-ninth-details third-set">H. Akibat langsung ataupun tidak langsung dari penyakit Human Immuno-deficiency Virus (HIV) atau Acquired Immune Deficiency Syndrome (AIDS) atau kondisi yang berhubungan dengan HIV/AIDS</p>
            <p id="tenth-tenth-details" class="dtlstenth-tenth-details third-set">I. Meninggal dunia dan/atau ketidakmampuan total tetap yang terjadi dalam masa 12 (dua belas) bulan pertama sejak tanggal masuk, kecuali kejadian meninggal dunia dan/atau ketidakmampuan total tetap yang disebabkan oleh karena suatu kecelakaan</p>
            <p id="tenth-eleventh-details" class="dtlstenth-eleventh-details third-set">J. Aktivitas yang berbahaya seperti martial sky arts, sky diving, diving, mendaki gunung, panjat tebing (baik tebing buatan atau alami), arung jeram, kegiatan yang berhubungan dengan hang gliding, ballooning, parasut, terjun payung, bungee jumping, berkuda, berlayar aero sports, dan kegiatan atau olahraga berbahaya lainnya;</p>
            <p id="tenth-twelfth-details" class="dtlstenth-twelfth-details third-set">K. Terlibat dalam penerbangan kecuali sebagai penumpang terbang komersial dengan penerbangan terjadwal</p>
            <p id="tenth-thirteenth-details" class="dtlstenth-thirteenth-details third-set">L. Melakukan kejahatan</p>
            <p id="tenth-fourteenth-details" class="dtlstenth-fourteenth-details third-set">M. Tertanggung mengendarai segala jenis kendaraan dalam keadaan mabuk, yaitu dimana kadar alkohol dalam darah melebihi 0,005%</p>
            <p id="tenth-fifteenth-details" class="dtlstenth-fifteenth-details third-set">N. Tertanggung menelan obat-obatan kecuali dapat dibuktikan bahwa obat-obatan tersebut diminum berdasarkan resep medis yang benar dan bukan untuk perawatan kecanduan obat</p>
            <p id="tenth-sixteenth-details" class="dtlstenth-sixteenth-details third-set">0. Reaksi nuklir, radiasi, atau kontaminasi radioaktif</p>
            <p id="tenth-seventeenth-details" class="dtlstenth-seventeenth-details">Catatan: Detail lengkap mengenai ketentuan Pengecualian dinyatakan dalam Ketentuan Umum dan Ketentuan Khusus Polis</p>
        </div>
        <div class="details-eleventh-container">
            <p id="eleventh-main-title" class="dtlseleventh-main-title">Persyaratan & Tata Cara</p>
            <div class="eleventh-first-container">
                <p id="eleventhdtls-first-title" class="eleventh-first-subtitle">Cara pengajuan asuransi jiwa</p>
                <p id="eleventhdtls-first-details" class="eleventh-first-details">Pemegang Polis melakukan pengajuan asuransi jiwa melalui aplikasi Digibank dan dapat memilih besaran Uang Pertanggungan atau besaran Premi yang dikehendaki.</p>
            </div>
            <div class="eleventh-second-container">
                <p id="eleventhdtls-second-title" class="eleventh-second-subtitle">Cara pembayaran premi</p>
                <p id="eleventhdtls-second-details" class="eleventh-second-details">Premi dapat dibayar secara bulanan dan akan diakui oleh Manulife Indonesia pada saat premi diterima di rekening Manulife Indonesia.</p>
            </div>
            <div class="eleventh-third-container">
                <div class="eleventh-third-titlecont">
                    <p id="eleventhdtls-third-title" class="eleventh-third-subtitle">Masa leluasa (grace period)</p>
                    <cds-icon id="information-icon" class="info-icon" icon="action:info_2" width="1.125rem" height="1.125rem"></cds-icon>
                </div>
                <p id="eleventhdtls-third-details" class="eleventh-third-details">Masa Leluasa (grace period) selama 45 hari kalender sejak tanggal jatuh tempo pembayaran premi. Apabila premi tidak dibayar hingga masa leluasa (grace period) berakhir, maka polis akan berakhir (Lapsed).</p>
            </div>
            <div class="eleventh-fourth-container">
                <p id="eleventhdtls-fourth-title" class="eleventh-fourth-subtitle">Masa Mempelajari Polis (Cooling Off Period)</p>
                <p id="eleventhdtls-fourth-firstdetails" class="eleventh-fourth-firstdetails">a) Polis asuransi dalam bentuk elektronik akan dikirimkan kepada Pemegang Polis setelah proses pengajuan selesai dan pembayaran Premi telah diterima di rekening Manulife Indonesia.</p>
                <p id="eleventhdtls-fourth-seconddetails" class="eleventh-fourth-seconddetails">b) Pemegang Polis mempunyai hak untuk mempelajari Polis dalam waktu 14 hari kalender sejak Tanggal Penerimaan Polis.</p>
                <p id="eleventhdtls-fourth-thirddetails" class="eleventh-fourth-thirddetails">c) Apabila Pemegang Polis tidak setuju dengan Ketentuan Umum dan Ketentuan Khusus Polis maka Pemegang Polis dapat mengajukan pembatalan dengan memberitahukan secara tertulis kepada Manulife Indonesia.</p>
                <p id="eleventhdtls-fourth-fourthdetails" class="eleventh-fourth-fourthdetails">d) Manulife Indonesia akan mengembalikan Premi yang telah dibayarkan oleh Pemegang Polis setelah dikurangi biaya administrasi termasuk namun tidak terbatas pada biaya pemeriksaan medis (bila ada) dan Polis menjadi batal sejak awal.</p>
                <p id="eleventhdtls-fourth-fifthdetails" class="eleventh-fourth-fifthdetails">e) Sebaliknya, bila tidak ada pemberitahuan atau keberatan yang diajukan secara tertulis oleh Pemegang Polis dalam kurun waktu tersebut, maka Pemegang Polis dianggap setuju dengan seluruh isi dari Ketentuan Umum dan Ketentuan Khusus Polis ini.</p>
            </div>
            <div class="eleventh-fifth-container">
                <p id="eleventhdtls-fifth-title" class="eleventh-fifth-subtitle">Cara pengajuan klaim</p>
                <p id="eleventhdtls-fifth-details" class="eleventh-fifth-details">Pengajuan klaim dapat dilakukan secara tertulis atau melalui MiEClaim dalam 90 hari kalender sejak tertanggung meninggal dunia atau 180 hari kalender sejak tertanggung menderita ketidakmampuan total tetap.</p>
            </div>
            <div class="eleventh-sixth-container">
                <p id="eleventhdtls-sixth-title" class="eleventh-sixth-subtitle">Pembayaran klaim</p>
                <p id="eleventhdtls-sixth-details" class="eleventh-sixth-details">Manulife Indonesia akan melakukan pembayaran manfaat pertanggungan paling lama 30 hari kalender sejak klaim disetujui dengan ketentuan bahwa dokumen klaim telah secara lengkap diterima dan telah melalui proses pengujian klaim.</p>
            </div>
            <div class="eleventh-seventh-container">
                <p id="eleventhdtls-seventh-title" class="eleventh-seventh-subtitle">Perubahan polis</p>
                <p id="eleventhdtls-seventh-details" class="eleventh-seventh-details">Apabila terjadi perubahan ketentuan polis mengenai tapi tidak terbatas pada ketentuan manfaat, biaya, dan risiko akan diberitahukan kepada pemegang polis melalui nomor telepon atau alamat korespondensi terkini pemegang polis yang tercatat pada Manulife Indonesia paling lambat 30 hari kerja sebelum terjadi perubahan.</p>
            </div>
            <div class="eleventh-eighth-container">
                <p id="eleventhdtls-eighth-title" class="eleventh-eighth-subtitle">Kelengkapan dokumen pengajuan klaim</p>
                <div class="eleventh-first-subcontainer">
                    <p id="eleventh-firstsub-subtitle" class="eleventh-firstsub-title">Klaim meninggal dunia:</p>
                    <p id="eleventh-firstsub-firstdetails" class="eleventh-firstsub-firstdtls">a. Formulir Klaim Meninggal Dunia yang disediakan oleh Penanggung dan telah diisi lengkap</p>
                    <p id="eleventh-firstsub-seconddetails" class="eleventh-firstsub-seconddtls">b. Surat keterangan dokter yang memeriksa jenazah Tertanggung yang menjelaskan sebab-sebab kematian Tertanggung;</p>
                    <p id="eleventh-firstsub-thirddetails" class="eleventh-firstsub-thirddtls">c. Surat keterangan meninggal dari pihak yang berwenang;</p>
                    <p id="eleventh-firstsub-fourthdetails" class="eleventh-firstsub-fourthdtls">d. Surat Keterangan/Tanda Bukti Lapor dari Kepolisian R.I (jika peserta meninggal dunia karena hal-hal seperti namun tidak terbatas Kecelakaan dan/atau pembunuhan);</p>
                    <p id="eleventh-firstsub-fifthdetails" class="eleventh-firstsub-fifthdtls">e. Surat keterangan mengenai bukti diri yang berkepentingan dalam Polis; dan</p>
                    <p id="eleventh-firstsub-sixthdetails" class="eleventh-firstsub-sixthdtls">f. Fotokopi identitas Tertanggung dan Yang Ditunjuk yang masih berlaku.</p>
                </div>
                <div class="eleventh-second-subcontainer">
                    <p id="eleventh-secondsub-subtitle" class="eleventh-secondsub-title">Klaim ketidakmampuan total tetap:</p>
                    <p id="eleventh-secondsub-firstdetails" class="eleventh-secondsub-firstdtls">a. Formulir Klaim Ketidakmampuan Total Tetap yang disediakan oleh Penanggung dan telah diisi lengkap;</p>
                    <p id="eleventh-secondsub-seconddetails" class="eleventh-secondsub-seconddtls">b. Surat keterangan dokter yang memeriksa Tertanggung yang menjelaskan sebab-sebab terjadinya Ketidakmampuan Tertanggung;</p>
                    <p id="eleventh-secondsub-thirddetails" class="eleventh-secondsub-thirddtls">c. Surat Keterangan/Tanda Bukti Lapor dari Kepolisian R.I dalam hal Tertanggung mengalami Ketidakmampuan Total Tetap akibat Kecelakaan atau hal tidak wajar; dan</p>
                    <p id="eleventh-secondsub-fourthdetails" class="eleventh-secondsub-fourthdtls">d. Fotokopi identitas Tertanggung yang masih berlaku.</p>
                </div>
                <div class="eleventh-third-subcontainer">
                    <p id="eleventh-thirdsub-subtitle" class="eleventh-thirdsub-title">Klaim Akhir Masa Pertanggungan:</p>
                    <p id="eleventh-thirdsub-firstdetails" class="eleventh-thirdsub-firstdtls">a. Surat keterangan mengenai bukti diri Pemegang Polis</p>
                </div>
            </div>
            <div class="eleventh-ninth-container">
                <p id="eleventhdtls-ninth-title" class="eleventh-ninth-subtitle">Pengajuan Keluhan/ Pertanyaan</p>
                <p id="eleventhdtls-ninth-details" class="eleventh-ninth-details">Apabila ada hal-hal yang ingin disampaikan atau ditanyakan, nasabah dapat menghubungi Customer Contact Center Manulife Indonesia di kantor pemasaran terdekat atau menghubungi kami melalui saluran berikut:</p>
            </div>
            <div class="eleventh-tenth-container">
                <p id="eleventhdtls-tenth-title" class="eleventh-tenth-subtitle">Customer Contact Center</p>
                <div class="eleventh-fourth-subcontainer">
                    <p id="eleventh-fourthsub-firstdetails" class="eleventh-fourthsub-firstdtls">Sampoerna Strategic Square, North Tower – Lantai GF</p>
                    <p id="eleventh-fourthsub-seconddetails" class="eleventh-fourthsub-seconddtls">Jl. Jenderal Sudirman Kav. 45 Jakarta 12930</p>
                    <p id="eleventh-fourthsub-thirddetails" class="eleventh-fourthsub-thirddtls">Tel: <strong>(021) 2555 7777</strong></p>
                    <p id="eleventh-fourthsub-fifthdetails" class="eleventh-fourthsub-fifthdtls"><strong>0800 1 606060</strong> (Bebas Pulsa)</p>
                    <p id="eleventh-fourthsub-sixthdetails" class="eleventh-fourthsub-sixthdtls">Email : <strong>customerserviceid@manulife.com</strong></p>
                </div>
            </div>
        </div>
        <div class="details-twelfth-container">
            <p id="twelfth-main-title" class="dtlstwelfth-main-title">Biaya - Biaya</p>
            <p id="twelfth-first-details" class="dtlstwelfth-first-details">Premi yang Anda bayarkan sudah termasuk biaya administrasi, biaya asuransi, komisi bank dan biaya pemasaran (bila ada).</p>
        </div>
        <div class="details-thirteenth-container">
            <p id="thirteenth-main-title" class="dtlsthirteenth-main-title">Ilustrasi</p>
            <div class="thirteenth-first-container">
                <p id="thirteenthdtls-first-title" class="thirteenth-first-subtitle">Skenario A</p>
                <p id="thirteenthdtls-first-details" class="thirteenth-first-details">Bapak Radin pada usia 25 tahun membeli produk MiFLIP dengan uang pertanggungan sebesar Rp700.000.000,00 dan masa pertanggunggan selama 10 tahun. Bapak Radin membayar premi sebesar Rp1.000.000,00 setiap bulan selama 10 tahun.</p>
                <div id="thirteenthdtls-first-scroll" class="thirteenth-first-scroll"></div>
            </div>
            <div class="thirteenth-second-container">
                <p id="thirteenthdtls-second-title" class="thirteenth-second-subtitle">Skenario B</p>
                <p id="thirteenthdtls-second-details" class="thirteenth-second-details">Jika Bapak Radin meninggal dunia atau mengalami ketidakmampuan total tetap pada tahun pertama polis (misalnya pada bulan ke-9 polis) <em>karena kecelakaan</em>, manfaat yang diterima adalah uang pertanggungan sebesar Rp700.000.000,00 dan polis berakhir.</p>
                <div id="thirteenthdtls-second-scroll" class="thirteenth-second-scroll"></div>
            </div>
            <div class="thirteenth-third-container">
                <p id="thirteenthdtls-third-title" class="thirteenth-third-subtitle">Skenario C</p>
                <p id="thirteenthdtls-third-details" class="thirteenth-third-details">Jika Bapak Radin meninggal dunia atau mengalami ketidakmampuan total tetap pada tahun pertama polis (misalnya pada bulan ke-9 polis) <em>bukan karena kecelakaan</em>, maka tidak ada manfaat yang diterima dan polis berakhir.</p>
                <div id="thirteenthdtls-third-scroll" class="thirteenth-third-scroll"></div>
            </div>
            <div class="thirteenth-fourth-container">
                <p id="thirteenthdtls-fourth-title" class="thirteenth-fourth-subtitle">Skenario D</p>
                <p id="thirteenthdtls-fourth-details" class="thirteenth-fourth-details">Jika Bapak Radin meninggal dunia atau mengalami ketidakmampuan total tetap  pada tahun ke-5 polis, manfaat yang diterima adalah uang pertanggungan sebesar Rp700.000.000,00 dan polis berakhir.</p>
                <div id="thirteenthdtls-fourth-scroll" class="thirteenth-fourth-scroll"></div>
            </div>
            <div class="thirteenth-fifth-container">
                <p id="thirteenthdtls-fifth-title" class="thirteenth-fifth-subtitle">Skenario E</p>
                <p id="thirteenthdtls-fifth-details" class="thirteenth-fifth-details">Jika Bapak Radin tidak pernah melakukan klaim hingga akhir pertanggungan, maka Bapak Radin akan mendapat pengembalian premi yang telah terbayarkan sebesar Rp120.000.000,00</p>
                <div id="thirteenthdtls-fifth-scroll" class="thirteenth-fifth-scroll"></div>
            </div>
            <div class="thirteenth-sixth-container">
                <p id="thirteenthdtls-sixth-title" class="thirteenth-sixth-subtitle">Skenario F</p>
                <p id="thirteenthdtls-sixth-details" class="thirteenth-sixth-details">Jika Bapak Radin mengakhiri polis pada tahun ke-9 atau setelahnya, maka 70% dari premi yang telah dibayarkan akan dikembalikan sebagai manfaat pengembalian premi sebesar</p>
                <div id="thirteenthdtls-sixth-scroll" class="thirteenth-sixth-scroll"></div>
            </div>
            <div class="thirteenth-seventh-container">
                <p id="thirteenthdtls-seventh-title" class="thirteenth-seventh-subtitle">Skenario G</p>
                <p id="thirteenthdtls-seventh-details" class="thirteenth-seventh-details">Jika Bapak Radin mengakhiri polis sebelum atau pada tahun ke-8, maka tidak ada pengembalian premi yang dibayarkan</p>
                <div id="thirteenthdtls-seventh-scroll" class="thirteenth-seventh-scroll"></div>
            </div>
        </div>
        <div class="details-fourteenth-container">
            <p id="fourteenth-main-title" class="dtlsfourteenth-main-title">Catatan - Catatan</p>
            <p id="fourteenth-first-details" class="dtlsfourteenth-first-details">A. Calon Pemegang Polis harus membaca dengan teliti Ringkasan Informasi Produk dan Layanan Versi Personal ini sebelum memutuskan untuk membeli produk asuransi.</p>
            <p id="fourteenth-second-details" class="dtlsfourteenth-second-details">B. Calon Pemegang Polis harus membaca, memahami dan menyelesaikan pengajuan asuransi melalui aplikasi Digibank setelah menyetujui keseluruhan isi dari ilustrasi dan informasi yang terdapat di dalam Ringkasan Informasi Produk dan Layanan Versi Personal ini.</p>
            <p id="fourteenth-third-details" class="dtlsfourteenth-third-details">C. Ringkasan Informasi Produk dan Layanan Versi Personal ini merupakan penjelasan singkat dari produk asuransi MiFirst Life Protector dan bukan merupakan bagian dari aplikasi pengajuan asuransi dan Polis.</p>
            <p id="fourteenth-fourth-details" class="dtlsfourteenth-fourth-details">Ketentuan lengkap mengenai produk asuransi MiFirst Life Protector dapat calon Pemegang Polis pelajari pada Polis MiFirst Life Protector yang diterbitkan Manulife Indonesia dan akan dikirimkan kepada calon Pemegang Polis setelah proses persetujuan aplikasi.</p>
            <p id="fourteenth-fifth-details" class="dtlsfourteenth-fifth-details">D. Manulife Indonesia berhak menerima atau menolak permohonan asuransi berdasarkan keputusan seleksi risiko yang dilakukan oleh Manulife Indonesia. Keputusan klaim sepenuhnya merupakan keputusan Manulife Indonesia yang mengacu pada Ketentuan Polis MiFirst Life Protector.</p>
            <p id="fourteenth-sixth-details" class="dtlsfourteenth-sixth-details">E. Calon Pemegang Polis dan/atau calon Tertanggung mempunyai kewajiban untuk menyediakan informasi dan/atau data sesuai dengan kondisi sesungguhnya. Apabila Manulife Indonesia mengetahui adanya informasi dan/atau data calon Pemegang Polis dan/atau calon Tertanggung yang tidak sesuai dengan kondisi sesungguhnya maka Manulife Indonesia berhak untuk membatalkan Pertanggungan.</p>
            <p id="fourteenth-seventh-details" class="dtlsfourteenth-seventh-details">F. Selain tercantum pada Ringkasan Informasi Produk dan Layanan Versi Personal ini, Syarat dan Ketentuan yang berlaku untuk produk asuransi MiFirst Life Protector juga tercantum dalam dokumen Ringkasan</p>
            <p id="fourteenth-eighth-details" class="dtlsfourteenth-eighth-details">Informasi Produk dan Layanan Versi Umum dan brosur yang dapat diunduh pada www.manulife.co.id dan Ketentuan Polis.</p>
            <p id="fourteenth-ninth-details" class="dtlsfourteenth-ninth-details">G. Ringkasan Informasi Produk dan Layanan Versi Personal ini mengacu pada dan berdasarkan pada Polis MiFirst Life Protector yang diterbitkan oleh Manulife Indonesia. Apabila terdapat perbedaan interpretasi antara Ringkasan Informasi Produk dan Layanan Versi Personal ini dengan ketentuan Polis MiFirst Life Protector, maka yang akan berlaku adalah ketentuan Polis MiFirst Life Protector.</p>
            <p id="fourteenth-tenth-details" class="dtlsfourteenth-tenth-details">H. Manulife Indonesia merupakan perusahaan asuransi jiwa yang terdaftar dan diawasi oleh Otoritas Jasa Keuangan (OJK).</p>
        </div>
        <div class="details-fifteenth-container">
            <p id="fifteenth-main-title" class="dtlsfifteenth-main-title">Pernyataan & Kesepakatan</p>
            <p id="fifteenth-first-details" class="dtlsfifteenth-first-details">A. Saya telah membaca dan memahami ilustrasi yang disampaikan, telah mendapat penjelasan yang baik, benar dan lengkap mengenai produk MiFirst Life Protector yang ditawarkan dan mengerti atas penjelasan yang disampaikan dalam Ringkasan Informasi Produk dan Layanan Versi Personal ini.</p>
            <p id="fifteenth-second-details" class="dtlsfifteenth-second-details">B. Saya memahami bahwa ilustrasi ini bukan merupakan kontrak asuransi, tidak mengikat, dan bukan pula merupakan bagian dari Polis. Hak dan kewajiban sebagai Pemegang Polis dan/atau Tertanggung dan ketentuan lengkap dan mengikat mengenai produk asuransi MiFirst Life Protector ini tercantum di dalam Polis.</p>
            <p id="fifteenth-third-details" class="dtlsfifteenth-third-details">C. Saya memutuskan untuk membeli produk MiFirst Life Protector yang ditawarkan tanpa ada paksaan dari pihak manapun dan bersedia tunduk pada ketentuan-ketentuan yang tercantum di dalam Polis.</p>
            <p id="fifteenth-fourth-details" class="dtlsfifteenth-fourth-details">D. Saya mengerti bahwa pertanggungan yang ditawarkan mulai berlaku pada saat:</p>
            <ul id="fifteenth-fourth-detailslist" class="dtlsfifteenth-fourth-detailslist">
                <li id="fifteenth-fourthlist-firstoption" class="dtlsfifteenth-fourthlist-firstoption">Formulir Pendaftaran dan dokumen pendukung telah dilengkapi secara penuh dan disetujui oleh Saya, selaku calon Tertanggung,</li>
                <li id="fifteenth-fourthlist-secondoption" class="dtlsfifteenth-fourthlist-secondoption">Premi Pertama telah dibayar secara lunas dan diterima oleh Manulife Indonesia.</li>
                <li id="fifteenth-fourthlist-secondoption" class="dtlsfifteenth-fourthlist-thirdoption">Calon Tertanggung dalam keadaan dapat diasuransikan menurut bagian seleksi risiko (underwriting) sesuai dengan ketentuan yang berlaku pada Manulife Indonesia.</li>
            </ul>
            <p id="fifteenth-fifth-details" class="dtlsfifteenth-fifth-details">E. Saya mengerti dan menyetujui apabila dalam proses penerbitan Polis terjadi perubahan perhitungan usia, maka Polis akan diterbitkan dengan menggunakan perhitungan usia yang berlaku di Manulife Indonesia. Ringkasan Ilustrasi yang tertera pada Ringkasan Informasi Produk dan Layanan Versi Personal ini hanya berlaku untuk usia yang tercantum didalamnya dan tidak berlaku jika terdapat perubahan usia.</p>
            <p id="fifteenth-sixth-details" class="dtlsfifteenth-sixth-details">F. Saya dengan ini memberikan kuasa yang tidak dapat ditarik kembali kepada Manulife Indonesia dengan hak substitusi untuk menggunakan, mengungkapkan, menyediakan data/informasi mengenai Saya yang diperoleh dan dikumpulkan oleh Manulife Indonesia atau afiliasinya kepada pihak yang berkepentingan (termasuk namun tidak terbatas pada pihak reasuransi, penyidik klaim, bank/lembaga keuangan, asosiasi industri dan/atau badan hukum lain, baik di dalam maupun di luar negeri) baik yang memiliki kerja sama atau tidak dengan Manulife Indonesia atau afiliasinya untuk tujuan pembayaran klaim dan/atau melakukan proses lainnya yang terkait dengan pengajuan permintaan asuransi jiwa Saya maupun untuk penawaran produk asuransi atau produk keuangan lainnya, untuk tujuan pencegahan tindak pidana pencucian uang, suap dan pendanaan terorisme atau terkait dengan  sanksi ekonomi, yang berkaitan dengan setiap proses pidana/perdata atau proses arbitrase, investigasi, audit atau pemeriksaan (termasuk penyelidikan internal, audit atau pemeriksaan) terhadap Manulife Indonesia , afiliasi, karyawan atau agen, pelaporan pertukaran informasi antar negara atau untuk tujuan lain sebagaimana yang diperkenankan oleh pedoman Manulife Indonesia dan kode etik Manulife Indonesia maupun hukum dan peraturan perundang-undangan yang berlaku.</p>
            <p id="fifteenth-seventh-details" class="dtlsfifteenth-seventh-details">Kepada Saya telah dijelaskan mengenai ringkasan Manfaat Pertanggungan, risiko, dan biaya-biaya yang akan dikenakan yang tercantum dalam produk MiFirst Life Protector ini dan Saya menyatakan telah mengerti secara penuh dan oleh karenanya Saya tanpa paksaan dari siapapun juga telah menyetujui keseluruhan isi dari ilustrasi dan catatan-catatan yang terdapat di dalamnya, termasuk tetapi tidak terbatas kepada metodologi perhitungan serta Manfaat Pertanggungan. Dengan persetujuan yang telah saya berikan pada saat ditawarkan produk ini, dan saya akan mempelajari Polis dalam masa mempelajari Polis (Cooling Off Period), maka Saya menyatakan bahwa Saya telah membaca, mengerti, memahami dan telah mendapatkan penjelasan yang baik atas setiap halaman yang terdapat pada Ringkasan Informasi Produk dan Layanan Versi Personal ini.</p>
        </div>
    </div>
    <div class="button-container">
        <cds-popup-actions>
            <cds-button id="back-button" class="back" [config]="backButton" (click)="buttonClicked('back')"></cds-button>
        </cds-popup-actions>
    </div>
</div>
<div id="health-statement-container" class="health-statement">
    <p id="health-statement-title" class="title">Pernyataan Kesehatan</p>
    <div class="health-details">
        <p id="first-part" class="paragraph">Saya berumur dibawah atau 50 tahun.</p>
        <p id="second-part" class="second-paragraph">
            Saya dalam kondisi sehat dan tidak pernah terdiagnosa atau terkena penyakit seperti:
        </p>
        <ul>
            <li id="first-disease-option">kanker atau pertumbuhan sel lainnya</li>
            <li id="second-disease-option">tekanan darah tinggi</li>
            <li id="third-disease-option"> nyeri dada, serangan jantung, stroke</li>
            <li id="fourth-disease-option"> kencing manis</li>
            <li id="fifth-disease-option">hepatitis</li>
            <li id="sixth-disease-option"> penyakit jiwa</li>
            <li id="seventh-disease-option">epilepsi</li>
            <li id="eighth-disease-option">HIV / AIDS</li>
            <li id="ninth-disease-option">kelainan yang berhubungan dengan pembuluh darah, sistem pernafasan, darah, ginjal, hati, lambung, atau
                saluran pencernaan.</li>
        </ul>
        <p id="third-part" class="paragraph">
            Saya tidak sedang menunggu hasil tes diagnosa ataupun mengkonsumsi obat secara rutin.
        </p>
        <p id="fourth-part" class="second-paragraph">
            Saya tidak pernah ditolak pada pengajuan aplikasi asuransi atau dikenakan premi
            tambahan / pengecualian / penawaran lain baik di PT Asuransi Jiwa Manulife 
            Indonesia maupun di perusahaan asuransi lain.
        </p>
    </div>
    <div class="health-statement-buttons">
        <cds-button id="agree-button" class="agree" [config]="agreeConfig" (click)="sayaSetuhu()"></cds-button>
        <cds-button id="back-button" class="back" [config]="backConfig" (click)="kembali()"></cds-button>
    </div>   
</div>
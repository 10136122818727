import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, ErrorHandler } from '@angular/core';
declare var newrelic: any;

@Injectable({
  providedIn: 'root'
})
export class ErrorServicesService implements ErrorHandler {
  handleError(error: Error, attributes?: any): void {
    if (['', null, undefined].includes(attributes) === true) {
      console.error(error);
      newrelic.noticeError(error);
    } else {
      newrelic.noticeError(error, attributes);
    }
  }

  getClientMessage(error: Error): string {
    if (!navigator.onLine) {
      return 'No Internet Connection';
    }
    return error.message ? error.message : error.toString();
  }

  getClientStack(error: Error): string {
    return error.stack || 'undefined';
  }

  getServerMessage(error: HttpErrorResponse): string {
    return error.message;
  }

  getServerStack(error: HttpErrorResponse): string {
    return 'stack';
  }
}

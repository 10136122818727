<app-header></app-header>
<div class="final-failed-page">
    <p id="failed-completion-title" class="title">Mohon Maaf</p>
    <div class="failed-page-details">
        <div id="first-paragraph" class="paragraph">
            Mohon maaf, pengajuan asuransi MiFirst Life Protector anda belum berhasil untuk dilakukan.
        </div>
        <div id="second-paragraph" class="paragraph">
            Mohon dapat periksa kembali kelengkapan pengajuan dan pembayaran Anda
        </div>
        <div id="third-paragraph" class="paragraph">
            Dan mohon anda dapat mencoba kembali melalui aplikasi DBS Digibank.
        </div>
    </div>
    <div class="final-page-buttons">
        <cds-button 
            id="back-button" 
            class="back" 
            [config]="backDigibank" 
            (click)="backToDigibank()">
        </cds-button>
    </div>
</div>
<app-footer></app-footer>

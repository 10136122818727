import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@cds/ng-web-components/popup';
import { CdsButtonConfig } from '@cds/ng-core/button';

@Component({
  selector: 'app-statement',
  templateUrl: './statement.component.html',
  styleUrls: ['./statement.component.scss']
})
export class StatementComponent implements OnInit {
  backButton: CdsButtonConfig = {
    label: 'Tutup',
    buttonType: 'button',
    style: 'secondary',
    size: 'sm'
  };

  constructor(
    public dialogRef: MatDialogRef<StatementComponent>
  ) {
    // This is intentional
  }

  ngOnInit(): void {
    // This is intentional
  }

  buttonClicked(action: string): void {
    if (action === 'close' || action === 'back') {
      this.dialogRef.close({ agree: false });
    } else {
      this.dialogRef.close({ agree: true });
    }
  }
}

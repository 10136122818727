<div class="carousel-container">
    <div id="title" class="carousel-title">Layanan Manulife</div>
    <div class="main-carousel">
        <div class="manulife-services" (scroll)="onScroll()">
            <div class="services-container">
                <a [attr.href]="serviceUrl" class="service-site">
                    <div class="service" *ngFor="let service of manulifeService; index as i; trackBy: trackByFn" [id]="'service' + (i + 1)" [class]="getServiceIcon(i)" (click)="onServiceClick(service.icon)">
                        <cds-icon [id]="'service-icon' + (i + 1)" class="service-icon" [icon]="service.icon === 'paperless-icon' ? 'form:easy_application' : 'presentation:like'" [config]="iconConfig" width="5rem" height="5rem"></cds-icon>
                        <div [id]="'service-description' + (i + 1)" class="service-description">{{ getServiceDescription(i) }}</div>
                    </div>
                </a>
            </div>
        </div>
        <div class="ellipsis-container">
            <div class="ellipsis" *ngFor="let service of manulifeService; index as i; trackBy: trackByFn" [id]="'ellipsis' + (i + 1)" [class.active]="onScroll() === manulifeService[i].icon"></div>
        </div>
    </div>
    <div class="link">
        <a [attr.href]="serviceUrl" class="manulife-service-site">
            <cds-link id="carousel-link" class="manulife-service-link" [config]="manulifeServices" (click)="onPopupClick()"></cds-link>
        </a>
    </div>
</div>
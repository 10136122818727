import { Component, OnDestroy, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { CdsStepperComponent } from '@cds/ng-web-components/stepper';
import { Subscription } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { SharedService } from '../_shared/shared.service';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss']
})
export class TemplateComponent implements OnInit, AfterViewInit, OnDestroy {
  dataPassed = 0;

  constructor(
    private ds: SharedService,
    public changeDetectorRef: ChangeDetectorRef
  ) {
    // This is intentional
  }

  subscription = new Subscription();
  completedSteps = [{ completed: 0 }, { completed: 0 }, { completed: 0 }, { completed: 0 }];
  selectedSteps: Array<number> = [];
  currentSelectedStep = 0;

  @ViewChild(CdsStepperComponent, { static: false }) stepper!: CdsStepperComponent;

  ngOnInit(): void {
    if ('current-step' in sessionStorage) {
      this.currentSelectedStep = JSON.parse(sessionStorage.getItem('current-step') || '[]');
    } else {
      sessionStorage.setItem('current-step', JSON.stringify(this.currentSelectedStep));
    }
    if ('selected-steps' in sessionStorage) {
      this.selectedSteps = JSON.parse(sessionStorage.getItem('selected-steps') || '[]');
    } else {
      this.selectedSteps.push(0);
      sessionStorage.setItem('selected-steps', JSON.stringify(this.selectedSteps));
    }
    if ('completed-steps' in sessionStorage) {
      this.completedSteps = JSON.parse(sessionStorage.getItem('completed-steps') || '[]');
    } else {
      sessionStorage.setItem('completed-steps', JSON.stringify(this.completedSteps));
    }
    this.subscription = this.ds?.getData().subscribe(x => {
      this.dataPassed = x;
    });
  }

  clearNextSteps(stepper: any): void {
    this.completedSteps = JSON.parse(sessionStorage.getItem('completed-steps') || '[]');
    this.completedSteps.forEach((elem, index) => {
      if (index === stepper.previouslySelectedIndex) {
        if (elem.completed < 1) {
          stepper.previouslySelectedStep.interacted = false;
        }
      }
    });
    const stepData = this.completedSteps;
    sessionStorage.setItem('completed-steps', JSON.stringify(stepData));
  }

  ngAfterViewInit(): void { // No Test at the moment
    let step;
    for (step = 1; step < this.selectedSteps.length; step++) {
      if ((this.currentSelectedStep + 1) > step) {
        this.stepper.next();
      } else if (this.currentSelectedStep === 1) {
        this.stepper.selectedIndex = 1;
      } else {
        // do nothing
      }
    }
    this.subscription = this.stepper?.selectionChange
      .pipe(pluck('selectedIndex'))
      .subscribe((index: any) => {
        if (this.selectedSteps.indexOf(index) === -1) {
          this.selectedSteps.push(index);
          if (this.currentSelectedStep) {
            this.stepper.selectedIndex = this.currentSelectedStep;
          }
          sessionStorage.setItem('selected-steps', JSON.stringify(this.selectedSteps));
        }
      });
    this.changeDetectorRef.markForCheck();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}

import { Component, OnInit } from '@angular/core';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { CdsLinkConfig } from '@cds/ng-core/link';
import { MliAnalyticsService } from '@mli/analytics';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {
  serviceUrl = "";
  dbsParams: any = {};
  manulifeService: any = [
    {
      icon: 'paperless-icon',
      description: 'Ajukan klaim asuransi tanpa kertas'
    },
    {
      icon: 'change-to-police-icon',
      description: 'Lakukan perubahan pada polis'
    },
    {
      icon: 'new-protection-icon',
      description: 'Tambah perlindungan baru'
    }
  ];
  manulifeServices: CdsLinkConfig = {
    label: 'Lihat Layanan Manulife',
    type: 'standard',
    style: 'primary'
  };
  iconConfig: CdsIconConfig = {
    color: "white"
  };
  constructor(
    private mliAnalyticsService: MliAnalyticsService
  ) {
  }

  ngOnInit(): void {
    const el = document.querySelector('.manulife-services');
    self.setInterval(() => {
      if (el!.scrollLeft === 0) {
        self.setInterval(() => {
          setTimeout(() => {
            if (el!.scrollLeft < 214) {
              el!.scrollTo(el!.scrollLeft + 2, 0);
            }
          }, 3000);

          if (el!.scrollLeft === 214) {
            setTimeout(() => {
              if (el!.scrollLeft !== el!.scrollWidth) {
                el!.scrollTo(el!.scrollLeft + 2, 0);
              }
            }, 3000);
          }
          if (el!.scrollLeft >= (el!.clientWidth < 420 ? 370 : 285)) {
            setTimeout(() => {
              if (el!.scrollLeft > 0) {
                el!.scrollTo(el!.scrollLeft - 7, 0);
              }
            }, 3000);
          }
        }, 11);
      }
    }, 4000);
    this.dbsParams = JSON.parse(sessionStorage.getItem('dbsParams') || '{}');
  }

  onScroll(): string {
    const el = document.querySelector('.manulife-services');
    if (el!.scrollLeft <= 120) {
      return this.manulifeService[0].icon;
    } else if (el!.scrollLeft >= 121 && el!.scrollLeft <= 270) {
      return this.manulifeService[1].icon;
    } else {
      return this.manulifeService[2].icon;
    }
  }

  getServiceIcon(index: number): string {
    return this.manulifeService[index].icon;
  }

  getServiceDescription(index: number): string {
    return this.manulifeService[index].description;
  }

  onServiceClick(linkValue: string): void {
    if (linkValue === 'paperless-icon') {
      this.mliAnalyticsService?.trackEvent({
      event_label: 'Link Ajukan klaim',
      event_category: 'Succeed Page',
      event_type: 'Click button_link ajukan klaim',
      dbs_adobe_id: this.dbsParams.adobeId,
      dbs_marketing_id: this.dbsParams.marketingId,
      dbs_authcode: this.dbsParams.authCode
    });
      this.serviceUrl = "https://www.manulife.co.id/id/layanan/layanan-klaim.html";
    } else if (linkValue === 'change-to-police-icon') {
      this.mliAnalyticsService?.trackEvent({
        event_label: 'Link Lakukan perubahan',
        event_category: 'Succeed Page',
        event_type: 'Click button_lakukan perubahan',
        dbs_adobe_id: this.dbsParams.adobeId,
        dbs_marketing_id: this.dbsParams.marketingId,
        dbs_authcode: this.dbsParams.authCode
      });
      this.serviceUrl = "https://www.manulife.co.id/id/layanan/kelola-polis.html";
    } else {
      this.mliAnalyticsService?.trackEvent({
        event_label: 'Link tambah perlindungan',
        event_category: 'Succeed Page',
        event_type: 'Click button_explore claim process, others',
        dbs_adobe_id: this.dbsParams.adobeId,
        dbs_marketing_id: this.dbsParams.marketingId,
        dbs_authcode: this.dbsParams.authCode
      });
      this.serviceUrl = "https://www.manulife.co.id/id/solusi.html";
    }
  }

  onPopupClick(): void {
    this.serviceUrl = "https://www.manulife.co.id/id/layanan.html";
  }

  trackByFn(index: any, manulifeService: any): any {
    return manulifeService.service;
  }
}

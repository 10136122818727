import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorServicesService } from '../_services/error-services.service';
import { GeneralService } from '../_services/general.service';
import { Subject, Subscription } from 'rxjs';
import { CustomerDetails } from '../_model/customerdetails-model';
import { CustomerInfo } from '../_model/customer-info.model';
import { SharedService } from '../_shared/shared.service';
import { Product, ProductEligibility } from '../_model/product-eligiblity.model';
import { CustomerEligibility } from '../_model/customer-eligibility.model';
import { DatePipe } from '@angular/common';
import { CookieService } from 'ngx-cookie';
import { MliAnalyticsService } from '@mli/analytics';
import { takeUntil } from 'rxjs/operators';
import { CustomerInfoDetails } from '../_model/customer-info-details.model';
import { EligibilityInfo } from '../_model/eligibility-info.model';

@Component({
  selector: 'app-loading-page',
  templateUrl: './loading-page.component.html',
  styleUrls: ['./loading-page.component.scss']
})
export class LoadingPageComponent implements OnInit, OnDestroy {
  model: CustomerDetails = new CustomerDetails();
  customerInfo: CustomerInfo = new CustomerInfo();
  customerInfoDetailsReq: CustomerInfoDetails = new CustomerInfoDetails();
  customerData: any = {};
  customer: any = {};
  customerDetails: any = {};
  customerDuplicate: any = {};
  customerToken: any = {};
  customerEligibilityRes: any = {};
  productEligibilityRes: any = {};
  eligibilityPayload: EligibilityInfo = new EligibilityInfo();
  eligibilityData: any = {};
  age = 0;
  abobeParamObj: any = {
    adobeId: '',
    marketingId: ''
  };
  authCodeObj: any = {
    authCode: ''
  };
  mliTrackingObj: any = {
    authCode: '',
    adobeId: '',
    marketingId: ''
  };
  maxSumInsured = 0;
  multiplier = 0;
  validWorkAddress = true;
  validHomeAddress = true;
  validHeader = true;
  hasTimeoutOpen = false;
  destroySub = new Subject();
  subscription: Subscription = new Subscription();
  customerEligibilityData: CustomerEligibility = new CustomerEligibility();
  productEligibilityData: ProductEligibility = new ProductEligibility();
  productData: Product = new Product();
  createdDate = new Date().toLocaleString('en-US', { timeZone: 'Asia/Jakarta' });
  objectId: any = '';

  constructor(
    private _router: Router,
    public _error: ErrorServicesService,
    private _service: GeneralService,
    public _activeRoute: ActivatedRoute,
    private _sharedService: SharedService,
    private datepipe: DatePipe,
    public cookie: CookieService,
    private mliAnalyticsService: MliAnalyticsService
  ) {
    console.log('constructor is called');
    this._sharedService?.implementTimeout(this.hasTimeoutOpen, this.destroySub);
  }

  ngOnInit(): void {
    console.log('ngOnInit is called');
    console.log('ngOnInit: DBS Data ==> ', this.cookie.get('authCode'));
    console.log('ngOnInit: Splitted DBS Data ==> ', this.cookie.get('authCode')?.split('&'));
    if ((this.cookie.get('authCode')?.split('&'))?.length > 1) {
      this.abobeParamObj.adobeId = (((this.cookie.get('authCode')?.split('&'))[1])?.split('='))[1];
      this.abobeParamObj.marketingId = (((this.cookie.get('authCode')?.split('&'))[2])?.split('='))[1];
      this.authCodeObj.authCode = (this.cookie.get('authCode')?.split('&'))[0];
      this.initializeAdobeParameters(this.authCodeObj.authCode, this.abobeParamObj.adobeId, this.abobeParamObj.marketingId);
      this.mliAnalyticsService?.trackEvent({
        dbs_adobe_id: this.abobeParamObj.adobeId,
        dbs_marketing_id: this.abobeParamObj.marketingId,
        dbs_authcode: this.authCodeObj.authCode,
        event_category: 'Loading Page'
      });
      console.log('ngOnInit: authCodeObj ==> ', this.authCodeObj);
      console.log('ngOnInit: adobeParamObj ==>', this.abobeParamObj);
      this.callCustomerProfile(this.authCodeObj);
    } else {
      this.authCodeObj.authCode = this.cookie.get('authCode');
      console.log('ngOnInit: authCodeObj with no Adobe Parameters ==> ', this.authCodeObj.authCode);
      this.initializeAdobeParameters(this.authCodeObj.authCode, this.abobeParamObj.adobeId, this.abobeParamObj.marketingId);
      this.mliAnalyticsService?.trackEvent({
        dbs_adobe_id: this.abobeParamObj.adobeId,
        dbs_marketing_id: this.abobeParamObj.marketingId,
        dbs_authcode: this.authCodeObj.authCode,
        event_category: 'Loading Page'
      });
      this.callCustomerProfile(this.authCodeObj);
    }
  }

  callCustomerProfile(authCodeObj?: any): void {
    console.log('callCustomerProfile: authCode ==> ', authCodeObj.authCode);
    this._service.customerProfile(authCodeObj).subscribe((response: any) => {
      if (response !== null) {
        console.log('callCustomerProfile: Customer Profile Response ==> ', response);
        this.objectId = response.id;
        console.log('callCustomerProfile: Customer Details ==> ', this.objectId);
        this.getData(this.objectId);
      } else {
        console.log('callCustomerProfile: Customer Profile Null Response ==> ', response);
        sessionStorage.setItem('Error Status', JSON.stringify({
          location: 'page 0: callCustomerProfile', details: {
            subdetails: 'response of customer/profile has no value',
            maindetails: '=> ' + response
          }
        }));
        this._router.navigate(['/error-occured']);
      }
    }, (error: any) => {
      console.log('callCustomerProfile: Customer Profile Error ==> ', error);
      this._error.handleError(error, {
        details: ['', null, undefined].includes(this.initializeAdobeParameters(this.authCodeObj.authCode, this.abobeParamObj.adobeId, this.abobeParamObj.marketingId)) === false ?
          this.initializeAdobeParameters(this.authCodeObj.authCode, this.abobeParamObj.adobeId, this.abobeParamObj.marketingId) : '/v1/IDN/customer/profile Error',
        secondary_details: ['', null, undefined].includes(this.initializeAdobeParameters(this.authCodeObj.authCode, this.abobeParamObj.adobeId, this.abobeParamObj.marketingId)) === false ?
          'Cookies Passed: ' + this.cookie.get('authCode') : 'Error Message: ' + error.message
      });
      sessionStorage.setItem('Error Status', JSON.stringify({
        location: 'page 0: callCustomerProfile', details: {
          subdetails: 'error on customer/profile',
          maindetails: '=> ' + error
        }
      }));
      this._router.navigate(['/error-occured']);
    });
  }

  getData(savedId: any): void {
    this.customerInfoDetailsReq = {
      fields: ['cif', 'fullName', 'dob', 'gender', 'nationality', 'addressStreet1', 'addressStreet2', 'addressCity', 'addressCountry', 'addressProvince', 'addressType', 'addressState', 'phone', 'hashCode']
    };
    try {
      this._service.findById(savedId, this.customerInfoDetailsReq)
        .pipe(takeUntil(this.destroySub))
        .subscribe(response => {
          this.customerDetails = response;
          this.initializedProductEligibilityData(this.customerDetails);
        }, (error: any) => {
          this._error.handleError(error, {
            details: '/v1/customer-info/customerId Error: ' + error.message,
            secondary_details: 'API Payload: ' + savedId
          });
        });
    } catch (e) {
      this._router.navigate(['/error-occured']);
    }
  }

  initializedProductEligibilityData(data: any): void {
    console.log('initializedProductEligibilityData: initializedProductEligibilityData is Called');
    this.productData.cif = data.cif;
    this.productData.dob = data.dob + ' 00:00:00';
    this.productData.fullName = data.fullName;
    this.productData.gender = data.gender;

    this.productEligibilityData.customerInfo = this.productData;
    console.log('initializedProductEligibilityData: productData Value ==> ', this.productData);
    console.log('initializedProductEligibilityData: productEligibilityData.customerInfo Value ==> ', this.productEligibilityData.customerInfo);
    this.productEligibility(this.productEligibilityData);
  }

  initilizedCustomerEligibilityData(customerDetails: any, authCode: any): void {
    console.log('initilizedCustomerEligibilityData: initilizedCustomerEligibilityData is Called');
    this.customerEligibilityData.customerId = customerDetails.cif;
    this.customerEligibilityData.dob = customerDetails.dob + ' 00:00:00';
    this.customerEligibilityData.fullName = customerDetails.fullName;
    this.customerEligibilityData.gender = customerDetails.gender;
    console.log('initilizedCustomerEligibilityData: customerEligibilityData Value ==> ', this.customerEligibilityData);

    this.customerEligibility(this.customerEligibilityData);
  }

  initializedCustomerInformation(): void {
    console.log('initializedCustomerInformation: initializedCustomerInformation is Called');
    try {
      this.customerData.customerId = this.authCodeObj.authCode;
      this.customerData.fullName = this.customerDetails.fullName;
      this.customerData.aliasName = this.customerDetails.fullName;
      this.customerData.dob = this._sharedService?.formatDate(this.customerDetails.dob);
      this.customerData.gender = this.customerDetails.gender;
      this.customerData.nationality = this.customerDetails.nationality;
      this.customerData.addressStreet1 = this._sharedService?.formatAddress(this.customerDetails.addressStreet1);
      this.customerData.addressStreet2 = this._sharedService?.formatAddress(this.customerDetails.addressStreet2);
      this.customerData.addressCity = this.customerDetails.addressCity;
      this.customerData.addressCountry = this.customerDetails.addressCountry;
      this.customerData.addressProvince = this._sharedService?.formatAddress(this.customerDetails.addressProvince);
      this.customerData.addressType = this.customerDetails.addressType;
      this.customerData.addressState = this.customerDetails.addressState;
      this.customerData.phone = this._sharedService?.formatPhone(this.customerDetails.phone);
      this.customerData.hashCode = this.customerDetails.hashCode;

      this.customerInfo.customerInfo = this.customerData;
      console.log('initializedCustomerInformation: Customer Data ==> ', this.customerData);
      console.log('initializedCustomerInformation: customerInfo.customerInfo Value ==> ', this.customerInfo.customerInfo);
    } catch (e) {
      console.log('initializedCustomerInformation: Error ==> ', e);
    }
  }

  initializedCustomerDetails(): void {
    console.log('initializedCustomerDetails: initializedCustomerDetails is Called');
    this.model.customerID = this.authCodeObj.authCode;
    this.model.isEligible = this.productEligibilityRes.productResult?.result;
    this.model.isExistingCustomer = this.customerEligibilityRes.customerExistence?.exist;
    this.model.applicationSummaryChecked = false;
    this.model.createdDate = this.datepipe.transform(this.createdDate, 'yyyy-MM-ddTHH:mm:ss.SSS') || '';
    this.model.adobeMarketingCloudId = this.abobeParamObj.marketingId;
    this.model.adobeId = this.abobeParamObj.adobeId;
    console.log('initializedCustomerDetails: Customer Model ==> ', this.model);
  }

  initializeAdobeParameters(authCode: string, adobeId: string, marketingId: string): any {
    this.mliTrackingObj.authCode = ['', undefined, null].includes(authCode) === false ? authCode : 'undefined';
    this.mliTrackingObj.adobeId = ['', undefined, null].includes(adobeId) === false ? adobeId : 'undefined';
    this.mliTrackingObj.marketingId = ['', undefined, null].includes(marketingId) === false ? marketingId : 'undefined';

    if (this.mliTrackingObj.authCode === 'undefined' || this.mliTrackingObj.adobeId === 'undefined' || this.mliTrackingObj.marketingId === 'undefined') {
      let authCodeVal = this.mliTrackingObj.authCode === 'undefined' ? 'No Authcode' : 'Authcode: ' + this.mliTrackingObj.authCode;
      let adobeIdVal = this.mliTrackingObj.adobeId === 'undefined' ? 'No Adobe Id' : 'Adobe Id: ' + this.mliTrackingObj.adobeId;
      let marketingIdVal = this.mliTrackingObj.marketingId === 'undefined' ? 'No Marketing Id' : 'Marketing Id: ' + this.mliTrackingObj.marketingId;

      return authCodeVal + ', ' + adobeIdVal + ', ' + marketingIdVal;
    }
  }

  getHashcode(customerInfo: any): void {
    console.log('getHashcode: getHashcode is Called');
    console.log('getHashcode: Customer Info ==> ', customerInfo);
    console.log('getHashcode: productEligibilityRes.productResult?.premiumAmountSummary Value ==> ', this.productEligibilityRes.productResult?.premiumAmountSummary);
    this._sharedService?.initializeEligibilityData(this.eligibilityData, this.customerDetails, this.eligibilityPayload);
    try {
      console.log('getHashcode: Update Customer Info ==> ', this.eligibilityPayload);
      this._service.saveHashcode(this.eligibilityPayload).subscribe((value: any) => {
        console.log('getHashcode: saveHashcode Response ==> ', value);
        this.customerData.hashCode = value.hashcodeResult.hashcode;
        this.customerInfo.customerInfo = this.customerData;
        this.model.hashCode = value.hashcodeResult.hashcode;
        console.log('getHashcode: Customer Data Hashcode ==> ', this.customerData.hashCode);
        console.log('getHashcode: Customer Data ==> ', this.customerData);
        console.log('getHashcode: customerInfo.customerInfo Value ==> ', this.customerInfo.customerInfo);
        this.checkCustomerAge(this.customerDetails);
      }, (error: any) => {
        console.log('getHashcode: saveHashcode Error Response ==> ', error);
        this._error.handleError(error, {
          details: '/v1/IDN/eligibility/hashcode Error: ' + error.message,
          secondary_details: 'API Payload: ' + JSON.stringify(customerInfo)
        });
        if (+(this.productEligibilityRes.productResult?.premiumAmountSummary) > 1000000000) {
          sessionStorage.setItem('Error Status', JSON.stringify({
            location: 'page 0: getHashcode-eligibility/hashcode error', details: {
              subdetails: 'error on eligibility/hashcode and reached maximum premiumAmountSummary => ' + +(this.productEligibilityRes.productResult?.premiumAmountSummary),
              maindetails: '=> ' + error
            }
          }));
          this._router.navigate(['/reached-limit']);
        } else {
          console.log('getHashcode: error.status Value ==> ', error.status);
          if (error.status === 400) {
            sessionStorage.setItem('Error Status', JSON.stringify({
              location: 'page 0: getHashcode-eligibility/hashcode error', details: {
                subdetails: 'error on eligibility/hashcode and error.status of 400 => ' + error.status,
                maindetails: '=> ' + error
              }
            }));
            this._router.navigate(['/data-error']);
          } else {
            sessionStorage.setItem('Error Status', JSON.stringify({
              location: 'page 0: getHashcode-eligibility/hashcode error', details: {
                subdetails: 'error on eligibility/hashcode',
                maindetails: '=> ' + error
              }
            }));
            this._router.navigate(['/error-occured', error.status]);
          }
        }
      });
    } catch (e) {
      console.log('getHashcode: Try Catch Error ==> ', e);
      sessionStorage.setItem('Error Status', JSON.stringify({
        location: 'page 0: getHashcode', details: {
          subdetails: 'error on try catch of eligibility/hashcode',
          maindetails: '=> ' + e
        }
      }));
      this._router.navigate(['/error-occured']);
    }
  }

  checkCustomerAge(customerDetails: any): void {
    console.log('checkCustomerAge: checkCustomerAge is Called');
    console.log('checkCustomerAge: customerDetails Value ==> ', customerDetails);
    console.log('checkCustomerAge: customerDetails.dateOfBirth Value ==> ', customerDetails.dob);
    console.log('checkCustomerAge: productEligibilityRes.productResult?.premiumAmountSummary Value ==> ', this.productEligibilityRes.productResult?.premiumAmountSummary);
    const timeDiff = Math.abs(Date.now() - new Date(customerDetails.dob).getTime());
    console.log('checkCustomerAge: timeDiff Value ==> ', timeDiff);
    this.age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
    console.log('checkCustomerAge: age Value ==> ', this.age);
    if (this.age >= 18 && this.age <= 50) {
      this.model.age = this.age;
      this.checkEligible(customerDetails);
    } else {
      if (+(this.productEligibilityRes.productResult?.premiumAmountSummary) > 1000000000) {
        sessionStorage.setItem('Error Status', JSON.stringify({
          location: 'page 0: checkCustomerAge', details: {
            subdetails: 'invalid age of 18-50 and reached maximum premiumAmountSummary => ' + +(this.productEligibilityRes.productResult?.premiumAmountSummary),
            maindetails: '=> ' + this.age
          }
        }));
        this._router.navigate(['/reached-limit']);
      } else {
        sessionStorage.setItem('Error Status', JSON.stringify({
          location: 'page 0: checkCustomerAge', details: {
            subdetails: 'invalid age of 18-50',
            maindetails: '=> ' + this.age
          }
        }));
        this._router.navigate(['/not-elligible']);
      }
    }
  }

  checkEligible(customerDetails: any): void {
    console.log('checkEligible: checkEligible is Called');
    console.log('checkEligible: customerDetails Value ==> ', customerDetails);
    console.log('checkEligible: productEligibilityRes Value ==> ', this.productEligibilityRes);
    if (this.productEligibilityRes.productResult?.result === 'Y') {
      this.checkPremiumAmountSummary(customerDetails);
    } else {
      sessionStorage.setItem('Error Status', JSON.stringify({
        location: 'page 0: checkEligible', details: {
          subdetails: 'productEligibilityRes.productResult?.result is N',
          maindetails: '=> ' + this.productEligibilityRes.productResult?.result
        }
      }));
      this._router.navigate(['/reached-limit']);
    }
  }

  checkPremiumAmountSummary(customerDetails: any): void {
    console.log('checkPremiumAmountSummary: checkPremiumAmountSummary is Called');
    console.log('checkPremiumAmountSummary: customerDetails Value ==> ', customerDetails);
    console.log('checkPremiumAmountSummary: customerDetails.premiumAmountSummary Value ==> ', +(this.productEligibilityRes.productResult?.premiumAmountSummary));
    console.log('checkPremiumAmountSummary: model Value ==> ', this.model);
    console.log('checkPremiumAmountSummary: age Value ==> ', this.age);
    if (+(this.productEligibilityRes.productResult?.premiumAmountSummary) < 1000000000) {
      console.log("checkPremiumAmountSummary: customerDetails.premiumAmountSummary Value ==> ", +(this.productEligibilityRes.productResult?.premiumAmountSummary));
      this.save(this.model);
    } else {
      this._router.navigate(['/reached-limit']);
    }
  }

  save(data: any): void {
    console.log('save: save is Called');
    console.log('save: data Value ==> ', data);
    this.model.id = this.objectId;
    try {
      this._service.updateCustomerInformation(this.objectId, data).subscribe(() => {
        sessionStorage.setItem('CustomerDetails', JSON.stringify({
          savedId: this.objectId
        }));
        console.log('save: customerDetails.age Value ==> ', this.age);
        console.log('save: premiumAmountSummary Value ==> ', +(this.productEligibilityRes.productResult?.premiumAmountSummary));
        console.log('save: isEligible Value ==> ', this.productEligibilityRes.productResult?.result);
        console.log('save: savedId Value ==> ', this.objectId);
        this._router.navigate(['/mi-flip/build-your-plan']);
      }, (error) => {
        console.log('save: updateCustomerInfo Error Response ==> ', error);
        this._error.handleError(error, {
          details: 'PATCH /v1/IDN/customer-info/ Error: ' + error.message,
          secondary_details: 'API Payload: ' + JSON.stringify(data)
        });
        sessionStorage.setItem('current-error', error.statusText);
        console.log('save: 2nd updateCustomerInfo Error Response ==> ', error.statusText);
        sessionStorage.setItem('Error Status', JSON.stringify({
          location: 'page 0: save', details: {
            subdetails: 'error on PATCH /v1/IDN/customer-info/',
            maindetails: '=> ' + error
          }
        }));
        if (+(this.productEligibilityRes.productResult?.premiumAmountSummary) > 1000000000) {
          this._router.navigate(['/reached-limit']);
        } else {
          if (error.status === 400) {
            this._router.navigate(['/data-error']);
          } else {
            this._router.navigate(['/error-occured', error.status]);
          }
        }
      });
    } catch (e) {
      console.log('save: Try Catch Error Response ==> ', e);
      sessionStorage.setItem('Error Status', JSON.stringify({
        location: 'page 0: save', details: {
          subdetails: 'error on try catch for PATCH /v1/IDN/customer-info/',
          maindetails: '=> ' + e
        }
      }));
      this._router.navigate(['/error-occured']);
    }
  }

  customerEligibility(customer: any): any {
    console.log('customerEligibility: customerEligibility is Called');
    console.log("customerEligibility: Customer Value ==> ", customer);
    console.log("customerEligibility: customerInfo Value ==> ", this.customerInfo);
    this._service.customerEligibility(customer).subscribe((data: any) => {
      console.log("customerEligibility: customerEligibility Response ==> ", data);
      this.customerEligibilityRes = data;
      this.initializedCustomerInformation();
      this.initializedCustomerDetails();
      this.getHashcode(this.customerInfo);
    }, (error: any) => {
      console.log("customerEligibility: customerEligibility Error Response ==> ", error);
      this._error.handleError(error, {
        details: '/v1/IDN/customer Error: ' + error.message,
        secondary_details: 'API Payload: ' + JSON.stringify(customer)
      });
      sessionStorage.setItem('Error Status', JSON.stringify({
        location: 'page 0: customerEligibility', details: {
          subdetails: 'error on eligibility/customer',
          maindetails: '=> ' + error
        }
      }));
      if (+(this.productEligibilityRes.productResult?.premiumAmountSummary) > 1000000000) {
        this._router.navigate(['/reached-limit']);
      } else {
        if (error.status === 400) {
          this._router.navigate(['/data-error']);
        } else {
          this._router.navigate(['/error-occured', error.status]);
        }
      }
    });
  }

  productEligibility(product: any): any {
    console.log("productEligibility: productEligibility is Called");
    console.log("productEligibility: Product Value ==> ", product);
    this._service.productEligibility(product).subscribe((data: any) => {
      console.log("productEligibility: productEligibility Response ==> ", data);
      this.productEligibilityRes = data;
      this.model.premiumAmountSummary = +(this.productEligibilityRes.productResult?.premiumAmountSummary);
      this.initilizedCustomerEligibilityData(this.customerDetails, this.authCodeObj.authCode);
    }, (error: any) => {
      console.log("productEligibility: productEligibility Error Response ==> ", error);
      this._error.handleError(error, {
        details: '/v1/IDN/eligibility/product Error: ' + error.message,
        secondary_details: 'API Payload: ' + JSON.stringify(product)
      });
      sessionStorage.setItem('Error Status', JSON.stringify({
        location: 'page 0: productEligibility', details: {
          subdetails: 'error on eligibility/product',
          maindetails: '=> ' + error
        }
      }));
      if (error.status === 400) {
        this._router.navigate(['/data-error']);
      } else {
        this._router.navigate(['/error-occured', error.status]);
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@cds/ng-web-components/popup';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { ChangeEmailAddressComponent } from '../change-email-address/change-email-address.component';
import { GeneralService } from 'src/app/_services/general.service';
import { DokuPay } from 'src/app/_model/payment-model';
import { SharedService } from 'src/app/_shared/shared.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CustomerDetails } from 'src/app/_model/customerdetails-model';
import { ErrorServicesService } from 'src/app/_services/error-services.service';
import { CustomerInfoDetails } from 'src/app/_model/customer-info-details.model';

@Component({
  selector: 'app-make-payment',
  templateUrl: './make-payment.component.html',
  styleUrls: ['./make-payment.component.scss']
})
export class MakePaymentComponent implements OnInit, OnDestroy {
  param: any = {
    gateway: "dokupay",
    orderRef: "",
    amount: {
      currency: "IDR",
      value: 0
    },
    additionalInfo: {
      paymentDueDate: "20210625",
      name: "-",
      email: '',
      paymentChannel: "17",
      billNumber: '',
      customerId: ''
    },
    type: "sales"
  };

  iconConfig: CdsIconConfig = {
    size: 'sm',
    color: 'default',
  };
  agreeConfig: CdsButtonConfig = {
    label: 'Lakukan Pembayaran',
    buttonType: 'button',
    style: 'primary'
  };
  backConfig: CdsButtonConfig = {
    label: 'Kembali',
    buttonType: 'button',
    style: 'secondary'
  };
  paymentData !: DokuPay;
  destroySub = new Subject();
  customer: any;
  productComputation: any;
  startDate = '';
  endDate = '';
  appearSpinner = false;
  model: CustomerDetails = {};
  policy: any = {};
  customerDetails: any = {};
  customerInfoDetailsReq: CustomerInfoDetails = new CustomerInfoDetails();

  constructor(
    public dialogRef: MatDialogRef<ChangeEmailAddressComponent>,
    private _service: GeneralService,
    private _shared: SharedService,
    private _router: Router,
    private service: GeneralService,
    private _error: ErrorServicesService
  ) { }

  ngOnInit(): void {
    this.generatePolicyNumber();
  }

  initializeData(): void {
    this.param.additionalInfo.billNumber = sessionStorage.getItem('policyId' || '');
    this.param.additionalInfo.customerId = sessionStorage.getItem('policyId' || '');
    this.customer = JSON.parse(sessionStorage.getItem('CustomerDetails') || '{}');
    this.productComputation = JSON.parse(sessionStorage.getItem('productComputation') || '{}');
    this.startDate = this.productComputation.startDate;
    this.endDate = this.productComputation.endDate;
    this.param.orderRef = sessionStorage.getItem('policyId' || '') + '_tr_cr_' + this.makeid(3);

    this.customerInfoDetailsReq = {
      fields: ['fullName', 'premiumAmount', 'customerEmail', 'addressCountry', 'addressCity', 'postalCode', 'phone', 'addressState', 'addressStreet1', 'addressStreet2']
    };
    try {
      this._service.findById(this.customer?.savedId, this.customerInfoDetailsReq)
        .pipe(takeUntil(this.destroySub))
        .subscribe(response => {
          this.model.policyId = this.policy.policyNumber;
          this.model.orderRef = this.param.orderRef;
          this.model.currencyCode = 'IDR';
          this.model.paymentMode = 'Monthly';
          this.param.additionalInfo.name = response.fullName;
          this.param.amount.value = response.premiumAmount;
          this.param.additionalInfo.email = response.customerEmail;
          this.customerDetails = {
            addressCountry: response.addressCountry ? response.addressCountry : '-',
            addressCity: response.addressCity ? response.addressCity : '-',
            postalCode: response.postalCode ? response.postalCode : '-',
            phone: response.phone ? response.phone : '-',
            addressState: response.addressState ? response.addressState : '-',
            addressStreet1: response.addressStreet1 ? response.addressStreet1 : '-',
            addressStreet2: response.addressStreet2 ? response.addressStreet2 : '-',
            firstValue: response.premiumAmount ? response.premiumAmount : '-'
          };
        }, (error: any) => {
          this._error.handleError(error, {
            details: '/v1/IDN/customer-info/customerId Error: ' + error.message,
            secondary_details: 'API Payload: ' + this.customer?.savedId
          });
        });
    } catch (e) {
      sessionStorage.setItem('Error Status', JSON.stringify({
        location: 'page 4 popup: initializeData', details: {
          subdetails: 'generalService.findById try catch error',
          maindetails: '=> ' + e
        }
      }));
      this._router.navigate(['/error-occured']);
    }
  }

  generatePolicyNumber(): void {
    if (sessionStorage.getItem('policyId') !== null) {
      this.policy.policyNumber = sessionStorage.getItem('policyId');
      this.initializeData();
    } else {
      this._service.getPolicyNumber().subscribe(data => {
        this.policy = data;
        sessionStorage.setItem('policyId', data.policyNumber);
        this.initializeData();
      }, (error) => {
        this._error.handleError(error, {
          details: '/v1/IDN/policy-number/new Error: ' + error.message,
          secondary_details: 'No Payload GET Method: ' + error.statusText
        });
        if (error) {
          sessionStorage.setItem('Error Status', JSON.stringify({
            location: 'page 4 popup: generatePolicyNumber', details: {
              subdetails: 'generalService.getPolicyNumber error response',
              maindetails: '=> ' + error
            }
          }));
          this._router.navigate(['error-occured']);
        }
      });
    }
  }

  agree(): void {
    this.model.id = this.customer?.savedId;
    this.service.updateCustomerInformation(this.customer?.savedId, this.model).subscribe(() => {
      this.generatePaymentSession();
      console.log('save: Successfully Saved in MongoDB');
    }, (error: any) => {
      console.log('save: Unsuccessfully Saved in MongoDB ==> ', error);
      this._error.handleError(error, {
        details: 'PATCH /v1/IDN/customer-info/customerID Error: ' + error.message,
        secondary_details: 'API Payload: ' + JSON.stringify(this.model)
      });
      sessionStorage.setItem('Error Status', JSON.stringify({
        location: 'page 4 popup: updateCustomerInformation', details: {
          subdetails: 'generalService.updateCustomerInformation error response',
          maindetails: '=> ' + error
        }
      }));
      this._router.navigate(['/error-occured']);
    });
  }

  generatePaymentSession(): void {
    this.appearSpinner = true;
    console.log('agree() triggered make payment');
    console.log('agree: this.param values ==> ', this.param);
    try {
      this._service.dokuPayment(this.param)
        .pipe(takeUntil(this.destroySub))
        .subscribe(data => {
          console.log('agree: dokuPayment Response ==> ', data);
          this.paymentData = data.paymentResponse;
          // for QA & Dev testing --START--
          this.paymentData.paymentGatewayLink.metaData.NAME = this.truncateName(this.param.additionalInfo.name);
          this.paymentData.paymentGatewayLink.metaData.COUNTRY = this.customerDetails.addressCountry;
          this.paymentData.paymentGatewayLink.metaData.CITY = this.customerDetails.addressCity;
          this.paymentData.paymentGatewayLink.metaData.ZIPCODE = this.customerDetails.postalCode;
          this.paymentData.paymentGatewayLink.metaData.MOBILEPHONE = this._shared.formatPhone(this.customerDetails.phone);
          this.paymentData.paymentGatewayLink.metaData.BILLTYPE = 'P';
          this.paymentData.paymentGatewayLink.metaData.BILLDETAIL = 'MiFlip Product';
          this.paymentData.paymentGatewayLink.metaData.EXECUTETYPE = 'DATE';
          this.paymentData.paymentGatewayLink.metaData.EXECUTEDATE = '5,15,25';
          this.paymentData.paymentGatewayLink.metaData.EXECUTEMONTH = 'JAN, FEB, MAR, APR, MAY, JUN, JUL, AUG, SEP, OCT, NOV, DEC';
          this.paymentData.paymentGatewayLink.metaData.FLATSTATUS = 'FALSE';
          this.paymentData.paymentGatewayLink.metaData.REGISTERAMOUNT = (this.customerDetails.firstValue).toFixed(2);
          this.paymentData.paymentGatewayLink.metaData.HOMEPHONE = this._shared.formatPhone(this.customerDetails.phone);
          this.paymentData.paymentGatewayLink.metaData.WORKPHONE = this._shared.formatPhone(this.customerDetails.phone);
          this.paymentData.paymentGatewayLink.metaData.STATE = this.customerDetails.addressState;
          if (this.customerDetails.addressStreet1 !== '') {
            this.paymentData.paymentGatewayLink.metaData.ADDRESS = this._shared.formatAddress(this.customerDetails.addressStreet1);
          } else {
            this.paymentData.paymentGatewayLink.metaData.ADDRESS = this._shared.formatAddress(this.customerDetails.addressStreet2);
          }
          this.paymentData.paymentGatewayLink.metaData.STARTDATE = this.startDate;
          this.paymentData.paymentGatewayLink.metaData.ENDDATE = this.endDate;
          // for development testing --END--
          console.log('Payment Data ==> ', this.paymentData);
          sessionStorage.setItem('Doku Session', this.paymentData.orderRef);
        }, (error: any) => {
          this._error.handleError(error, {
            details: '/v1/IDN/payment Error: ' + error.message,
            secondary_details: 'API Payload: ' + JSON.stringify(this.param)
          });
        });
    } catch (e) {
      sessionStorage.setItem('Error Status', JSON.stringify({
        location: 'page 4 popup: generatePaymentSession', details: {
          subdetails: 'generalService.dokuPayment error try catch error',
          maindetails: '=> ' + e
        }
      }));
      this._router.navigate(['/error-occured']);
    }
  }

  back(): void {
    this.dialogRef.close({ agree: false });
  }

  makeid(length: number): string {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  truncateName(name: string): string {
    let charlimit = 50;
    if (!name || name.length <= charlimit) {
      return name;
    }

    let withoutHtml = name.replace(/<(?:.|\n)*?>/gm, '');
    return withoutHtml.substring(0, charlimit);
  }

  ngOnDestroy(): void {
    this.destroySub.next();
    this.destroySub.complete();
  }
}

export class ProductEngine {
    id?: string;
    policyYearDate?: string;
    plannedPremium?: number;
    location?: string;
    planCode?: string;
    entryAge?: number;
    paymentMode?: string;
    faceAmount?: number;
    currencyId?: string;
    channel?: string;
    inputMode?: string;
}

<div class="change-email-address" [class.newEmailClicked]="onEmailFocused">
    <cds-icon id="exit-icon" class="close-icon" icon="action:button_x" width="2.5rem" height="2.5rem" (click)="isBacked()"></cds-icon>
    <p id="change-email-title" class="title">Ubah alamat email Anda</p>
    <div class="details" [formGroup]="formGroup">
        <cds-inline-alert id="new-alert" class="details-alert new-customer-alert" *ngIf="customerDetails.isExistingCustomer !== 'Y'" type="info">
            Perubahan alamat email Anda di halaman ini tidak akan mengubah data bank Anda
        </cds-inline-alert>
        <cds-inline-alert id="existing-alert" class="details-alert existing-customer-alert" *ngIf="customerDetails.isExistingCustomer === 'Y'" type="warning">
            <div class="alert-details">
                <strong>Kami melihat bahwa Anda telah memiliki produk asuransi Manulife Indonesia.</strong> 
                Perubahan email ini akan mengubah data kontak Anda pada sistem Manulife
            </div>
        </cds-inline-alert>
        <cds-textfield 
            id="new-email-textfield" 
            class="new-email-address" 
            (click)="emailFieldClicked()" 
            [class.new-email-active]="onEmailFocused === true" 
            [class.old-email-address] = "onEmailFocused === false && isSameEmail === true" 
            (focusout)="onFocusedOut()" 
            attr.newemail="{{newEmVal}}" 
            (keyup)="onEnterEmail($event)" 
            [config]="newEmailAddress" 
            formControlName="newEmail" 
            [class.invalid]="!isValidEmail || isSameEmail">
        </cds-textfield>
        <cds-assistive-text id="email-format-error" class="details-assistive format-error" *ngIf="(!isValidEmail && (!noValueEmail && !isSameEmail)) || (noValueEmail && (!isValidEmail && !isSameEmail))">Format Alamat Email tidak sesuai. Mohon perbaiki format alamat email Anda.</cds-assistive-text>
        <cds-assistive-text id="email-compare-error" class="details-assistive compare-error" *ngIf="isSameEmail && (!noValueEmail && isValidEmail)">Alamat Email baru tidak boleh sama dengan Alamat email lama.</cds-assistive-text>
        <cds-button 
            id="change-email-button" 
            class="change-email" 
            [config]="changeEmailButton" 
            [disabled]="!isValidEmail || noValueEmail || isSameEmail ? true : false" 
            (click)="!isValidEmail || noValueEmail || isSameEmail ? '' : isGantiEmailClicked()">
        </cds-button>
    </div>
</div>
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CdsOption } from '@cds/ng-core/configuration';
import { CdsLinkConfig } from '@cds/ng-core/link';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { takeUntil } from 'rxjs/operators';
import { SharedService } from '../_shared/shared.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { GeneralService } from '../_services/general.service';
import { CustomerDetails } from '../_model/customerdetails-model';
import { PolicyComponent } from '../_modal/policy/policy.component';
import { ProductEngine } from '../_model/product-engine.model';
import { DatePipe } from '@angular/common';
import { MliAnalyticsService } from '@mli/analytics';
import { ErrorServicesService } from '../_services/error-services.service';
import { CustomerInfoDetails } from '../_model/customer-info-details.model';

@Component({
  selector: 'app-build-your-plan',
  templateUrl: './build-your-plan.component.html',
  styleUrls: ['./build-your-plan.component.scss']
})
export class BuildYourPlanComponent implements OnInit, OnDestroy {
  @Output() selectedIndex = new EventEmitter();
  age = 0;
  premiumAmountSummary = 0;
  savedId: string = JSON.parse(sessionStorage.getItem('CustomerDetails') || '{}').savedId;
  startDate: Date = new Date();
  endDate: any;
  productEng: ProductEngine = new ProductEngine();
  isFirstClicked = false;
  isSecondClicked = false;
  monthlyPremium = 50000;
  totalAmount = 1000000000;
  firstControlValue = '';
  secondControlValue = '';
  maxMonthlyPremium = 0;
  maxSumInsured = 0;
  minSumInsured = 0;
  minMonthlyPremium = 0;
  universalMaxSum = 1000000000;
  remainingMaxSum = 0;
  form: FormGroup;
  productComputation: any = {};
  elligibilityResponse: any = {};
  hasFirstValueActive = false;
  hasSecondValueActive = false;
  hasThirdValueActive = false;
  hasReachedMaximumMP = false;
  hasReachedMaximumSI = false;
  destroySub = new Subject();
  steps: any = [];
  // ForTesting
  hasPopUpClicked: any = false;
  hasLanjutClicked: any = false;
  customerInfoDetailsReq: CustomerInfoDetails = new CustomerInfoDetails();
  termsAndConditions: CdsLinkConfig = {
    label: 'Ringkasan Informasi Produk & Layanan Personal'
  };

  model: CustomerDetails = {};
  dbsParams: any = {};

  constructor(
    private fb: FormBuilder,
    private cdsPopup: CdsPopupService,
    public _sharedService: SharedService,
    private _router: Router,
    private _service: GeneralService,
    public _datePipe: DatePipe,
    private mliAnalyticsService: MliAnalyticsService,
    public _error: ErrorServicesService
  ) {
    this.form = this.fb.group({
      firstValue: new FormControl(undefined, Validators.maxLength(50)),
      secondValue: new FormControl(undefined, Validators.maxLength(50)),
      thirdValue: new FormControl(undefined)
    });
  }

  yearOptions: CdsOption[] = this.getDefaultPolicy();

  ngOnInit(): void {
    sessionStorage.setItem('current-step', JSON.stringify(0));
    this.steps = JSON.parse(sessionStorage.getItem('completed-steps') || '[]');
    this.productComputation = JSON.parse(sessionStorage.getItem('productComputation') || '{}');

    this.customerInfoDetailsReq = {
      fields: ['age', 'premiumAmountSummary', 'customerID', 'adobeMarketingCloudId', 'adobeId', 'premiumAmount', 'sumInsured', 'policyTerm']
    };
    try {
      this._service.findById(this.savedId, this.customerInfoDetailsReq)
        .pipe(takeUntil(this.destroySub))
        .subscribe(response => {
          this.age = response.age ? response.age : 0;
          this.premiumAmountSummary = response.premiumAmountSummary ? response.premiumAmountSummary : 0;
          this.dbsParams = {
            adobeId: response.adobeId,
            marketingId: response.adobeMarketingCloudId,
            authCode: response.customerID
          };

          this.initializeProductEng(this.productEng);
          this.setMinAmount();
          this.totalAmount = this.calculateNewTotal(50000);
          this.getMaxAmount();

          this.form.setValue({
            firstValue: response.premiumAmount ? this.formatValue(response.premiumAmount) : this.formatValue(+(this.monthlyPremium).toFixed(0)),
            secondValue: response.sumInsured ? this.formatValue(response.sumInsured) : this.formatValue(+(this.minSumInsured).toFixed(0)),
            thirdValue: response.policyTerm ? +((response.policyTerm)?.split('F')[1]) : this.yearOptions[0].value
          });
        }, (error: any) => {
          this._error.handleError(error, {
            details: '/v1/customer-info/customerId Error: ' + error.message,
            secondary_details: 'API Payload: ' + this.savedId
          });
        });
    } catch (e) {
      this._router.navigate(['/error-occured']);
    }

    if (Object.values(this.productComputation)?.length !== 0) {
      this.hasFirstValueActive = true;
      this.hasSecondValueActive = true;
      this.hasThirdValueActive = true;
    }

    if (sessionStorage.getItem('productComputation')) {
      this.hasLanjutClicked = true;
    } else {
      this.hasLanjutClicked = false;
    }
  }

  initializeProductEng(productEng: ProductEngine): void {
    productEng.id = this.savedId;
    productEng.policyYearDate = '' + this._datePipe.transform(new Date(), 'YYYYMMdd');
    productEng.plannedPremium = 0;
    productEng.location = 'ID';
    productEng.planCode = '';
    productEng.entryAge = this.age;
    productEng.paymentMode = 'Monthly';
    productEng.faceAmount = 0;
    productEng.currencyId = 'IDR';
    productEng.channel = 'Agency';
    productEng.inputMode = '';
  }

  popUp(): void {
    const popupRef = this.cdsPopup?.open(PolicyComponent, {
      size: 'md',
      data: {
        name: 'John Doe',
      },
    });

    popupRef?.afterClosed()
      .pipe(takeUntil(this.destroySub))
      .subscribe(() => {
        this.mliAnalyticsService?.trackEvent({
          event_label: 'Terms and conditions',
          event_category: 'Build your plan',
          event_type: 'Click button_close Ringkasan Informasi Produk & Layanan',
          dbs_adobe_id: this.dbsParams.adobeId,
          dbs_marketing_id: this.dbsParams.marketingId,
          dbs_authcode: this.dbsParams.authCode
        });
      });
  }

  // ForTesting of popUp()
  isPopUpClicked(): any {
    this.mliAnalyticsService?.trackEvent({
      event_label: 'Terms and conditions',
      event_category: 'Build your plan',
      event_type: 'Click button_Ringkasan Informasi Produk & Layanan',
      dbs_adobe_id: this.dbsParams.adobeId,
      dbs_marketing_id: this.dbsParams.marketingId,
      dbs_authcode: this.dbsParams.authCode,
    });
    this.hasPopUpClicked = true;
  }

  isFirstActive(): void {
    this.isFirstClicked = true;
    this.hasReachedMaximumMP = false;
    this.hasReachedMaximumSI = false;
    this.monthlyPremium = 50000;
  }

  isSecondActive(): void {
    this.isSecondClicked = true;
    this.hasReachedMaximumMP = false;
    this.hasReachedMaximumSI = false;
    this.totalAmount = 1000000000;
  }

  calculatePerMonth(age: number, maxTotal?: number): number {
    let value = 0;
    const total = !maxTotal ? this.totalAmount : maxTotal;

    if (age >= 18 && age <= 30) {
      value = total / 700;
    } else if (age >= 31 && age <= 40) {
      value = total / 500;
    } else if (age >= 41 && age <= 50) {
      value = total / 300;
    }

    if (this.hasReachedMaximumMP) {
      this.hasReachedMaximumSI = true;
    }
    return value;
  }

  calculateNewTotal(amountPerMonth: number): number {
    let total = 9000000;

    if (this.age >= 18 && this.age <= 30) {
      total = amountPerMonth * 700;
    } else if (this.age >= 31 && this.age <= 40) {
      total = amountPerMonth * 500;
    } else if (this.age >= 41 && this.age <= 50) {
      total = amountPerMonth * 300;
    }

    if (this.hasReachedMaximumSI) {
      this.hasReachedMaximumMP = true;
    }
    return total;
  }

  formatValue(value: number): string {
    return 'Rp ' + ('' + value).replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }

  lanjut(): void {
    this.mliAnalyticsService?.trackEvent({
      event_label: 'Proceed',
      event_category: 'Build your plan',
      event_type: 'Click button_Proceed',
      dbs_adobe_id: this.dbsParams.adobeId,
      dbs_marketing_id: this.dbsParams.marketingId,
      dbs_authcode: this.dbsParams.authCode,
    });
    if ((this._sharedService?.reformatValue(this.form.get('firstValue')?.value) > this.maxMonthlyPremium) || (this._sharedService?.reformatValue(this.form.get('secondValue')?.value) > this.maxSumInsured)) {
      this.form.patchValue({
        firstValue: this.formatValue(this.maxMonthlyPremium),
        secondValue: this.formatValue(this.maxSumInsured)
      });
      this.hasReachedMaximumSI = true;
      this.hasReachedMaximumMP = true;
    } else {
      if (this.steps[0].completed < 1) {
        this.steps[0].completed = 1;
        sessionStorage.setItem('completed-steps', JSON.stringify(this.steps));
      }
      this._sharedService?.setData(1);
      this.form.patchValue({
        firstValue: this.formatValue(this._sharedService?.reformatValue(this.form.get('firstValue')?.value)),
        secondValue: this.formatValue(this._sharedService?.reformatValue(this.form.get('secondValue')?.value))
      });

      sessionStorage.setItem('productComputation', JSON.stringify(
        {
          startDate: this._datePipe.transform(this.startDate, 'YYYYMMdd'),
          endDate: this._datePipe.transform(this.endDate, 'YYYYMMdd')
        }
      ));

      this._router.navigate(['mi-flip/health-declaration']);
      // ForTesting
      this.hasLanjutClicked = true;
    }
  }

  isFirstValueActive(): void {
    this.hasFirstValueActive = true;
    this.form.patchValue({
      firstValue: this.formatValue(Math.round(this._sharedService?.reformatValue(
        this.form.get('firstValue')?.value
      ) / 100) * 100)
    });
    this.scrollTo(350);
    this.onFocus('#monthly-premium-value [id=mat-input-0]');
  }

  isSecondValueActive(): void {
    this.hasSecondValueActive = true;
    this.form.patchValue({
      secondValue: this.formatValue(Math.round(this._sharedService?.reformatValue(
        this.form.get('secondValue')?.value
      ) / 100) * 100)
    });
    this.scrollTo(515);
    this.onFocus('#sum-insured-value [id=mat-input-1]');
  }

  isThirdValueActive(): void {
    this.hasThirdValueActive = true;
    this.form.patchValue({
      thirdValue: this.yearOptions[0].value
    });
    this.scrollTo(665);
    this.onFocus('#policy-term-value [id=mat-select-0]');
  }

  getDefaultPolicy(): any {
    const policyTerms = [
      { label: '8 tahun', value: 8 },
      { label: '9 tahun', value: 9 },
      { label: '10 tahun', value: 10 },
      { label: '11 tahun', value: 11 },
      { label: '12 tahun', value: 12 },
      { label: '13 tahun', value: 13 },
      { label: '14 tahun', value: 14 },
      { label: '15 tahun', value: 15 }
    ];
    if (this.age <= 43) {
      return policyTerms;
    } else {
      switch (this.age) {
        case 44:
          policyTerms.splice(7, 7);
          return policyTerms;
        case 45:
          policyTerms.splice(6, 6);
          return policyTerms;
        case 46:
          policyTerms.splice(5, 5);
          return policyTerms;
        case 47:
          policyTerms.splice(4, 4);
          return policyTerms;
        case 48:
          policyTerms.splice(3, 5);
          return policyTerms;
        case 49:
          policyTerms.splice(2, 6);
          return policyTerms;
        default:
          policyTerms.splice(1, 7);
          return policyTerms;
      }
    }
  }

  getMaxAmount(): void {
    if (this.age >= 18 && this.age <= 30) {
      this.maxMonthlyPremium = 1428500;
      this.maxSumInsured = 999950000;
    } else if (this.age >= 31 && this.age <= 40) {
      this.maxMonthlyPremium = 2000000;
      this.maxSumInsured = 1000000000;
    } else {
      this.maxMonthlyPremium = 3333333;
      this.maxSumInsured = 999999900;
    }

    if (this.age >= 41 && this.age <= 50) {
      if ((this.maxSumInsured - this.premiumAmountSummary) <= 600000000) {
        this.maxMonthlyPremium = Math.round((this.calculatePerMonth(
          this.age, (Math.round((this.maxSumInsured - this.premiumAmountSummary) / 100) * 100)
        )) / 100) * 100;
        this.maxSumInsured = this.calculateNewTotal(this.maxMonthlyPremium);
      } else {
        this.maxMonthlyPremium = Math.round((this.calculatePerMonth(
          this.age, (Math.round((600000000) / 100) * 100)
        )) / 100) * 100;
        this.maxSumInsured = this.calculateNewTotal(this.maxMonthlyPremium);
      }
    } else {
      this.maxMonthlyPremium = Math.round((this.calculatePerMonth(
        this.age, (Math.round((this.maxSumInsured - this.premiumAmountSummary) / 100) * 100)
      )) / 100) * 100;
      this.maxSumInsured = this.calculateNewTotal(this.maxMonthlyPremium);
    }
  }

  setMinAmount(): void {
    if (this.age >= 18 && this.age <= 30) {
      this.minMonthlyPremium = 50000;
      this.minSumInsured = 35000000;
    } else if (this.age >= 31 && this.age <= 40) {
      this.minMonthlyPremium = 50000;
      this.minSumInsured = 25000000;
    } else {
      this.minMonthlyPremium = 50000;
      this.minSumInsured = 15000000;
    }
  }

  setThirdValue(policyTerm: number): string {
    switch (policyTerm) {
      case 8:
        return 'GMF08';
      case 9:
        return 'GMF09';
      case 10:
        return 'GMF10';
      case 11:
        return 'GMF11';
      case 12:
        return 'GMF12';
      case 13:
        return 'GMF13';
      case 14:
        return 'GMF14';
      case 15:
        return 'GMF15';
      default:
        return 'GMF08';
    }
  }

  scrollTo(value: number, event?: string): void {
    console.log('scrollTo is Called', value);
    console.log('scrollTo: event Value ==> ', event);
    if (value !== 0) {
      if (value === 515) {
        this.hasSecondValueActive = true;
        this.onFocus('#sum-insured-value [id=mat-input-1]');
      } else if (value === 665) {
        this.hasThirdValueActive = true;
        this.onFocus('#policy-term-value [id=mat-select-0]');
      }

      window.scroll(0, value);
    }
  }

  onFocus(selector: string): void {
    console.log('onFocus: onFocus is Called');
    setTimeout(() => {
      console.log('onFocus: HTML element value ==> ', document.querySelectorAll(selector));
      const htmlSelector = document.querySelectorAll<HTMLElement>(selector);
      htmlSelector[0]?.focus();
    }, 100);
  }

  onFocusout(inputField: string, event: any): void {
    const val = event.target?.value;
    if (inputField === 'firstValue') {
      if (this.hasSecondValueActive !== true) {
        this.scrollTo(515);
      }

      if (this._sharedService?.reformatValue(val) < this.minMonthlyPremium || isNaN(this._sharedService?.reformatValue(val))) {
        this.form.patchValue({
          firstValue: this.formatValue(Math.round(this.minMonthlyPremium / 100) * 100) + ' '
        });
      } else if (this._sharedService?.reformatValue(val) > this.maxMonthlyPremium) {
        this.hasReachedMaximumMP = true;
        this.form.patchValue({
          firstValue: this.formatValue(
            Math.round((this.calculatePerMonth(this.age, Math.round(this.maxSumInsured / 100) * 100)) / 100) * 100
          ) + ' '
        });
      } else {
        this.form.patchValue({
          firstValue: this.formatValue(Math.round(this._sharedService?.reformatValue(val) / 100) * 100) + ' '
        });
      }

      this.productEng.plannedPremium = Math.round(this._sharedService?.reformatValue(
        this.form.get('firstValue')?.value
      ) / 100) * 100;
      this.productEng.faceAmount = 0;
      this.productEng.planCode = this.setThirdValue(this.form.get('thirdValue')?.value);
      this.productEng.inputMode = 'Premium';
      this._service.productEngine(this.productEng.planCode, this.productEng)
        .pipe(takeUntil(this.destroySub))
        .subscribe(calSumInsured => {
          this.model.annualizedPremium = calSumInsured.result.returns.projections[0].annualizedPremium;
          this.endDate = new Date(
            calSumInsured.result.returns.maturityDate?.match(/.{1,4}/g)[0] + '-' +
            (calSumInsured.result.returns.maturityDate?.match(/.{1,4}/g)[1])?.match(/.{1,2}/g)[0] + '-' +
            (calSumInsured.result.returns.maturityDate?.match(/.{1,4}/g)[1])?.match(/.{1,2}/g)[1] + 'T00:00:00'
          );
          this.form.patchValue({
            secondValue: this.formatValue(calSumInsured.result.returns.faceAmount)
          });
        }, (error: any) => {
          this._error.handleError(error, {
            details: 'Calculate Sum Insured /v1/IDN/products/productId/calculate Error: ' + error.message,
            secondary_details: 'Calculate Sum Insured API Payload: ' + this.productEng.planCode + ' & ' + JSON.stringify(this.productEng)
          });
        });
    } else if (inputField === 'secondValue') {
      if (this.hasThirdValueActive !== true) {
        this.scrollTo(665);
      }

      if (this._sharedService?.reformatValue(val) < this.minSumInsured || isNaN(this._sharedService?.reformatValue(val))) {
        this.form.patchValue({
          secondValue: this.formatValue(Math.round(this.minSumInsured / 100) * 100) + ' '
        });
      } else if (this._sharedService?.reformatValue(val) > this.maxSumInsured) {
        this.hasReachedMaximumSI = true;
        this.form.patchValue({
          secondValue: this.formatValue(
            Math.round((this.calculateNewTotal(Math.round(this.maxMonthlyPremium / 100) * 100)) / 100) * 100
          ) + ' '
        });
      } else {
        this.form.patchValue({
          secondValue: this.formatValue(Math.round(this._sharedService?.reformatValue(val) / 100) * 100) + ' '
        });
      }

      this.productEng.plannedPremium = 0;
      this.productEng.faceAmount = Math.round(this._sharedService?.reformatValue(
        this.form.get('secondValue')?.value
      ) / 100) * 100;
      this.productEng.planCode = this.setThirdValue(this.form.get('thirdValue')?.value);
      this.productEng.inputMode = 'Sum-Insured';
      this._service.productEngine(this.productEng.planCode, this.productEng)
        .pipe(takeUntil(this.destroySub))
        .subscribe(calPremAmount => {
          this.model.annualizedPremium = calPremAmount.result.returns.projections[0].annualizedPremium;
          this.endDate = new Date(
            calPremAmount.result.returns.maturityDate?.match(/.{1,4}/g)[0] + '-' +
            (calPremAmount.result.returns.maturityDate?.match(/.{1,4}/g)[1])?.match(/.{1,2}/g)[0] + '-' +
            (calPremAmount.result.returns.maturityDate?.match(/.{1,4}/g)[1])?.match(/.{1,2}/g)[1] + 'T00:00:00'
          );
          this.form.patchValue({
            firstValue: this.formatValue(calPremAmount.result.quotations[0].premiums[0].amount)
          });
        }, (error: any) => {
          this._error.handleError(error, {
            details: 'Calculate Prem Amount /v1/IDN/products/productId/calculate Error: ' + error.message,
            secondary_details: 'Calculate Prem Amount API Payload: ' + this.productEng.planCode + ' & ' + JSON.stringify(this.productEng)
          });
        });
    } else {
      let year = this.startDate.getFullYear();
      let month = this.startDate.getMonth();
      let day = this.startDate.getDate();
      this.endDate = new Date(year + this.form.get('thirdValue')?.value, month, day);

      this.productEng.plannedPremium = Math.round(this._sharedService?.reformatValue(
        this.form.get('firstValue')?.value
      ) / 100) * 100;
      this.productEng.faceAmount = 0;
      this.productEng.planCode = this.setThirdValue(this.form.get('thirdValue')?.value);
      this.productEng.inputMode = 'Premium';
      this._service.productEngine(this.productEng.planCode, this.productEng)
        .pipe(takeUntil(this.destroySub))
        .subscribe(calSumInsured => {
          this.model.annualizedPremium = calSumInsured.result.returns.projections[0].annualizedPremium;
          this.endDate = new Date(
            calSumInsured.result.returns.maturityDate?.match(/.{1,4}/g)[0] + '-' +
            (calSumInsured.result.returns.maturityDate?.match(/.{1,4}/g)[1])?.match(/.{1,2}/g)[0] + '-' +
            (calSumInsured.result.returns.maturityDate?.match(/.{1,4}/g)[1])?.match(/.{1,2}/g)[1] + 'T00:00:00'
          );
          this.form.patchValue({
            secondValue: this.formatValue(calSumInsured.result.returns.faceAmount)
          });
        }, (error: any) => {
          this._error.handleError(error, {
            details: 'Calculate Sum Insured /v1/IDN/products/productId/calculate Error: ' + error.message,
            secondary_details: 'Calculate Sum Insured API Payload: ' + this.productEng.planCode + ' & ' + JSON.stringify(this.productEng)
          });
        });
    }
  }

  ngOnDestroy(): void {
    this.destroySub.next();
    this.destroySub.complete();
  }
}

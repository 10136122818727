<app-header></app-header>
<div class="final-page">
    <cds-icon id="completion-icon" class="smiley-icon" icon="emotion:very_happy" width="3.75rem" height="3.75rem"></cds-icon>
    <p id="completion-title" class="title">Selamat!</p>
    <p id="completion-message" class="thank-you-message">Terima kasih karena telah memilih Manulife untuk melindungi diri Anda dan orang yang Anda sayangi.</p>
    <cds-inline-alert id="completion-inline-alert" class="completion-alert" type="warning"><strong>Kami akan mengirimkan polis elektronik (e-policy) ke alamat email Anda (1 hari kerja).</strong> Informasi polis baru Anda dapat dilihat di aplikasi digibank setelah menerima polis elektronik.</cds-inline-alert>
    <div class="final-page-details">
        <p id="first-details-label" class="details-label">Tanggal Mulai Perlindungan</p>
        <p id="first-details-value" class="details-value">{{ currentDate ? (currentDate | date : "dd MMMM yyyy") : '-' }}</p>
        <p id="second-details-label" class="details-label">Nomor Polis</p>
        <p id="second-details-value" class="details-value">{{ policyDetails.policyNumber ? policyDetails.policyNumber : '-' }}</p>
        <p id="third-details-label" class="details-label">Alamat email pengiriman <em class="italic-text">e-policy</em></p>
        <p id="third-details-value" class="details-value details-email">{{ customerDetails.email ? customerDetails.email : '-' }}</p>
        <p id="fourth-details-label" class="details-label">Nominal premi berikutnya</p>
        <p id="fourth-details-value" class="details-value">{{ productComputation.firstValue ? productComputation.firstValue + ' / bulan' : '-' }}</p>
        <p id="fifth-details-label" class="details-label">Jatuh Tempo premi berikutnya</p>
        <p id="fifth-details-value" class="last-details-value">{{ dueDate ? (dueDate | date : "dd MMMM yyyy") : '-' }}</p>
    </div>
    <div class="final-page-buttons">
        <cds-button 
            id="back-button" 
            class="back" 
            [config]="backDigibank" 
            (click)="backToDigibank()">
        </cds-button>
    </div>
</div>
<app-carousel></app-carousel>
<app-footer></app-footer>

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorServicesService } from '../_services/error-services.service';
import { GeneralService } from '../_services/general.service';

@Component({
  selector: 'app-completion-loader',
  templateUrl: './completion-loader.component.html',
  styleUrls: ['./completion-loader.component.scss']
})
export class CompletionLoaderComponent implements OnInit {

  paymentStatus: any = {};
  model: any = {};
  savedId: string = JSON.parse(sessionStorage.getItem('CustomerDetails') || '{}').savedId;
  paymentSession: any = sessionStorage.getItem('Doku Session' || '');
  constructor(
    private _service: GeneralService,
    public _error: ErrorServicesService,
    private _router: Router
  ) { }

  ngOnInit(): void {
    console.log('ngOnInit: Completion Loader Called');
    this.getPaymentStatus();
  }

  getPaymentStatus(): void {
    console.log('getPaymentStatus: getPaymentStatus Called');
    console.log('getPaymentStatus: orderRef ==> ', this.paymentSession);
    this._service.getPaymentStatusByOrderRef(
      this.paymentSession
    ).subscribe(data => {
      console.log('getPaymentStatus: getPaymentStatusByOrderRef Called');
      console.log('getPaymentStatus: getPaymentStatusByOrderRef Response ==> ', data);
      this.paymentStatus = data.paymentStatusDto;
      console.log('getPaymentStatus: paymentStatus ==> ', this.paymentStatus);
      if (this.paymentStatus.status === 'rejected') {
        console.log('getPaymentStatus: paymentStatus.status is Rejected ==> ', this.paymentStatus.status);
        this.setData('rejected');
      } else if (['', null, undefined].includes(this.paymentStatus.status) === true) {
        console.log('getPaymentStatus: paymentStatus.status has no value ==> ', this.paymentStatus.status);
        this.setData('status no value');
      } else {
        console.log('getPaymentStatus: paymentStatus.status is NOT Rejected ==> ', this.paymentStatus.status);
        this.setData('successful');
      }
    }, (error: any) => {
      console.log('getPaymentStatus: getPaymentStatusByOrderRef Error Response ==> ', error);
      this._error.handleError(error, {
        details: '/v1/IDN/orders/ Error',
        secondary_details: 'API Payload: ' + this.paymentSession
      });
      sessionStorage.setItem('Error Status', JSON.stringify({
        location: 'completion-loader page: getPaymentStatus', details: {
          subdetails: 'error on service.getPaymentStatusByOrderRef',
          maindetails: '=> ' + error
        }
      }));
      this._router.navigate(['/ambiguous']);
    });
  }

  setData(status: string): void {
    console.log("setData: setData is Called");
    console.log("setData: savedId Value ==> ", this.savedId);
    if (status === 'rejected') {
      this._router.navigate(['/failed-completion']);
    } else if (status === 'status no value') {
      this._router.navigate(['/ambiguous']);
    } else {
      this._router.navigate(['/completion-loader']);
    }
  }
}

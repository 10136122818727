<div class="footer">
    <p id="title" class="footer-title">Butuh bantuan?</p>
    <p id="subtitle" class="footer-subtitle">Customer Contact Center Manulife</p>
    <div class="footer-details">
        <p id="first-initial-detail" class="first-detail">Senin - Jum'at</p>
        <p id="second-initial-detail" class="second-detail">Pukul 8:00 - 17:00</p>
        <p id="third-initial-detail" class="third-detail">Tidak beroperasi pada hari libur nasional</p>
    </div>
    <a class="additional-details-link" href="tel:+62 21 25557777"><p id="first-secondary-details" class="additional-details">(021) 2555 7777</p></a>
    <a class="additional-details-link" href="tel:08001606060"><p id="second-secondary-details" class="second-additional-details">0800 - 1 - 606060 (Bebas Pulsa)</p></a>
</div>

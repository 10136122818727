<div class="set-your-protection">
    <p class="title">Atur Perlindunganmu</p>
    <cds-inline-alert id="max-reached-alert" type="error" *ngIf="hasReachedMaximumSI || hasReachedMaximumMP">
       <p><strong>Uang pertanggungan yang Anda masukkan melebihi batas yang ditentukan.</strong>
       Mohon sesuaikan nominal Uang Pertanggungan Anda dengan batas yang tertera.</p> 
    </cds-inline-alert>
    <cds-inline-alert id="conversion-warning-alert" type="warning">
        <p>Nominal Premi dan Uang Pertanggungan akan dibulatkan secara otomatis ke ratusan terdekat.</p> 
     </cds-inline-alert>
    <cds-conversational-field class="build-your-plan-details" [formGroup]="form">
        <cds-conversational-inline id="monthly-premium-conversational" class="first-paragraph" [class.first-error-occured]="(hasReachedMaximumSI || hasReachedMaximumMP) || (!hasFirstValueActive && !hasLanjutClicked)">
            <span id="first-sentence">Saya menginginkan premi asuransi sebesar</span><br>
            <div class="first-input">
                <cds-conversational-text 
                    id="monthly-premium-value" 
                    class="monthly-premium" 
                    formControlName="firstValue" 
                    (keydown)="isFirstActive()"  
                    [class.active]="form.get('firstValue')?.value != null || isFirstClicked != false" 
                    [class.first-value-active]="!hasFirstValueActive && !hasLanjutClicked" 
                    [class.first-active]="hasFirstValueActive" 
                    placeholder="Rp 50.000" 
                    (click)="isFirstValueActive()" 
                    (focusout)="onFocusout('firstValue', $event)" 
                    (keypress)="$event.key === 'Enter' || ($event.key).toLowerCase() === 'next' ? scrollTo(515, $event.key) : ''" 
                    attr.value="{{this.form.get('firstValue')?.value}}" 
                    errorMessage="Sesuatu yang salah">
                </cds-conversational-text>
                <span id="second-sentence">/ bulan</span>
            </div>
            <div class="reached-maximum" *ngIf="hasReachedMaximumSI || hasReachedMaximumMP">
                <cds-assistive-text id="monthly-premium-maximum" class="reached-maximum-details">maksimal {{this.form.get('firstValue')?.value}}</cds-assistive-text>
            </div>
        </cds-conversational-inline>
        <cds-conversational-inline id="sum-insurred-conversational" class="second-paragraph" *ngIf="hasFirstValueActive || hasLanjutClicked"  [class.second-error-occured]="hasReachedMaximumSI || hasReachedMaximumMP">
            <span>dengan demikian, uang pertanggungan saya</span><br>
            <cds-conversational-text 
                id="sum-insured-value" 
                class="sum-insured" 
                formControlName="secondValue" 
                (keydown)="isSecondActive()" 
                [class.invalid]="hasReachedMaximumSI" 
                [class.active]="form.get('secondValue')?.value != null || isSecondClicked != false" 
                [class.second-active]="hasSecondValueActive" 
                [placeholder]="formatValue(calculateNewTotal(this.monthlyPremium))" 
                (click)="isSecondValueActive()" 
                (focusout)="onFocusout('secondValue', $event)" 
                (keypress)="$event.key === 'Enter' || ($event.key).toLowerCase() === 'next' ? scrollTo(665, $event.key) : ''" 
                attr.value="{{this.form.get('secondValue')?.value}}" 
                errorMessage="Sesuatu yang salah">
            </cds-conversational-text>
            <div class="reached-maximum" *ngIf="hasReachedMaximumSI || hasReachedMaximumMP">
                <cds-assistive-text id="sum-insurred-maximum" class="reached-maximum-details">Mohon maaf, uang pertanggungan yang Anda masukan melebihi batas yang ditentukan. Mohon bisa menyesuaikan dengan uang pertanggungan sebagaimana tertera</cds-assistive-text>
            </div>
        </cds-conversational-inline>
        <cds-conversational-inline id="policy-term-conversational" class="third-paragraph" *ngIf="hasFirstValueActive || hasLanjutClicked" [class.third-error-occured]="!hasThirdValueActive && !hasLanjutClicked">
            <span>saya ingin terlindungi </span><br>
            <div class="third-input">
                <span>selama</span>
                <cds-conversational-dropdown 
                    id="policy-term-value" 
                    formControlName="thirdValue" 
                    placeholder="8 tahun" 
                    [options]="yearOptions" 
                    [class.third-active]="hasThirdValueActive" 
                    errorMessage="Sesuatu yang salah" 
                    (click)="isThirdValueActive()" 
                    (focusout)="onFocusout('thirdValue', $event)" 
                    attr.value="{{this.form.get('thirdValue')?.value}}" 
                    [class.third-value-active]="!hasThirdValueActive && !hasLanjutClicked">
                </cds-conversational-dropdown>
            </div>
        </cds-conversational-inline>
        <div class="links" *ngIf="hasThirdValueActive || hasLanjutClicked">
            <cds-link id="popup-link" class="terms-and-conditions" [config]="termsAndConditions" (click)="popUp(); isPopUpClicked()"></cds-link>
        </div>
    </cds-conversational-field>
    <cds-button 
        id="continue" 
        class="continue-button" 
        *ngIf="hasThirdValueActive || hasLanjutClicked" 
        (click)="lanjut()">Lanjut
    </cds-button>
</div>
<div id="make-payment-container" class="make-payment">
    <p id="make-payment-title" class="title">Lakukan Pembayaran</p>
    <p id="payment-first-sentence" class="first-sentence">Anda akan berpindah ke halaman DOKU untuk melakukan transaksi
        pembayaran Premi Anda.</p>
    <p id="payment-second-sentence" class="second-sentence">Beberapa hal penting untuk anda ketahui:</p>
    <div id="payment-details" class="make-payment-details">
        <div id="first-detail-container" class="first-detail">
            <cds-icon id="first-detail-icon" class="cash-benefit-icon" icon="finance:cash_benefit" [config]="iconConfig"
                width="2.5rem" height="2.5rem"></cds-icon>
            <p id="first-sentence" class="first-details-sentence">
                Saat ini pembayaran premi pertama dan premi berikutnya di Manulife Indonesia hanya dapat dilakukan dengan menggunakan kartu kredit/debit berlogo visa/mastercard atas nama Anda selaku pemegang polis yang telah didaftarkan pada halaman DOKU.
            </p>
        </div>
        <div id="second-detail-container" class="second-detail">
            <cds-icon id="second-detail-icon" class="funds-icon" icon="investment:roll_over_funds" [config]="iconConfig"
                width="2.5rem" height="2.5rem"></cds-icon>
            <div id="second-sentence" class="second-details-sentence">
                <p id="second-firstsub-sentence" class="second-details-firstsubsentence">
                    Apabila Anda setuju untuk melanjutkan proses pembayaran, maka untuk pembayaran premi berikutnya secara otomatis akan dilakukan secara autodebit kartu kredit/pendebetan rekening kartu debit Anda pada setiap tanggal jatuh tempo polis Anda (jika tanggal jatuh tempo jatuh pada hari libur maka pendebetan akan dilakukan pada hari kerja berikutnya).
                </p>
                <p id="second-secondsub-sentence" class="second-details-secondsubsentence">
                    Tanggal pendebatan akan disesuaikan dengan tanggal transaksi pertama kali, dengan ketentuan untuk transaksi pada tanggal: (i) 25-4, akan didebet pada tanggal 5 setiap bulannya; (ii) 5 - 14, akan didebet pada tanggal 15 setiap bulannya; atau (iii) 15-24, akan didebet pada tanggal 25 setiap bulannya.
                </p>
            </div>
        </div>
        <div id="third-detail-container" class="third-detail">
            <cds-icon id="third-detail-icon" class="megaphone-icon" icon="action:megaphone" [config]="iconConfig"
                width="2.5rem" height="2.5rem"></cds-icon>
            <p id="third-sentence" class="third-details-sentence">
                Anda wajib segera menginformasikan Manulife Indonesia apabila terdapat perubahan data kartu kredit/debit Anda yang telah didaftarkan pada halaman DOKU.
            </p>
        </div>
        <div id="fourth-detail-container" class="fourth-detail">
            <cds-icon id="fourth-detail-icon" class="search-icon" icon="form:search" [config]="iconConfig"
                width="2.5rem" height="2.5rem"></cds-icon>
            <p id="fourth-sentence" class="fourth-details-sentence">
                Kegagalan pendebitan kartu kredit/debit sebagai akibat dari seperti namun tidak terbatas pada: kartu kredit/debit sudah tidak aktif/expired atau jumlah limit/saldo dana penggunaan kartu kredit/debit tidak mencukupi, sehingga mengakibatkan pendebitan gagal dilakukan merupakan tanggung jawab Anda selaku pemegang polis.
            </p>
        </div>
    </div>
    <div id="payment-buttons-container" class="make-payment-buttons">
            <cds-button 
            id="payment-agree-button" 
            class="agree-button" 
            [config]="agreeConfig" 
            (click)="agree()">
            </cds-button>
        <cds-button id="payment-back-button" class="back-button" [config]="backConfig" (click)="back()">
        </cds-button>
    </div>
</div>

<div *ngIf="appearSpinner === true" id="spinner-overlay" class="spinner">
    <cds-progress id="progress-icon" mode="indeterminate" type="ring" class="ring" [diameter]=60></cds-progress>
</div>
<app-paymentform-redirect [paymentData]=paymentData></app-paymentform-redirect>

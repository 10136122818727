<div class="container">
    <app-header></app-header>
    <div class="not-elligable">
        <cds-icon id="not-elligable-icon" class="elligable-icon" icon="emotion:sad" [config]="config"></cds-icon>
        <div class="message">
            <p id="reached-limit-title" class="title" (click)="isMohonMaafClicked()">Mohon Maaf,</p>
            <p id="reached-limit-details" class="details">
                Anda tidak memenuhi syarat pembelian polis ini. Akumulasi Uang Pertanggungan Anda untuk produk asuransi ini telah melebihi batas maksimal.
            </p>
            <div id="error-logs" class="logs" [class.visible-logs]="MohonMaafClicked === true">{{errorMsg ? errorMsg : ''}}</div>
        </div>
        <div class="buttons-container">
            <cds-button id="back-button" class="back" [config]="buttonConfig" (click)="onBackClicked()"></cds-button>
        </div>
    </div>
    <app-footer></app-footer>
</div>
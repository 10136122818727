import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { MliAnalyticsService } from '@mli/analytics';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CustomerInfoDetails } from '../_model/customer-info-details.model';
import { CustomerInfo } from '../_model/customer-info.model';
import { CustomerDetails } from '../_model/customerdetails-model';
import { EligibilityInfo } from '../_model/eligibility-info.model';
import { ErrorServicesService } from '../_services/error-services.service';
import { GeneralService } from '../_services/general.service';
import { SharedService } from '../_shared/shared.service';

@Component({
  selector: 'app-health-declaration',
  templateUrl: './health-declaration.component.html',
  styleUrls: ['./health-declaration.component.scss']
})
export class HealthDeclarationComponent implements OnInit {
  @Output() selectedIndex = new EventEmitter();
  customerInfo: CustomerInfo = new CustomerInfo();
  addressDetails: any = {};
  eligibilityPayload: EligibilityInfo = new EligibilityInfo();
  eligibilityData: any = {};
  isEligible = '';
  savedId: string = JSON.parse(sessionStorage.getItem('CustomerDetails') || '{}').savedId;
  agreeConfig: CdsButtonConfig = {
    label: 'Saya setuju',
    buttonType: 'button',
    style: 'primary'
  };
  backConfig: CdsButtonConfig = {
    label: 'Kembali',
    buttonType: 'button',
    style: 'secondary'
  };
  steps: any = [];
  // ForTesting
  hasKembaliClicked = false;
  hasSayaSetuhuClicked = false;
  dbsParams: any = {};
  model: CustomerDetails = {};
  customerInfoDetailsReq: CustomerInfoDetails = new CustomerInfoDetails();
  destroySub = new Subject();

  constructor(
    private _router: Router,
    private _sharedService: SharedService,
    private _generalService: GeneralService,
    private mliAnalyticsService: MliAnalyticsService,
    private _error: ErrorServicesService
  ) { }

  ngOnInit(): void {
    this.getCurrentData(this.savedId);
  }

  getCurrentData(id: string): void {
    this.customerInfoDetailsReq = {
      fields: ['isEligible', 'adobeId', 'adobeMarketingCloudId', 'customerID', 'fullName', 'dob', 'gender', 'nationality', 'addressStreet1', 'addressStreet2', 'addressCity', 'addressCountry', 'addressProvince', 'addressType', 'addressState', 'phone', 'hashCode']
    };
    try {
      this._generalService.findById(id, this.customerInfoDetailsReq)
        .pipe(takeUntil(this.destroySub))
        .subscribe(response => {
          this.isEligible = response.isEligible;
          sessionStorage.setItem('current-step', JSON.stringify(1));
          this.steps = JSON.parse(sessionStorage.getItem('completed-steps') || '[]');
          this.dbsParams = {
            adobeId: response.adobeId,
            marketingId: response.adobeMarketingCloudId,
            authCode: response.customerID
          };
          this._sharedService.initializeEligibilityData(this.eligibilityData, response, this.eligibilityPayload);
        }, (error: any) => {
          this._error.handleError(error, {
            details: '/v1/customer-info/customerId Error: ' + error.message,
            secondary_details: 'API Payload: ' + id
          });
        });
    } catch (e) {
      this._router.navigate(['/error-occured']);
    }
  }

  kembali(): void {
    this.mliAnalyticsService?.trackEvent({
      event_label: 'Back',
      event_category: 'Health declaration',
      event_type: 'Click button_Back',
      dbs_adobe_id: this.dbsParams.adobeId,
      dbs_marketing_id: this.dbsParams.marketingId,
      dbs_authcode: this.dbsParams.authCode
    });
    this._sharedService.setData(0);
    this._router.navigate(['mi-flip/build-your-plan']);
    // ForTesting
    this.hasKembaliClicked = true;
  }

  sayaSetuhu(): void {
    this.mliAnalyticsService?.trackEvent({
      event_label: 'Agree',
      event_category: 'Health declaration',
      event_type: 'Click button_Agree',
      dbs_adobe_id: this.dbsParams.adobeId,
      dbs_marketing_id: this.dbsParams.marketingId,
      dbs_authcode: this.dbsParams.authCode
    });
    if (this.steps[1].completed < 1) {
      this.steps[1].completed = 1;
      sessionStorage.setItem('completed-steps', JSON.stringify(this.steps));
    }
    try {
      this._generalService.saveBridger(this.eligibilityPayload).subscribe((response) => {
        console.log('sayaSetuhu: saveBridger Response ==> ', response);
        this.model.watchlistIndicator = response.bridgerResult?.customerEligible;
        if (response.bridgerResult?.customerEligible === 'Y' || this.isEligible === 'Y') {
          this._sharedService.setData(2);
          // ForTesting
          this.hasSayaSetuhuClicked = true;
          this.save(this.model);
        } else {
          sessionStorage.setItem('Error Status', JSON.stringify({
            location: 'page 2: sayaSetuhu', details: {
              subdetails: 'response.bridgerResult?.customerEligible or isEligible is NOT Y',
              maindetails: '=> ' + response.bridgerResult?.customerEligible ? response.bridgerResult?.customerEligible : this.isEligible
            }
          }));
          this._router.navigate(['/not-elligible']);
        }
      }, (error) => {
        console.log('sayaSetuhu: saveBridger Error Response ==> ', error);
        this._error.handleError(error, {
          details: '/v1/IDN/eligibility/bridger Error: ' + error.message,
          secondary_details: 'API Payload: ' + JSON.stringify(this.eligibilityPayload)
        });
        sessionStorage.setItem('current-error', error.statusText);
        sessionStorage.setItem('Error Status', JSON.stringify({
          location: 'page 2: sayaSetuhu', details: {
            subdetails: 'error on eligibility/bridger',
            maindetails: '=> ' + error
          }
        }));
        if (error.status === 400) {
          this._router.navigate(['/data-error']);
        } else {
          this._router.navigate(['/error-occured', error.status]);
        }
      });
    } catch (e) {
      console.log('sayaSetuhu: TryCatch Error Response ==> ', e);
      sessionStorage.setItem('Error Status', JSON.stringify({
        location: 'page 2: sayaSetuhu', details: {
          subdetails: 'error on try catch of eligibility/bridger',
          maindetails: '=> ' + e
        }
      }));
      this._router.navigate(['/error-occured']);
    }
  }

  save(payload: CustomerDetails): void {
    console.log('save is Called');
    console.log('save: payload or this.model Value ==> ', payload);
    console.log('save: payload.watchlistIndicator or this.model.watchlistIndicator Value ==> ', payload.watchlistIndicator);
    this.model.id = this.savedId;
    try {
      this._generalService.updateCustomerInformation(this.savedId, payload)
        .subscribe(() => {
          console.log('save: Successfully Saved in MongoDB');
          this._router.navigate(['mi-flip/add-other-information']);
        }, (error: any) => {
          console.log('save: Unsuccessfully Saved in MongoDB ==> ', error);
          this._error.handleError(error, {
            details: 'PATCH /v1/IDN/customerInfo/customerID Error: ' + error.message,
            secondary_details: 'API Payload: ' + JSON.stringify(payload)
          });
          this._router.navigate(['/error-occured']);
        });
    } catch (e) {
      this._router.navigate(['/error-occured']);
    }
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { delay, retryWhen, scan } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CustomerInfoDetails } from '../_model/customer-info-details.model';
import { CustomerDetails } from '../_model/customerdetails-model';
import { EligibilityInfo } from '../_model/eligibility-info.model';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  constructor(private _http: HttpClient) { }

  saveCustomerInformation(data: CustomerDetails): Observable<any> {
    return this._http.post(`${environment.bffServiceUrl}/v1/IDN/customerInfo/` + data.customerID, data);
  }

  updateCustomerInformation(objectId: any, data: CustomerDetails): Observable<any> {
    return this._http.patch(`${environment.bffServiceUrl}/v1/IDN/customer-info/` + objectId, data);
  }

  findById(customerId: any, customerInfoDetails: CustomerInfoDetails): Observable<any> {
    return this._http.post<CustomerDetails>(`${environment.bffServiceUrl}/v1/IDN/customer-info/` + customerId, customerInfoDetails);
  }

  dokuPayment(data: any): Observable<any> {
    return this._http.post<any>(`${environment.bffServiceUrl}/v1/IDN/payment`, data);
  }

  saveBridger(customerInfo: EligibilityInfo): Observable<any> {
    return this._http.post<any>(`${environment.bffServiceUrl}/v1/IDN/eligibility/` + 'bridger', customerInfo);
  }

  savePayment(customerInfo: any): Observable<any> {
    return this._http.post<any>(`${environment.bffServiceUrl}/v1/IDN/eligibility?eligibilityType=` + 'payment', customerInfo);
  }

  getPaymentStatus(paymentId: any, orderId: any): Observable<any> {
    return this._http.post<any>(`${environment.bffServiceUrl}/v1/IDN/payments/` + paymentId + '/orders/' + orderId, {});
  }

  saveHashcode(customerInfo: EligibilityInfo): Observable<any> {
    return this._http.post<any>(`${environment.bffServiceUrl}/v1/IDN/eligibility/` + 'hashcode', customerInfo);
  }

  getPolicyNumber(): Observable<any> {
    return this._http.get(`${environment.bffServiceUrl}/v1/IDN/policy-number/new`);
  }

  productEngine(productId: any, productEngineReq: any): Observable<any> {
    return this._http.post<any>(`${environment.bffServiceUrl}/v1/IDN/products/` + productId + `/calculate`, productEngineReq);
  }

  customerProfile(authorization: string): Observable<any> {
    return this._http.post<any>(`${environment.bffServiceUrl}/v1/IDN/customer/profile`, authorization);
  }

  getSubmission(policyNumber: any): Observable<any> {
    return this._http.post<any>(`${environment.bffServiceUrl}/v1/IDN/submission`, policyNumber);
  }

  customerEligibility(customer: any): Observable<any> {
    return this._http.post<any>(`${environment.bffServiceUrl}/v1/IDN/customer`, customer);
  }

  productEligibility(product: any): Observable<any> {
    return this._http.post<any>(`${environment.bffServiceUrl}/v1/IDN/eligibility/product`, product);
  }

  getPaymentStatusByOrderRef(orderRef: any): Observable<any> {
    return this._http.post<any>(`${environment.bffServiceUrl}/v1/IDN/orders/` + orderRef, {})
      .pipe(
        retryWhen(error => error.pipe(
          delay(3000),
          scan((retryCount) => {
            if (retryCount > 3) {
              throw error;
            } else {
              retryCount += 1;
              console.log('Retry Count =>' + retryCount);
              return retryCount;
            }
          }, 0)
        ))
      );
  }
}

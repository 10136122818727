import { Component, OnInit } from '@angular/core';
import { DbsRedirectionService } from 'src/app/_shared/dbs-redirection.service';

@Component({
  selector: 'app-session-expired',
  templateUrl: './session-expired.component.html',
  styleUrls: ['./session-expired.component.scss']
})
export class SessionExpiredComponent implements OnInit {

  constructor(private _dbsshared: DbsRedirectionService) {
    // This is intentional
  }

  ngOnInit(): void {
    // This is intentional
  }

  isBackClicked(): void {
    sessionStorage.clear();
    this._dbsshared.navigateToDashboard();
  }

}

<div class="registration-form">
    <p id="registration-form-title" class="title">Formulir Pendaftaran</p>
    <div class="personal-information">
        <p id="personal-title" class="personal-info-title">Informasi Pribadi</p>
        <p id="first-personal-label" class="personal-info-label">Nama lengkap</p>
        <p id="first-personal-value" class="personal-info-value personal-info-name">{{customerDetails.fullName ? customerDetails.fullName : '-'}}</p>
        <p id="second-personal-label" class="personal-info-label">No. KTP</p>
        <p id="second-personal-value"class="personal-info-value">{{customerDetails.docNo ? _sharedService.maskKtpAddress(customerDetails.docNo) : '-'}}</p>
        <p id="third-personal-label" class="personal-info-label">Tempat / tanggal lahir</p>
        <div class="personal-info-value address-birth">
            <p id="third-address-value">{{customerDetails.placeOfBirth ? customerDetails.placeOfBirth : '-'}} /</p>
            <p id="third-dob-value">{{customerDetails.dob ? (customerDetails.dob | date:"dd-MM-yyyy") : '-'}}</p>
        </div>
    </div>
    <div class="contact-data">
        <p id="contact-title" class="contact-data-title">Data Kontak</p>
        <p id="first-contact-label" class="contact-data-label">Alamat email</p>
        <p id="first-contact-value" class="contact-data-value contact-data-email">{{customerDetails.email ? customerDetails.email : '-'}}</p>
        <p id="second-contact-label" class="contact-data-label">No. HP</p>
        <p id="second-contact-value" class="contact-data-value">{{customerDetails.phone ? customerDetails.phone : '-'}}</p>
    </div>
    <div class="protection-plan">
        <p id="protection-title" class="protection-plan-title">Rencana Perlindungan</p>
        <p id="first-protection-label" class="protection-plan-label">Uang pertanggungan</p>
        <p id="first-protection-value" class="protection-plan-value">{{calculatedProduct.secondValue ? calculatedProduct.secondValue : '-'}}</p>
        <p id="second-protection-label" class="protection-plan-label">Masa perlindungan / masa pembayaran premi</p>
        <p id="second-protection-value" class="protection-plan-value">{{calculatedProduct.thirdValue ? +((calculatedProduct.thirdValue).split('F')[1]) : '-'}} tahun</p>
        <p id="third-protection-label" class="protection-plan-label">Nama Penerima Manfaat</p>
        <p id="third-protection-value" class="protection-plan-value">{{beneficiary.beneficiary ? beneficiary.beneficiary : '-'}}</p>
        <p id="fourth-protection-label" class="protection-plan-label">Hubungan Penerima Manfaat</p>
        <p id="fourth-protection-value" class="protection-plan-value">{{beneficiary.relationship ? translateRelation(beneficiary.relationship) : '-'}}</p>
    </div>
    <div class="payment-details">
        <p id="payment-title" class="payment-details-title">Detil Pembayaran</p>
        <p id="first-payment-label" class="payment-details-label">Nominal premi berikutnya</p>
        <p id="first-payment-value" class="payment-details-value">{{calculatedProduct.firstValue ? calculatedProduct.firstValue : '-'}} / bulan</p>
        <p id="second-payment-label" class="payment-details-label">Frekuensi pembayaran</p>
        <p id="second-payment-value" class="payment-details-value">{{customerDetails.frequency ? customerDetails.frequency : 'Bulanan'}}</p>
        <div>
            <p id="third-payment-label" class="payment-details-label">Kode promo</p>
            <cds-icon 
                id="information-tooltip" 
                class="cashback-tooltip" 
                icon="action:info_1" 
                cdsTooltip="Hadiah akan diberikan sesuai dengan syarat dan ketentuan yang berlaku">
            </cds-icon>
        </div>
        <p id="third-payment-value" class="payment-details-value">{{beneficiary.promo ? beneficiary.promo : '-'}}</p>
    </div>
    <div class="registration-form-checkbox">
        <div class="checkbox-container">
            <cds-checkbox id="application-checkbox" class="agree-checkbox" [config]="checkboxConfig" (click)="isChecked()" [checked]="hasChecked"></cds-checkbox>
            <p id="application-checkbox-label" class="checkbox-label">
                Saya telah memahami dan menyetujui <a id="popup-link-label" class="popup-link" (click)="popUp('policy'); isPopUpClicked()"><strong><u>Ringkasan Informasi Produk & Layanan,</u></strong></a> manfaat, biaya, dan risiko produk asuransi serta <a id="popup-link-label" class="popup-link" (click)="popUp('statement'); isPopUpClicked()"><strong><u>Pernyataan & Kuasa</u></strong></a>
            </p>
        </div>
        <a id="download-riplay-file" class="download-file-link" href="javascript:void(0)" style="cursor:pointer" (click)="downloadPdf()">
            <div id="download-riplay" class="download-riplay-link">
                <div id="download-icon" class="download-riplay-icon"></div>
                <p id="download-details" class="download-riplay-details">Ringkasan Informasi Produk & Layanan Umum</p>
            </div>
        </a>
        <cds-assistive-text id="application-required-error" class="required-error" [class.unhidden]="(errorContinued && !hasChecked) || (!hasChecked && !applicationIsEmpty) || (applicationSummary.checkedStatus === 'unchecked')">Anda wajib membaca dan menyetujui S&K</cds-assistive-text>
    </div>
    <div class="registration-form-buttons">
        <cds-button 
            id="continue-button" 
            class="continue" 
            [config]="continueConfig"
            [disabled]="!hasChecked ? true : false" 
            (click)="!hasChecked ? lanjutError() : lanjut()">
        </cds-button>
        <cds-button 
            id="back-button" 
            class="back" 
            [config]="backConfig"
            (click)="kembali()">
        </cds-button>
    </div>
</div>


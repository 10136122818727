<header></header>
<div class="confirmation-add-information" [formGroup]="formGroup">
    <div id="main-title" class="confirm-title">Konfirmasi dan Lengkapi Informasi</div>
    <cds-inline-alert id="first-inline-alert" class="first-info-box" type = 'info'>
        Kami telah mengisi beberapa informasi berdasarkan data dari bank Anda
    </cds-inline-alert>
    <div class="personal-information">
        <p id="personal-title" class="personal-info-title">Informasi Pribadi</p>
        <!-- Personal Details -->
        <p id="first-personal-subtitle" class="personal-info-subtitle">Nama Lengkap</p>
        <p id="first-personal-value" class="personal-info-value">{{stringFullname ? stringFullname : '-'}}</p>
        <p id="second-personal-subtitle" class="personal-info-subtitle">No. KTP</p>
        <p id="second-personal-value" class="personal-info-value">{{customerDetails.docNo ? _sharedService.maskKtpAddress(customerDetails.docNo) : '-'}}</p>
        <p id="third-personal-subtitle" class="personal-info-subtitle">Tempat/Tanggal Lahir</p>
        <div class="personal-info-value address-birth">
            <p id="third-address-value">{{customerDetails.placeOfBirth ? customerDetails.placeOfBirth : '-'}} /</p>
            <p id="third-dob-value">{{customerDetails.dob ? (customerDetails.dob | date:"dd-MM-yyyy") : '-'}}</p>
        </div>
        <!-- -->
    </div>
    <div class="contact-data">
        <p id="contact-title" class="contact-data-title">Data Kontak</p>
        <cds-inline-alert id="second-inline-alert" class="second-info-box" type = 'warning'>
            Manulife akan mengirimkan polis elektronik (e-policy) dan komunikasi penting lainnya ke alamat email Anda. 
            Mohon pastikan alamat email Anda benar dan telah terupdate.
        </cds-inline-alert>
        <!-- Contact Details -->
        <p id="first-contact-subtitle" class="contact-data-subtitle">Alamat Email</p>
        <div id="first-contact-value" class="contact-data-value">
            <div id="contact-data-email" class="contact-data-emailval" [class.noInitialEmail]="isEmailHasInitialValue === false">{{customerDetails.email ? customerDetails.email : 'wajib diisi'}}</div>
            <cds-icon id="edit-email-icon" class="edit-icon" icon="action:edit" [config]="iconConfig" (click)="editEmailClicked()"></cds-icon>
        </div>
        <p id="second-contact-subtitle" class="contact-data-subtitle">No. HP</p>
        <p id="second-contact-value" class="contact-data-value">{{customerDetails.phone ? customerDetails.phone : '-'}}</p>
        <!-- -->
    </div>
    <div class="beneficiary-information">
        <p id="beneficiary-title" class="beneficiary-info-title">Informasi Penerima Manfaat dan Lainnya</p>
        <div class="beneficiary-name">
            <cds-textfield 
                id="beneficiary-text" 
                class="beneficiary-textfield" 
                [config]="textfieldConfig" 
                CdsColorTheme = 'ml' 
                CdsTextfieldVariationTypes = 'default' 
                formControlName="beneficiary" 
                (keyup)="onEnterName($event)" 
                (click)="isLanjutClicked('beneficiary')" 
                (click)="scrollTo('beneficiary')" 
                (focusout)="hasRelationshipClicked !== true ? scrollTo('relationship') : ''" 
                (keypress)="$event.key === 'Enter' || ($event.key).toLowerCase() === 'next' ? scrollTo('relationship') : ''" 
                attr.value="{{this.formGroup.get('beneficiary')?.value}}" 
                [class.invalid]="((formGroup.get('beneficiary')?.hasError('required') || this.detectWhiteSpace(this.formGroup.get('beneficiary')?.value) === false) && hasLanjutClicked) || !isNameValid">
            </cds-textfield>
            <cds-icon 
                *ngIf="((formGroup.get('beneficiary')?.hasError('required') || this.detectWhiteSpace(this.formGroup.get('beneficiary')?.value) === false) && hasLanjutClicked) || !isNameValid" 
                id="beneficiary-name-tooltip" 
                class="info-tooltip" 
                icon="action:info_1" 
                cdsTooltip="Penerima manfaat asuransi adalah pihak yang mendapatkan manfaat (uang pertanggungan, pengembalian premi, dsb.) dari produk asuransi yang Anda beli">
            </cds-icon>
        </div>
        <p id="beneficiary-name-error" class="pattern-error name-error" *ngIf="!isNameValid">
            <cds-assistive-text>tidak dapat mengandung angka dan simbol</cds-assistive-text>
        </p>
        <p 
            id="name-required-error" 
            class="required-error name-error" 
            *ngIf="(formGroup.get('beneficiary')?.hasError('required') || this.detectWhiteSpace(this.formGroup.get('beneficiary')?.value) === false) && hasLanjutClicked">
            <cds-assistive-text>wajib diisi</cds-assistive-text>
        </p>
        <cds-dropdown 
            id="relationship-dropdown" 
            class="beneficiary-dropdown" 
            [config]="dropdownConfig" 
            formControlName="relationship" 
            (click)="isLanjutClicked('relationship')" 
            (click)="scrollTo('relationship')" 
            (focusout)="hasDestinationClicked !== true ? scrollTo('destination') : ''" 
            (keypress)="$event.key === 'Enter' || ($event.key).toLowerCase() === 'next' ? scrollTo('destination') : ''" 
            attr.value="{{this.formGroup.get('relationship')?.value}}" 
            [class.invalid]="formGroup.get('relationship')?.hasError('required') && hasLanjutClicked">
        </cds-dropdown>
        <p id="relationship-required-error" class="required-error" *ngIf="formGroup.get('relationship')?.hasError('required') && hasLanjutClicked">
            <cds-assistive-text>wajib diisi</cds-assistive-text>
        </p>
        <cds-dropdown 
            id="destination-dropdown" 
            class="beneficiary-dropdown second-dropdown" 
            [config]="secondDropdownConfig" 
            formControlName="destination" 
            (click)="isLanjutClicked('destination')" 
            (click)="scrollTo('destination')" 
            (focusout)="hasPromoClicked !== true ? scrollTo('promo') : ''" 
            (keypress)="$event.key === 'Enter' || ($event.key).toLowerCase() === 'next' ? scrollTo('promo') : ''" 
            attr.value="{{this.formGroup.get('destination')?.value}}" 
            [class.invalid]="formGroup.get('destination')?.hasError('required') && hasLanjutClicked">
        </cds-dropdown>
        <p id="destination-required-error" class="required-error" *ngIf="formGroup.get('destination')?.hasError('required') && hasLanjutClicked">
            <cds-assistive-text>wajib diisi</cds-assistive-text>
        </p>
        <div class="promo-code">
            <cds-textfield 
                id="promo-code-text" 
                class="payment-details-textfield" 
                [config]="promoCode" CdsColorTheme='ml' 
                formControlName="promo" 
                (click)="isLanjutClicked('promo')" 
                (click)="scrollTo('promo')" 
                attr.value="{{this.formGroup.get('promo')?.value}}" 
                CdsTextfieldVariationTypes='default'>
            </cds-textfield>
            <cds-icon 
                id="promo-code-tooltip" 
                class="info-tooltip" 
                icon="action:info_1" 
                cdsTooltip="Hadiah akan diberikan sesuai dengan syarat dan ketentuan yang berlaku">
            </cds-icon>
        </div>
        <p id="promo-pattern-error" class="pattern-error" *ngIf="formGroup.get('promo')?.hasError('pattern') || formGroup.get('promo')?.hasError('maxlength')">
            <cds-assistive-text>Kode Promo salah. Mohon periksa kembali Kode Promo Anda</cds-assistive-text>
        </p>
    </div>
    <div class="confimation-info-buttons">
        <cds-button 
            id="continue-button" 
            class="continue" 
            [config]="continueConfig"
            [disabled]="!this.formGroup.valid || this.detectWhiteSpace(this.formGroup.get('beneficiary')?.value) === false || !isNameValid || this.isEmailHasInitialValue === false ? true : false" 
            (click)="!this.formGroup.valid || this.detectWhiteSpace(this.formGroup.get('beneficiary')?.value) === false || !isNameValid || this.isEmailHasInitialValue === false ? isLanjutClicked('lanjut') : lanjut()">
        </cds-button>
        <cds-button 
            id="back-button" 
            class="back" 
            [config]="backConfig"
            (click)="kembali()">
        </cds-button>
    </div>   
</div>
<footer></footer>
<app-header></app-header>
<div class="in-progress">
    <p class="title">Pengajuan Asuransi Anda sedang dalam Proses</p>
    <div class="details">
        <p class="paragraph">Terima kasih karena telah memilih Manulife untuk melindungi Anda dan orang yang Anda sayangi.
            </p>
         <p class="paragraph">Pengajuan Asuransi MiFirst Life Protector Anda sedang dalam proses.
         </p>  
         <p class="paragraph">  Manulife akan mengirimkan notifikasi hasil pengajuan Anda melalui email yang telah terdaftar pada pengajuan ini dalam kurun waktu maksimal 1x24 jam.
         </p>  
         <p class="paragraph">  Mohon Anda dapat memeriksa email Anda yang terdaftar pada pengajuan ini secara berkala.
         </p>  
         <p class="paragraph">  Anda dapat menutup halaman ini untuk kembali ke aplikasi DBS Digibank Anda."
        </p>
    </div>
    <div class="in-progress-buttons">
        <cds-button class="back" [config]="backConfig" (click)="kembali()"></cds-button>
    </div>
</div>
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { CdsTextfieldConfig } from '@cds/ng-core/textfield';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { CdsToastService } from '@cds/ng-web-components/toast';
import { MliAnalyticsService } from '@mli/analytics';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ChangeEmailAddressComponent } from '../_modal/change-email-address/change-email-address.component';
import { CustomerInfoDetails } from '../_model/customer-info-details.model';
import { CustomerDetails } from '../_model/customerdetails-model';
import { ErrorServicesService } from '../_services/error-services.service';
import { GeneralService } from '../_services/general.service';
import { SharedService } from '../_shared/shared.service';

@Component({
  selector: 'app-add-other-information',
  templateUrl: './add-other-information.component.html',
  styleUrls: ['./add-other-information.component.scss']
})
export class AddOtherInformationComponent implements OnInit, OnDestroy {
  @Output() selectedIndex = new EventEmitter();
  formGroup: FormGroup;

  iconConfig: CdsIconConfig = {
    size: 'sm',
    color: 'default',
  };
  cautionConfig: CdsIconConfig = {
    size: 'sm',
    color: '#C14A36',
  };
  textfieldConfig: CdsTextfieldConfig = {
    label: 'Nama penerima manfaat',
    placeholder: 'Isi nama penerima manfaat',
    type: 'text'
  };
  dropdownConfig: CdsDropdownConfig = {
    label: 'Hubungan penerima manfaat dengan tertanggung',
    placeholder: 'Pilih hubungan',
    options: [
      { label: 'Suami', value: 'Husband' },
      { label: 'Istri', value: 'Wife' },
      { label: 'Ayah', value: 'Father' },
      { label: 'Ibu', value: 'Mother' },
      { label: 'Anak Laki-laki', value: 'Son' },
      { label: 'Anak Perempuan', value: 'Daughter' },
      { label: 'Saudara kandung Laki-laki', value: 'Brother' },
      { label: 'Saudara kandung Perempuan', value: 'Sister' }
    ]
  };
  secondDropdownConfig: CdsDropdownConfig = {
    label: 'Tujuan pembelian asuransi',
    placeholder: 'Pilih tujuan pembelian',
    options: [
      { label: 'Proteksi', value: 'Proteksi' },
      { label: 'Warisan', value: 'Warisan' }
    ]
  };
  promoCode: CdsTextfieldConfig = {
    label: 'Kode promo (opsional)',
    type: 'text'
  };
  backConfig: CdsButtonConfig = {
    label: 'Kembali',
    buttonType: 'button',
    style: 'secondary'
  };
  continueConfig: CdsButtonConfig = {
    label: 'Lanjut',
    buttonType: 'button',
    style: 'primary'
  };

  object: any = {};
  storageObject: any = {};
  accountId = '4';
  accountKey = 'User' + this.accountId;

  stringFullname = '';
  beneficiaryValue = '';
  relationshipValue = '';
  destinationValue = '';
  promoValue = '';
  emailValue = '';

  customer: any = {};
  customerDetails: any = {};

  // ForTesting
  hasPopUpClicked = false;
  hasKembaliClicked = false;
  hasLanjutClicked = false;
  hasEditEmailClicked = false;
  hasBeneficiaryClicked = false;
  hasRelationshipClicked = false;
  hasDestinationClicked = false;
  hasPromoClicked = false;
  isNameValid = true;
  isEmailHasInitialValue = false;
  steps: any = [];
  model: CustomerDetails = {};
  customerInfoDetailsReq: CustomerInfoDetails = new CustomerInfoDetails();
  destroySub = new Subject();
  dbsParams: any = {};

  constructor(
    private cdsPopup: CdsPopupService,
    private formBuilder: FormBuilder,
    public _sharedService: SharedService,
    private _router: Router,
    private toast: CdsToastService,
    private _service: GeneralService,
    private mliAnalyticsService: MliAnalyticsService,
    public _error: ErrorServicesService

  ) {
    this.formGroup = this.formBuilder.group({
      beneficiary: new FormControl('', Validators.required),
      relationship: new FormControl(''),
      destination: new FormControl(''),
      promo: new FormControl('', [Validators.pattern('[A-Za-z0-9%]+'), Validators.maxLength(10)])
    });
  }

  ngOnInit(): void {
    sessionStorage.setItem('current-step', JSON.stringify(2));
    this.steps = JSON.parse(sessionStorage.getItem('completed-steps') || '[]');
    this.customer = JSON.parse(sessionStorage.getItem('CustomerDetails') || '{}');

    this.customerInfoDetailsReq = {
      fields: ['fullName', 'customerEmail', 'beneficiary', 'relationship', 'destination', 'promoCode', 'docNo', 'placeOfBirth', 'dob', 'phone', 'adobeId', 'adobeMarketingCloudId', 'customerID']
    };

    try {
      this._service.findById(this.customer.savedId, this.customerInfoDetailsReq)
        .pipe(takeUntil(this.destroySub))
        .subscribe(response => {
          this.stringFullname = response.fullName;
          this.emailValue = response.customerEmail;
          this.beneficiaryValue = response.beneficiary;
          this.relationshipValue = response.relationship;
          this.destinationValue = response.destination;
          this.promoValue = response.promoCode;
          this.customerDetails = {
            docNo: response.docNo ? response.docNo : '-',
            placeOfBirth: response.placeOfBirth ? response.placeOfBirth : '-',
            dob: response.dob ? response.dob : '-',
            email: response.customerEmail ? response.customerEmail : undefined,
            phone: response.phone ? response.phone : '-'
          };

          this.formGroup.patchValue({
            beneficiary: this.beneficiaryValue,
            relationship: this.relationshipValue,
            destination: this.destinationValue,
            promo: this.promoValue
          });

          if (['', null, undefined].includes(response.customerEmail) === true) {
            this.isEmailHasInitialValue = false;
            console.log('ngOnInit: Email does NOT have a Initial Value ' + this.isEmailHasInitialValue + ', ' + response.customerEmail);
          } else {
            this.isEmailHasInitialValue = true;
            console.log('ngOnInit: Email does have a Initial Value ' + this.isEmailHasInitialValue + ', ' + response.customerEmail);
          }
          this.dbsParams = {
            adobeId: response.adobeId,
            marketingId: response.adobeMarketingCloudId,
            authCode: response.customerID
          };
        }, (error: any) => {
          this._error.handleError(error, {
            details: '/v1/customer-info/customerId Error: ' + error.message,
            secondary_details: 'API Payload: ' + this.customer.savedId
          });
        });
    } catch (e) {
      this._router.navigate(['/error-occured']);
    }
  }

  popUp(): void {
    const popupRef = this.cdsPopup?.open(ChangeEmailAddressComponent, {
      size: 'md'
    });
    popupRef?.afterClosed().pipe(takeUntil(this.destroySub)).subscribe(() => {
      // This is intentional
    });
  }

  // ForTesting of popUp()
  isPopUpClicked(): void {
    this.hasPopUpClicked = true;
  }

  kembali(): void {
    this.mliAnalyticsService?.trackEvent({
      event_label: 'Back',
      event_category: 'User information',
      event_type: 'Click button_Back',
      dbs_adobe_id: this.dbsParams.adobeId,
      dbs_marketing_id: this.dbsParams.marketingId,
      dbs_authcode: this.dbsParams.authCode
    });
    this._sharedService.setData(1);
    this.save('back');

    // ForTesting
    this.hasKembaliClicked = true;
  }

  lanjut(): void {
    this.mliAnalyticsService?.trackEvent({
      event_label: 'Next',
      event_category: 'User information',
      event_type: 'Click button_Next',
      dbs_adobe_id: this.dbsParams.adobeId,
      dbs_marketing_id: this.dbsParams.marketingId,
      dbs_authcode: this.dbsParams.authCode
    });
    if (this.steps[2].completed < 1) {
      this.steps[2].completed = 1;
      sessionStorage.setItem('completed-steps', JSON.stringify(this.steps));
    }
    this._sharedService.setData(3);
    this.save('continue');

    // ForTesting
    this.hasLanjutClicked = true;
  }

  editEmailClicked(): void {
    this.mliAnalyticsService?.trackEvent({
      event_label: 'Edit email',
      event_category: 'User information',
      event_type: 'Click button_edit email',
      dbs_adobe_id: this.dbsParams.adobeId,
      dbs_marketing_id: this.dbsParams.marketingId,
      dbs_authcode: this.dbsParams.authCode
    });
    const emailPopup = this.cdsPopup?.open(ChangeEmailAddressComponent, {
      size: 'md',
      data: {
        name: 'John Doe',
      },
    });

    emailPopup?.afterClosed().pipe(takeUntil(this.destroySub)).subscribe((data: any) => {
      if (data.agree === true) {
        this.mliAnalyticsService?.trackEvent({
          event_label: 'Edit email',
          event_category: 'User information',
          event_type: 'Click button_confirm edit email',
          dbs_adobe_id: this.dbsParams.adobeId,
          dbs_marketing_id: this.dbsParams.marketingId,
          dbs_authcode: this.dbsParams.authCode
        });
        this.emailValue = data.emailValue;
        this.customerDetails.email = data.emailValue;
        this.isEmailHasInitialValue = true;

        this.toast.success('Alamat email berhasil diubah.', { delay: 3000 });
      } else {
        this.mliAnalyticsService?.trackEvent({
          event_label: 'Edit email',
          event_category: 'User information',
          event_type: 'Click button_close edit email page',
          dbs_adobe_id: this.dbsParams.adobeId,
          dbs_marketing_id: this.dbsParams.marketingId,
          dbs_authcode: this.dbsParams.authCode
        });
      }
    });
  }

  // ForTesting of editEmailClicked()
  isEditEmailClicked(): void {
    this.hasEditEmailClicked = true;
  }

  isLanjutClicked(value: string): void {
    this.mliAnalyticsService?.trackEvent({
      event_label: 'Next',
      event_category: 'User information',
      event_type: 'Click button_Next',
      dbs_adobe_id: this.dbsParams.adobeId,
      dbs_marketing_id: this.dbsParams.marketingId,
      dbs_authcode: this.dbsParams.authCode
    });
    if (value === 'lanjut') {
      this.hasLanjutClicked = true;
    } else {
      this.hasLanjutClicked = false;
    }
  }

  scrollTo(value: string): void {
    switch (value) {
      case 'beneficiary':
        this.hasBeneficiaryClicked = true;
        window.scroll(0, 1200);
        this.onFocus('#beneficiary-text [id=mat-input-0]');
        break;
      case 'relationship':
        if (this.hasRelationshipClicked === false) {
          this.hasRelationshipClicked = true;
          this.formGroup.patchValue({
            relationship: ['', undefined, null].includes(this.formGroup.get('relationship')?.value) === true ? this.dropdownConfig.options[0].value : this.formGroup.get('relationship')?.value
          });
          window.scroll(0, 1200);
          this.onFocus('#relationship-dropdown [id=mat-select-0]');
          break;
        } else {
          this.hasRelationshipClicked = true;
          window.scroll(0, 1200);
          this.onFocus('#relationship-dropdown [id=mat-select-0]');
          break;
        }
      case 'destination':
        if (this.hasDestinationClicked === false) {
          this.hasDestinationClicked = true;
          this.formGroup.patchValue({
            destination: ['', undefined, null].includes(this.formGroup.get('destination')?.value) === true ? this.secondDropdownConfig.options[0].value : this.formGroup.get('destination')?.value
          });
          window.scroll(0, 1370);
          this.onFocus('#destination-dropdown [id=mat-select-1]');
          break;
        } else {
          this.hasDestinationClicked = true;
          window.scroll(0, 1370);
          this.onFocus('#destination-dropdown [id=mat-select-1]');
          break;
        }
      case 'promo':
        this.hasPromoClicked = true;
        window.scroll(0, 1370);
        this.onFocus('#promo-code-text [id=mat-input-1]');
        break;
      default:
        console.log(value);
        break;
    }
  }

  detectWhiteSpace(value: string): boolean {
    const arr = value.split('');
    let output = true;
    for (let index = 0; index < arr.length; index++) {
      if (arr[index] !== ' ') {
        return true;
      } else {
        output = false;
      }
    }
    return output;
  }

  save(value: string): void {
    this.model.beneficiary = this.formGroup.get('beneficiary')?.value;
    this.model.relationship = this.formGroup.get('relationship')?.value;
    this.model.destination = this.formGroup.get('destination')?.value;
    this.model.promoCode = this.formGroup.get('promo')?.value;
    this.model.customerEmail = this.emailValue;
    this.model.id = this.customer.savedId;
    try {
      this._service.updateCustomerInformation(this.customer.savedId, this.model)
        .pipe(takeUntil(this.destroySub))
        .subscribe(() => {
          console.log('save: Successfully Saved in MongoDB');
          if (value === 'continue') {
            this._router.navigate(['/mi-flip/application-summary']);
          } else {
            this._router.navigate(['/mi-flip/health-declaration']);
          }
        }, (error: any) => {
          console.log('save: Unsuccessfully Saved in MongoDB ==> ', error);
          this._error.handleError(error, {
            details: 'PATCH /v1/IDN/customer-info/customerID Error: ' + error.message,
            secondary_details: 'API Payload: ' + JSON.stringify(this.model)
          });
          this._router.navigate(['/error-occured']);
        });
    } catch (e) {
      this._router.navigate(['/error-occured']);
    }
  }

  onEnterName(event: any): void {
    const value = '' + event.target?.value;
    if (!/[^a-zA-Z ]/.test(value) === true) {
      this.isNameValid = true;
    } else {
      this.isNameValid = false;
    }
  }

  onFocus(selector: string): void {
    console.log('onFocus: onFocus is Called');
    setTimeout(() => {
      console.log('onFocus: HTML element value ==> ', document.querySelectorAll(selector));
      const htmlSelector = document.querySelectorAll<HTMLElement>(selector);
      htmlSelector[0]?.focus();
    }, 100);
  }

  ngOnDestroy(): void {
    this.destroySub.next();
    this.destroySub.complete();
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public redirectUri = 'http://localhost:8089/loading?id=1';
  public grantType = 'client_credentials';
  public accessTokenUrl = 'https://login.microsoftonline.com/5d3e2773-e07f-4432-a630-1a0f68a28a05/oauth2/v2.0/token';
  public clientId = 'eb1f6fec-c677-4cbc-ab6f-5facb53551c44';
  public clienrSecret = 'FVL8Q~mBxs5GFiQsAIMhLeFi6KK_Vd3vm65tydyb';
  public scope = 'api://9abb552d-e6d2-45ba-9118-c81f5d9fff8b/.default';

  constructor(private _http: HttpClient) {
    // This is intentional
  }

  retrieveToken(): void {

    let params = new URLSearchParams();
    params.append('client_id', this.clientId);
    params.append('client_secret', this.clienrSecret);
    params.append('grant_type', this.grantType);
    params.append('scope', this.scope);

    let headers =
      new HttpHeaders({ 'Content-type': 'application/x-www-form-urlencoded; charset=utf-8' });

    console.log("Params ==> ", params);
    this._http.post(this.accessTokenUrl,
      params.toString(), { headers })
      .subscribe(
        data => {
          this.saveToken(data);
        }, err => {
          console.log('Invalid Credentials ==> ', err);
        });
  }

  saveToken(token: any): void {
    const expireDate = new Date().getTime() + (1000 * token.expires_in);
    sessionStorage.set('access_token', token.access_token, expireDate);
    console.log('Obtained Access token');
  }

  checkCredentials(): any {
    return sessionStorage.check('access_token');
  }

  logout(): void {
    sessionStorage.delete('access_token');
    window.location.reload();
  }
}

export enum TopLevelDomains {
    AAA = "AAA",
    AARP = "AARP",
    ABARTH = "ABARTH",
    ABB = "ABB",
    ABBOTT = "ABBOTT",
    ABBVIE = "ABBVIE",
    ABC = "ABC",
    ABLE = "ABLE",
    ABOGADO = "ABOGADO",
    ABUDHABI = "ABUDHABI",
    AC = "AC",
    ACADEMY = "ACADEMY",
    ACCENTURE = "ACCENTURE",
    ACCOUNTANT = "ACCOUNTANT",
    ACCOUNTANTS = "ACCOUNTANTS",
    ACO = "ACO",
    ACTOR = "ACTOR",
    AD = "AD",
    ADAC = "ADAC",
    ADS = "ADS",
    ADULT = "ADULT",
    AE = "AE",
    AEG = "AEG",
    AERO = "AERO",
    AETNA = "AETNA",
    AF = "AF",
    AFAMILYCOMPANY = "AFAMILYCOMPANY",
    AFL = "AFL",
    AFRICA = "AFRICA",
    AG = "AG",
    AGAKHAN = "AGAKHAN",
    AGENCY = "AGENCY",
    AI = "AI",
    AIG = "AIG",
    AIRBUS = "AIRBUS",
    AIRFORCE = "AIRFORCE",
    AIRTEL = "AIRTEL",
    AKDN = "AKDN",
    AL = "AL",
    ALFAROMEO = "ALFAROMEO",
    ALIBABA = "ALIBABA",
    ALIPAY = "ALIPAY",
    ALLFINANZ = "ALLFINANZ",
    ALLSTATE = "ALLSTATE",
    ALLY = "ALLY",
    ALSACE = "ALSACE",
    ALSTOM = "ALSTOM",
    AM = "AM",
    AMAZON = "AMAZON",
    AMERICANEXPRESS = "AMERICANEXPRESS",
    AMERICANFAMILY = "AMERICANFAMILY",
    AMEX = "AMEX",
    AMFAM = "AMFAM",
    AMICA = "AMICA",
    AMSTERDAM = "AMSTERDAM",
    ANALYTICS = "ANALYTICS",
    ANDROID = "ANDROID",
    ANQUAN = "ANQUAN",
    ANZ = "ANZ",
    AO = "AO",
    AOL = "AOL",
    APARTMENTS = "APARTMENTS",
    APP = "APP",
    APPLE = "APPLE",
    AQ = "AQ",
    AQUARELLE = "AQUARELLE",
    AR = "AR",
    ARAB = "ARAB",
    ARAMCO = "ARAMCO",
    ARCHI = "ARCHI",
    ARMY = "ARMY",
    ARPA = "ARPA",
    ART = "ART",
    ARTE = "ARTE",
    AS = "AS",
    ASDA = "ASDA",
    ASIA = "ASIA",
    ASSOCIATES = "ASSOCIATES",
    AT = "AT",
    ATHLETA = "ATHLETA",
    ATTORNEY = "ATTORNEY",
    AU = "AU",
    AUCTION = "AUCTION",
    AUDI = "AUDI",
    AUDIBLE = "AUDIBLE",
    AUDIO = "AUDIO",
    AUSPOST = "AUSPOST",
    AUTHOR = "AUTHOR",
    AUTO = "AUTO",
    AUTOS = "AUTOS",
    AVIANCA = "AVIANCA",
    AW = "AW",
    AWS = "AWS",
    AX = "AX",
    AXA = "AXA",
    AZ = "AZ",
    AZURE = "AZURE",
    BA = "BA",
    BABY = "BABY",
    BAIDU = "BAIDU",
    BANAMEX = "BANAMEX",
    BANANAREPUBLIC = "BANANAREPUBLIC",
    BAND = "BAND",
    BANK = "BANK",
    BAR = "BAR",
    BARCELONA = "BARCELONA",
    BARCLAYCARD = "BARCLAYCARD",
    BARCLAYS = "BARCLAYS",
    BAREFOOT = "BAREFOOT",
    BARGAINS = "BARGAINS",
    BASEBALL = "BASEBALL",
    BASKETBALL = "BASKETBALL",
    BAUHAUS = "BAUHAUS",
    BAYERN = "BAYERN",
    BB = "BB",
    BBC = "BBC",
    BBT = "BBT",
    BBVA = "BBVA",
    BCG = "BCG",
    BCN = "BCN",
    BD = "BD",
    BE = "BE",
    BEATS = "BEATS",
    BEAUTY = "BEAUTY",
    BEER = "BEER",
    BENTLEY = "BENTLEY",
    BERLIN = "BERLIN",
    BEST = "BEST",
    BESTBUY = "BESTBUY",
    BET = "BET",
    BF = "BF",
    BG = "BG",
    BH = "BH",
    BHARTI = "BHARTI",
    BI = "BI",
    BIBLE = "BIBLE",
    BID = "BID",
    BIKE = "BIKE",
    BING = "BING",
    BINGO = "BINGO",
    BIO = "BIO",
    BIZ = "BIZ",
    BJ = "BJ",
    BLACK = "BLACK",
    BLACKFRIDAY = "BLACKFRIDAY",
    BLOCKBUSTER = "BLOCKBUSTER",
    BLOG = "BLOG",
    BLOOMBERG = "BLOOMBERG",
    BLUE = "BLUE",
    BM = "BM",
    BMS = "BMS",
    BMW = "BMW",
    BN = "BN",
    BNPPARIBAS = "BNPPARIBAS",
    BO = "BO",
    BOATS = "BOATS",
    BOEHRINGER = "BOEHRINGER",
    BOFA = "BOFA",
    BOM = "BOM",
    BOND = "BOND",
    BOO = "BOO",
    BOOK = "BOOK",
    BOOKING = "BOOKING",
    BOSCH = "BOSCH",
    BOSTIK = "BOSTIK",
    BOSTON = "BOSTON",
    BOT = "BOT",
    BOUTIQUE = "BOUTIQUE",
    BOX = "BOX",
    BR = "BR",
    BRADESCO = "BRADESCO",
    BRIDGESTONE = "BRIDGESTONE",
    BROADWAY = "BROADWAY",
    BROKER = "BROKER",
    BROTHER = "BROTHER",
    BRUSSELS = "BRUSSELS",
    BS = "BS",
    BT = "BT",
    BUDAPEST = "BUDAPEST",
    BUGATTI = "BUGATTI",
    BUILD = "BUILD",
    BUILDERS = "BUILDERS",
    BUSINESS = "BUSINESS",
    BUY = "BUY",
    BUZZ = "BUZZ",
    BV = "BV",
    BW = "BW",
    BY = "BY",
    BZ = "BZ",
    BZH = "BZH",
    CA = "CA",
    CAB = "CAB",
    CAFE = "CAFE",
    CAL = "CAL",
    CALL = "CALL",
    CALVINKLEIN = "CALVINKLEIN",
    CAM = "CAM",
    CAMERA = "CAMERA",
    CAMP = "CAMP",
    CANCERRESEARCH = "CANCERRESEARCH",
    CANON = "CANON",
    CAPETOWN = "CAPETOWN",
    CAPITAL = "CAPITAL",
    CAPITALONE = "CAPITALONE",
    CAR = "CAR",
    CARAVAN = "CARAVAN",
    CARDS = "CARDS",
    CARE = "CARE",
    CAREER = "CAREER",
    CAREERS = "CAREERS",
    CARS = "CARS",
    CASA = "CASA",
    CASE = "CASE",
    CASH = "CASH",
    CASINO = "CASINO",
    CAT = "CAT",
    CATERING = "CATERING",
    CATHOLIC = "CATHOLIC",
    CBA = "CBA",
    CBN = "CBN",
    CBRE = "CBRE",
    CBS = "CBS",
    CC = "CC",
    CD = "CD",
    CENTER = "CENTER",
    CEO = "CEO",
    CERN = "CERN",
    CF = "CF",
    CFA = "CFA",
    CFD = "CFD",
    CG = "CG",
    CH = "CH",
    CHANEL = "CHANEL",
    CHANNEL = "CHANNEL",
    CHARITY = "CHARITY",
    CHASE = "CHASE",
    CHAT = "CHAT",
    CHEAP = "CHEAP",
    CHINTAI = "CHINTAI",
    CHRISTMAS = "CHRISTMAS",
    CHROME = "CHROME",
    CHURCH = "CHURCH",
    CI = "CI",
    CIPRIANI = "CIPRIANI",
    CIRCLE = "CIRCLE",
    CISCO = "CISCO",
    CITADEL = "CITADEL",
    CITI = "CITI",
    CITIC = "CITIC",
    CITY = "CITY",
    CITYEATS = "CITYEATS",
    CK = "CK",
    CL = "CL",
    CLAIMS = "CLAIMS",
    CLEANING = "CLEANING",
    CLICK = "CLICK",
    CLINIC = "CLINIC",
    CLINIQUE = "CLINIQUE",
    CLOTHING = "CLOTHING",
    CLOUD = "CLOUD",
    CLUB = "CLUB",
    CLUBMED = "CLUBMED",
    CM = "CM",
    CN = "CN",
    CO = "CO",
    COACH = "COACH",
    CODES = "CODES",
    COFFEE = "COFFEE",
    COLLEGE = "COLLEGE",
    COLOGNE = "COLOGNE",
    COM = "COM",
    COMCAST = "COMCAST",
    COMMBANK = "COMMBANK",
    COMMUNITY = "COMMUNITY",
    COMPANY = "COMPANY",
    COMPARE = "COMPARE",
    COMPUTER = "COMPUTER",
    COMSEC = "COMSEC",
    CONDOS = "CONDOS",
    CONSTRUCTION = "CONSTRUCTION",
    CONSULTING = "CONSULTING",
    CONTACT = "CONTACT",
    CONTRACTORS = "CONTRACTORS",
    COOKING = "COOKING",
    COOKINGCHANNEL = "COOKINGCHANNEL",
    COOL = "COOL",
    COOP = "COOP",
    CORSICA = "CORSICA",
    COUNTRY = "COUNTRY",
    COUPON = "COUPON",
    COUPONS = "COUPONS",
    COURSES = "COURSES",
    CPA = "CPA",
    CR = "CR",
    CREDIT = "CREDIT",
    CREDITCARD = "CREDITCARD",
    CREDITUNION = "CREDITUNION",
    CRICKET = "CRICKET",
    CROWN = "CROWN",
    CRS = "CRS",
    CRUISE = "CRUISE",
    CRUISES = "CRUISES",
    CSC = "CSC",
    CU = "CU",
    CUISINELLA = "CUISINELLA",
    CV = "CV",
    CW = "CW",
    CX = "CX",
    CY = "CY",
    CYMRU = "CYMRU",
    CYOU = "CYOU",
    CZ = "CZ",
    DABUR = "DABUR",
    DAD = "DAD",
    DANCE = "DANCE",
    DATA = "DATA",
    DATE = "DATE",
    DATING = "DATING",
    DATSUN = "DATSUN",
    DAY = "DAY",
    DCLK = "DCLK",
    DDS = "DDS",
    DE = "DE",
    DEAL = "DEAL",
    DEALER = "DEALER",
    DEALS = "DEALS",
    DEGREE = "DEGREE",
    DELIVERY = "DELIVERY",
    DELL = "DELL",
    DELOITTE = "DELOITTE",
    DELTA = "DELTA",
    DEMOCRAT = "DEMOCRAT",
    DENTAL = "DENTAL",
    DENTIST = "DENTIST",
    DESI = "DESI",
    DESIGN = "DESIGN",
    DEV = "DEV",
    DHL = "DHL",
    DIAMONDS = "DIAMONDS",
    DIET = "DIET",
    DIGITAL = "DIGITAL",
    DIRECT = "DIRECT",
    DIRECTORY = "DIRECTORY",
    DISCOUNT = "DISCOUNT",
    DISCOVER = "DISCOVER",
    DISH = "DISH",
    DIY = "DIY",
    DJ = "DJ",
    DK = "DK",
    DM = "DM",
    DNP = "DNP",
    DO = "DO",
    DOCS = "DOCS",
    DOCTOR = "DOCTOR",
    DOG = "DOG",
    DOMAINS = "DOMAINS",
    DOT = "DOT",
    DOWNLOAD = "DOWNLOAD",
    DRIVE = "DRIVE",
    DTV = "DTV",
    DUBAI = "DUBAI",
    DUCK = "DUCK",
    DUNLOP = "DUNLOP",
    DUPONT = "DUPONT",
    DURBAN = "DURBAN",
    DVAG = "DVAG",
    DVR = "DVR",
    DZ = "DZ",
    EARTH = "EARTH",
    EAT = "EAT",
    EC = "EC",
    ECO = "ECO",
    EDEKA = "EDEKA",
    EDU = "EDU",
    EDUCATION = "EDUCATION",
    EE = "EE",
    EG = "EG",
    EMAIL = "EMAIL",
    EMERCK = "EMERCK",
    ENERGY = "ENERGY",
    ENGINEER = "ENGINEER",
    ENGINEERING = "ENGINEERING",
    ENTERPRISES = "ENTERPRISES",
    EPSON = "EPSON",
    EQUIPMENT = "EQUIPMENT",
    ER = "ER",
    ERICSSON = "ERICSSON",
    ERNI = "ERNI",
    ES = "ES",
    ESQ = "ESQ",
    ESTATE = "ESTATE",
    ET = "ET",
    ETISALAT = "ETISALAT",
    EU = "EU",
    EUROVISION = "EUROVISION",
    EUS = "EUS",
    EVENTS = "EVENTS",
    EXCHANGE = "EXCHANGE",
    EXPERT = "EXPERT",
    EXPOSED = "EXPOSED",
    EXPRESS = "EXPRESS",
    EXTRASPACE = "EXTRASPACE",
    FAGE = "FAGE",
    FAIL = "FAIL",
    FAIRWINDS = "FAIRWINDS",
    FAITH = "FAITH",
    FAMILY = "FAMILY",
    FAN = "FAN",
    FANS = "FANS",
    FARM = "FARM",
    FARMERS = "FARMERS",
    FASHION = "FASHION",
    FAST = "FAST",
    FEDEX = "FEDEX",
    FEEDBACK = "FEEDBACK",
    FERRARI = "FERRARI",
    FERRERO = "FERRERO",
    FI = "FI",
    FIAT = "FIAT",
    FIDELITY = "FIDELITY",
    FIDO = "FIDO",
    FILM = "FILM",
    FINAL = "FINAL",
    FINANCE = "FINANCE",
    FINANCIAL = "FINANCIAL",
    FIRE = "FIRE",
    FIRESTONE = "FIRESTONE",
    FIRMDALE = "FIRMDALE",
    FISH = "FISH",
    FISHING = "FISHING",
    FIT = "FIT",
    FITNESS = "FITNESS",
    FJ = "FJ",
    FK = "FK",
    FLICKR = "FLICKR",
    FLIGHTS = "FLIGHTS",
    FLIR = "FLIR",
    FLORIST = "FLORIST",
    FLOWERS = "FLOWERS",
    FLY = "FLY",
    FM = "FM",
    FO = "FO",
    FOO = "FOO",
    FOOD = "FOOD",
    FOODNETWORK = "FOODNETWORK",
    FOOTBALL = "FOOTBALL",
    FORD = "FORD",
    FOREX = "FOREX",
    FORSALE = "FORSALE",
    FORUM = "FORUM",
    FOUNDATION = "FOUNDATION",
    FOX = "FOX",
    FR = "FR",
    FREE = "FREE",
    FRESENIUS = "FRESENIUS",
    FRL = "FRL",
    FROGANS = "FROGANS",
    FRONTDOOR = "FRONTDOOR",
    FRONTIER = "FRONTIER",
    FTR = "FTR",
    FUJITSU = "FUJITSU",
    FUN = "FUN",
    FUND = "FUND",
    FURNITURE = "FURNITURE",
    FUTBOL = "FUTBOL",
    FYI = "FYI",
    GA = "GA",
    GAL = "GAL",
    GALLERY = "GALLERY",
    GALLO = "GALLO",
    GALLUP = "GALLUP",
    GAME = "GAME",
    GAMES = "GAMES",
    GAP = "GAP",
    GARDEN = "GARDEN",
    GAY = "GAY",
    GB = "GB",
    GBIZ = "GBIZ",
    GD = "GD",
    GDN = "GDN",
    GE = "GE",
    GEA = "GEA",
    GENT = "GENT",
    GENTING = "GENTING",
    GEORGE = "GEORGE",
    GF = "GF",
    GG = "GG",
    GGEE = "GGEE",
    GH = "GH",
    GI = "GI",
    GIFT = "GIFT",
    GIFTS = "GIFTS",
    GIVES = "GIVES",
    GIVING = "GIVING",
    GL = "GL",
    GLADE = "GLADE",
    GLASS = "GLASS",
    GLE = "GLE",
    GLOBAL = "GLOBAL",
    GLOBO = "GLOBO",
    GM = "GM",
    GMAIL = "GMAIL",
    GMBH = "GMBH",
    GMO = "GMO",
    GMX = "GMX",
    GN = "GN",
    GODADDY = "GODADDY",
    GOLD = "GOLD",
    GOLDPOINT = "GOLDPOINT",
    GOLF = "GOLF",
    GOO = "GOO",
    GOODYEAR = "GOODYEAR",
    GOOG = "GOOG",
    GOOGLE = "GOOGLE",
    GOP = "GOP",
    GOT = "GOT",
    GOV = "GOV",
    GP = "GP",
    GQ = "GQ",
    GR = "GR",
    GRAINGER = "GRAINGER",
    GRAPHICS = "GRAPHICS",
    GRATIS = "GRATIS",
    GREEN = "GREEN",
    GRIPE = "GRIPE",
    GROCERY = "GROCERY",
    GROUP = "GROUP",
    GS = "GS",
    GT = "GT",
    GU = "GU",
    GUARDIAN = "GUARDIAN",
    GUCCI = "GUCCI",
    GUGE = "GUGE",
    GUIDE = "GUIDE",
    GUITARS = "GUITARS",
    GURU = "GURU",
    GW = "GW",
    GY = "GY",
    HAIR = "HAIR",
    HAMBURG = "HAMBURG",
    HANGOUT = "HANGOUT",
    HAUS = "HAUS",
    HBO = "HBO",
    HDFC = "HDFC",
    HDFCBANK = "HDFCBANK",
    HEALTH = "HEALTH",
    HEALTHCARE = "HEALTHCARE",
    HELP = "HELP",
    HELSINKI = "HELSINKI",
    HERE = "HERE",
    HERMES = "HERMES",
    HGTV = "HGTV",
    HIPHOP = "HIPHOP",
    HISAMITSU = "HISAMITSU",
    HITACHI = "HITACHI",
    HIV = "HIV",
    HK = "HK",
    HKT = "HKT",
    HM = "HM",
    HN = "HN",
    HOCKEY = "HOCKEY",
    HOLDINGS = "HOLDINGS",
    HOLIDAY = "HOLIDAY",
    HOMEDEPOT = "HOMEDEPOT",
    HOMEGOODS = "HOMEGOODS",
    HOMES = "HOMES",
    HOMESENSE = "HOMESENSE",
    HONDA = "HONDA",
    HORSE = "HORSE",
    HOSPITAL = "HOSPITAL",
    HOST = "HOST",
    HOSTING = "HOSTING",
    HOT = "HOT",
    HOTELES = "HOTELES",
    HOTELS = "HOTELS",
    HOTMAIL = "HOTMAIL",
    HOUSE = "HOUSE",
    HOW = "HOW",
    HR = "HR",
    HSBC = "HSBC",
    HT = "HT",
    HU = "HU",
    HUGHES = "HUGHES",
    HYATT = "HYATT",
    HYUNDAI = "HYUNDAI",
    IBM = "IBM",
    ICBC = "ICBC",
    ICE = "ICE",
    ICU = "ICU",
    ID = "ID",
    IE = "IE",
    IEEE = "IEEE",
    IFM = "IFM",
    IKANO = "IKANO",
    IL = "IL",
    IM = "IM",
    IMAMAT = "IMAMAT",
    IMDB = "IMDB",
    IMMO = "IMMO",
    IMMOBILIEN = "IMMOBILIEN",
    IN = "IN",
    INC = "INC",
    INDUSTRIES = "INDUSTRIES",
    INFINITI = "INFINITI",
    INFO = "INFO",
    ING = "ING",
    INK = "INK",
    INSTITUTE = "INSTITUTE",
    INSURANCE = "INSURANCE",
    INSURE = "INSURE",
    INT = "INT",
    INTERNATIONAL = "INTERNATIONAL",
    INTUIT = "INTUIT",
    INVESTMENTS = "INVESTMENTS",
    IO = "IO",
    IPIRANGA = "IPIRANGA",
    IQ = "IQ",
    IR = "IR",
    IRISH = "IRISH",
    IS = "IS",
    ISMAILI = "ISMAILI",
    IST = "IST",
    ISTANBUL = "ISTANBUL",
    IT = "IT",
    ITAU = "ITAU",
    ITV = "ITV",
    JAGUAR = "JAGUAR",
    JAVA = "JAVA",
    JCB = "JCB",
    JE = "JE",
    JEEP = "JEEP",
    JETZT = "JETZT",
    JEWELRY = "JEWELRY",
    JIO = "JIO",
    JLL = "JLL",
    JM = "JM",
    JMP = "JMP",
    JNJ = "JNJ",
    JO = "JO",
    JOBS = "JOBS",
    JOBURG = "JOBURG",
    JOT = "JOT",
    JOY = "JOY",
    JP = "JP",
    JPMORGAN = "JPMORGAN",
    JPRS = "JPRS",
    JUEGOS = "JUEGOS",
    JUNIPER = "JUNIPER",
    KAUFEN = "KAUFEN",
    KDDI = "KDDI",
    KE = "KE",
    KERRYHOTELS = "KERRYHOTELS",
    KERRYLOGISTICS = "KERRYLOGISTICS",
    KERRYPROPERTIES = "KERRYPROPERTIES",
    KFH = "KFH",
    KG = "KG",
    KH = "KH",
    KI = "KI",
    KIA = "KIA",
    KIM = "KIM",
    KINDER = "KINDER",
    KINDLE = "KINDLE",
    KITCHEN = "KITCHEN",
    KIWI = "KIWI",
    KM = "KM",
    KN = "KN",
    KOELN = "KOELN",
    KOMATSU = "KOMATSU",
    KOSHER = "KOSHER",
    KP = "KP",
    KPMG = "KPMG",
    KPN = "KPN",
    KR = "KR",
    KRD = "KRD",
    KRED = "KRED",
    KUOKGROUP = "KUOKGROUP",
    KW = "KW",
    KY = "KY",
    KYOTO = "KYOTO",
    KZ = "KZ",
    LA = "LA",
    LACAIXA = "LACAIXA",
    LAMBORGHINI = "LAMBORGHINI",
    LAMER = "LAMER",
    LANCASTER = "LANCASTER",
    LANCIA = "LANCIA",
    LAND = "LAND",
    LANDROVER = "LANDROVER",
    LANXESS = "LANXESS",
    LASALLE = "LASALLE",
    LAT = "LAT",
    LATINO = "LATINO",
    LATROBE = "LATROBE",
    LAW = "LAW",
    LAWYER = "LAWYER",
    LB = "LB",
    LC = "LC",
    LDS = "LDS",
    LEASE = "LEASE",
    LECLERC = "LECLERC",
    LEFRAK = "LEFRAK",
    LEGAL = "LEGAL",
    LEGO = "LEGO",
    LEXUS = "LEXUS",
    LGBT = "LGBT",
    LI = "LI",
    LIDL = "LIDL",
    LIFE = "LIFE",
    LIFEINSURANCE = "LIFEINSURANCE",
    LIFESTYLE = "LIFESTYLE",
    LIGHTING = "LIGHTING",
    LIKE = "LIKE",
    LILLY = "LILLY",
    LIMITED = "LIMITED",
    LIMO = "LIMO",
    LINCOLN = "LINCOLN",
    LINDE = "LINDE",
    LINK = "LINK",
    LIPSY = "LIPSY",
    LIVE = "LIVE",
    LIVING = "LIVING",
    LIXIL = "LIXIL",
    LK = "LK",
    LLC = "LLC",
    LLP = "LLP",
    LOAN = "LOAN",
    LOANS = "LOANS",
    LOCKER = "LOCKER",
    LOCUS = "LOCUS",
    LOFT = "LOFT",
    LOL = "LOL",
    LONDON = "LONDON",
    LOTTE = "LOTTE",
    LOTTO = "LOTTO",
    LOVE = "LOVE",
    LPL = "LPL",
    LPLFINANCIAL = "LPLFINANCIAL",
    LR = "LR",
    LS = "LS",
    LT = "LT",
    LTD = "LTD",
    LTDA = "LTDA",
    LU = "LU",
    LUNDBECK = "LUNDBECK",
    LUXE = "LUXE",
    LUXURY = "LUXURY",
    LV = "LV",
    LY = "LY",
    MA = "MA",
    MACYS = "MACYS",
    MADRID = "MADRID",
    MAIF = "MAIF",
    MAISON = "MAISON",
    MAKEUP = "MAKEUP",
    MAN = "MAN",
    MANAGEMENT = "MANAGEMENT",
    MANGO = "MANGO",
    MAP = "MAP",
    MARKET = "MARKET",
    MARKETING = "MARKETING",
    MARKETS = "MARKETS",
    MARRIOTT = "MARRIOTT",
    MARSHALLS = "MARSHALLS",
    MASERATI = "MASERATI",
    MATTEL = "MATTEL",
    MBA = "MBA",
    MC = "MC",
    MCKINSEY = "MCKINSEY",
    MD = "MD",
    ME = "ME",
    MED = "MED",
    MEDIA = "MEDIA",
    MEET = "MEET",
    MELBOURNE = "MELBOURNE",
    MEME = "MEME",
    MEMORIAL = "MEMORIAL",
    MEN = "MEN",
    MENU = "MENU",
    MERCKMSD = "MERCKMSD",
    MG = "MG",
    MH = "MH",
    MIAMI = "MIAMI",
    MICROSOFT = "MICROSOFT",
    MIL = "MIL",
    MINI = "MINI",
    MINT = "MINT",
    MIT = "MIT",
    MITSUBISHI = "MITSUBISHI",
    MK = "MK",
    ML = "ML",
    MLB = "MLB",
    MLS = "MLS",
    MM = "MM",
    MMA = "MMA",
    MN = "MN",
    MO = "MO",
    MOBI = "MOBI",
    MOBILE = "MOBILE",
    MODA = "MODA",
    MOE = "MOE",
    MOI = "MOI",
    MOM = "MOM",
    MONASH = "MONASH",
    MONEY = "MONEY",
    MONSTER = "MONSTER",
    MORMON = "MORMON",
    MORTGAGE = "MORTGAGE",
    MOSCOW = "MOSCOW",
    MOTO = "MOTO",
    MOTORCYCLES = "MOTORCYCLES",
    MOV = "MOV",
    MOVIE = "MOVIE",
    MP = "MP",
    MQ = "MQ",
    MR = "MR",
    MS = "MS",
    MSD = "MSD",
    MT = "MT",
    MTN = "MTN",
    MTR = "MTR",
    MU = "MU",
    MUSEUM = "MUSEUM",
    MUTUAL = "MUTUAL",
    MV = "MV",
    MW = "MW",
    MX = "MX",
    MY = "MY",
    MZ = "MZ",
    NA = "NA",
    NAB = "NAB",
    NAGOYA = "NAGOYA",
    NAME = "NAME",
    NATURA = "NATURA",
    NAVY = "NAVY",
    NBA = "NBA",
    NC = "NC",
    NE = "NE",
    NEC = "NEC",
    NET = "NET",
    NETBANK = "NETBANK",
    NETFLIX = "NETFLIX",
    NETWORK = "NETWORK",
    NEUSTAR = "NEUSTAR",
    NEW = "NEW",
    NEWS = "NEWS",
    NEXT = "NEXT",
    NEXTDIRECT = "NEXTDIRECT",
    NEXUS = "NEXUS",
    NF = "NF",
    NFL = "NFL",
    NG = "NG",
    NGO = "NGO",
    NHK = "NHK",
    NI = "NI",
    NICO = "NICO",
    NIKE = "NIKE",
    NIKON = "NIKON",
    NINJA = "NINJA",
    NISSAN = "NISSAN",
    NISSAY = "NISSAY",
    NL = "NL",
    NO = "NO",
    NOKIA = "NOKIA",
    NORTHWESTERNMUTUAL = "NORTHWESTERNMUTUAL",
    NORTON = "NORTON",
    NOW = "NOW",
    NOWRUZ = "NOWRUZ",
    NOWTV = "NOWTV",
    NP = "NP",
    NR = "NR",
    NRA = "NRA",
    NRW = "NRW",
    NTT = "NTT",
    NU = "NU",
    NYC = "NYC",
    NZ = "NZ",
    OBI = "OBI",
    OBSERVER = "OBSERVER",
    OFF = "OFF",
    OFFICE = "OFFICE",
    OKINAWA = "OKINAWA",
    OLAYAN = "OLAYAN",
    OLAYANGROUP = "OLAYANGROUP",
    OLDNAVY = "OLDNAVY",
    OLLO = "OLLO",
    OM = "OM",
    OMEGA = "OMEGA",
    ONE = "ONE",
    ONG = "ONG",
    ONL = "ONL",
    ONLINE = "ONLINE",
    OOO = "OOO",
    OPEN = "OPEN",
    ORACLE = "ORACLE",
    ORANGE = "ORANGE",
    ORG = "ORG",
    ORGANIC = "ORGANIC",
    ORIGINS = "ORIGINS",
    OSAKA = "OSAKA",
    OTSUKA = "OTSUKA",
    OTT = "OTT",
    OVH = "OVH",
    PA = "PA",
    PAGE = "PAGE",
    PANASONIC = "PANASONIC",
    PARIS = "PARIS",
    PARS = "PARS",
    PARTNERS = "PARTNERS",
    PARTS = "PARTS",
    PARTY = "PARTY",
    PASSAGENS = "PASSAGENS",
    PAY = "PAY",
    PCCW = "PCCW",
    PE = "PE",
    PET = "PET",
    PF = "PF",
    PFIZER = "PFIZER",
    PG = "PG",
    PH = "PH",
    PHARMACY = "PHARMACY",
    PHD = "PHD",
    PHILIPS = "PHILIPS",
    PHONE = "PHONE",
    PHOTO = "PHOTO",
    PHOTOGRAPHY = "PHOTOGRAPHY",
    PHOTOS = "PHOTOS",
    PHYSIO = "PHYSIO",
    PICS = "PICS",
    PICTET = "PICTET",
    PICTURES = "PICTURES",
    PID = "PID",
    PIN = "PIN",
    PING = "PING",
    PINK = "PINK",
    PIONEER = "PIONEER",
    PIZZA = "PIZZA",
    PK = "PK",
    PL = "PL",
    PLACE = "PLACE",
    PLAY = "PLAY",
    PLAYSTATION = "PLAYSTATION",
    PLUMBING = "PLUMBING",
    PLUS = "PLUS",
    PM = "PM",
    PN = "PN",
    PNC = "PNC",
    POHL = "POHL",
    POKER = "POKER",
    POLITIE = "POLITIE",
    PORN = "PORN",
    POST = "POST",
    PR = "PR",
    PRAMERICA = "PRAMERICA",
    PRAXI = "PRAXI",
    PRESS = "PRESS",
    PRIME = "PRIME",
    PRO = "PRO",
    PROD = "PROD",
    PRODUCTIONS = "PRODUCTIONS",
    PROF = "PROF",
    PROGRESSIVE = "PROGRESSIVE",
    PROMO = "PROMO",
    PROPERTIES = "PROPERTIES",
    PROPERTY = "PROPERTY",
    PROTECTION = "PROTECTION",
    PRU = "PRU",
    PRUDENTIAL = "PRUDENTIAL",
    PS = "PS",
    PT = "PT",
    PUB = "PUB",
    PW = "PW",
    PWC = "PWC",
    PY = "PY",
    QA = "QA",
    QPON = "QPON",
    QUEBEC = "QUEBEC",
    QUEST = "QUEST",
    RACING = "RACING",
    RADIO = "RADIO",
    RAID = "RAID",
    RE = "RE",
    READ = "READ",
    REALESTATE = "REALESTATE",
    REALTOR = "REALTOR",
    REALTY = "REALTY",
    RECIPES = "RECIPES",
    RED = "RED",
    REDSTONE = "REDSTONE",
    REDUMBRELLA = "REDUMBRELLA",
    REHAB = "REHAB",
    REISE = "REISE",
    REISEN = "REISEN",
    REIT = "REIT",
    RELIANCE = "RELIANCE",
    REN = "REN",
    RENT = "RENT",
    RENTALS = "RENTALS",
    REPAIR = "REPAIR",
    REPORT = "REPORT",
    REPUBLICAN = "REPUBLICAN",
    REST = "REST",
    RESTAURANT = "RESTAURANT",
    REVIEW = "REVIEW",
    REVIEWS = "REVIEWS",
    REXROTH = "REXROTH",
    RICH = "RICH",
    RICHARDLI = "RICHARDLI",
    RICOH = "RICOH",
    RIL = "RIL",
    RIO = "RIO",
    RIP = "RIP",
    RO = "RO",
    ROCHER = "ROCHER",
    ROCKS = "ROCKS",
    RODEO = "RODEO",
    ROGERS = "ROGERS",
    ROOM = "ROOM",
    RS = "RS",
    RSVP = "RSVP",
    RU = "RU",
    RUGBY = "RUGBY",
    RUHR = "RUHR",
    RUN = "RUN",
    RW = "RW",
    RWE = "RWE",
    RYUKYU = "RYUKYU",
    SA = "SA",
    SAARLAND = "SAARLAND",
    SAFE = "SAFE",
    SAFETY = "SAFETY",
    SAKURA = "SAKURA",
    SALE = "SALE",
    SALON = "SALON",
    SAMSCLUB = "SAMSCLUB",
    SAMSUNG = "SAMSUNG",
    SANDVIK = "SANDVIK",
    SANDVIKCOROMANT = "SANDVIKCOROMANT",
    SANOFI = "SANOFI",
    SAP = "SAP",
    SARL = "SARL",
    SAS = "SAS",
    SAVE = "SAVE",
    SAXO = "SAXO",
    SB = "SB",
    SBI = "SBI",
    SBS = "SBS",
    SC = "SC",
    SCA = "SCA",
    SCB = "SCB",
    SCHAEFFLER = "SCHAEFFLER",
    SCHMIDT = "SCHMIDT",
    SCHOLARSHIPS = "SCHOLARSHIPS",
    SCHOOL = "SCHOOL",
    SCHULE = "SCHULE",
    SCHWARZ = "SCHWARZ",
    SCIENCE = "SCIENCE",
    SCJOHNSON = "SCJOHNSON",
    SCOT = "SCOT",
    SD = "SD",
    SE = "SE",
    SEARCH = "SEARCH",
    SEAT = "SEAT",
    SECURE = "SECURE",
    SECURITY = "SECURITY",
    SEEK = "SEEK",
    SELECT = "SELECT",
    SENER = "SENER",
    SERVICES = "SERVICES",
    SES = "SES",
    SEVEN = "SEVEN",
    SEW = "SEW",
    SEX = "SEX",
    SEXY = "SEXY",
    SFR = "SFR",
    SG = "SG",
    SH = "SH",
    SHANGRILA = "SHANGRILA",
    SHARP = "SHARP",
    SHAW = "SHAW",
    SHELL = "SHELL",
    SHIA = "SHIA",
    SHIKSHA = "SHIKSHA",
    SHOES = "SHOES",
    SHOP = "SHOP",
    SHOPPING = "SHOPPING",
    SHOUJI = "SHOUJI",
    SHOW = "SHOW",
    SHOWTIME = "SHOWTIME",
    SI = "SI",
    SILK = "SILK",
    SINA = "SINA",
    SINGLES = "SINGLES",
    SITE = "SITE",
    SJ = "SJ",
    SK = "SK",
    SKI = "SKI",
    SKIN = "SKIN",
    SKY = "SKY",
    SKYPE = "SKYPE",
    SL = "SL",
    SLING = "SLING",
    SM = "SM",
    SMART = "SMART",
    SMILE = "SMILE",
    SN = "SN",
    SNCF = "SNCF",
    SO = "SO",
    SOCCER = "SOCCER",
    SOCIAL = "SOCIAL",
    SOFTBANK = "SOFTBANK",
    SOFTWARE = "SOFTWARE",
    SOHU = "SOHU",
    SOLAR = "SOLAR",
    SOLUTIONS = "SOLUTIONS",
    SONG = "SONG",
    SONY = "SONY",
    SOY = "SOY",
    SPA = "SPA",
    SPACE = "SPACE",
    SPORT = "SPORT",
    SPOT = "SPOT",
    SR = "SR",
    SRL = "SRL",
    SS = "SS",
    ST = "ST",
    STADA = "STADA",
    STAPLES = "STAPLES",
    STAR = "STAR",
    STATEBANK = "STATEBANK",
    STATEFARM = "STATEFARM",
    STC = "STC",
    STCGROUP = "STCGROUP",
    STOCKHOLM = "STOCKHOLM",
    STORAGE = "STORAGE",
    STORE = "STORE",
    STREAM = "STREAM",
    STUDIO = "STUDIO",
    STUDY = "STUDY",
    STYLE = "STYLE",
    SU = "SU",
    SUCKS = "SUCKS",
    SUPPLIES = "SUPPLIES",
    SUPPLY = "SUPPLY",
    SUPPORT = "SUPPORT",
    SURF = "SURF",
    SURGERY = "SURGERY",
    SUZUKI = "SUZUKI",
    SV = "SV",
    SWATCH = "SWATCH",
    SWISS = "SWISS",
    SX = "SX",
    SY = "SY",
    SYDNEY = "SYDNEY",
    SYSTEMS = "SYSTEMS",
    SZ = "SZ",
    TAB = "TAB",
    TAIPEI = "TAIPEI",
    TALK = "TALK",
    TAOBAO = "TAOBAO",
    TARGET = "TARGET",
    TATAMOTORS = "TATAMOTORS",
    TATAR = "TATAR",
    TATTOO = "TATTOO",
    TAX = "TAX",
    TAXI = "TAXI",
    TC = "TC",
    TCI = "TCI",
    TD = "TD",
    TDK = "TDK",
    TEAM = "TEAM",
    TECH = "TECH",
    TECHNOLOGY = "TECHNOLOGY",
    TEL = "TEL",
    TEMASEK = "TEMASEK",
    TENNIS = "TENNIS",
    TEVA = "TEVA",
    TF = "TF",
    TG = "TG",
    TH = "TH",
    THD = "THD",
    THEATER = "THEATER",
    THEATRE = "THEATRE",
    TIAA = "TIAA",
    TICKETS = "TICKETS",
    TIENDA = "TIENDA",
    TIFFANY = "TIFFANY",
    TIPS = "TIPS",
    TIRES = "TIRES",
    TIROL = "TIROL",
    TJ = "TJ",
    TJMAXX = "TJMAXX",
    TJX = "TJX",
    TK = "TK",
    TKMAXX = "TKMAXX",
    TL = "TL",
    TM = "TM",
    TMALL = "TMALL",
    TN = "TN",
    TO = "TO",
    TODAY = "TODAY",
    TOKYO = "TOKYO",
    TOOLS = "TOOLS",
    TOP = "TOP",
    TORAY = "TORAY",
    TOSHIBA = "TOSHIBA",
    TOTAL = "TOTAL",
    TOURS = "TOURS",
    TOWN = "TOWN",
    TOYOTA = "TOYOTA",
    TOYS = "TOYS",
    TR = "TR",
    TRADE = "TRADE",
    TRADING = "TRADING",
    TRAINING = "TRAINING",
    TRAVEL = "TRAVEL",
    TRAVELCHANNEL = "TRAVELCHANNEL",
    TRAVELERS = "TRAVELERS",
    TRAVELERSINSURANCE = "TRAVELERSINSURANCE",
    TRUST = "TRUST",
    TRV = "TRV",
    TT = "TT",
    TUBE = "TUBE",
    TUI = "TUI",
    TUNES = "TUNES",
    TUSHU = "TUSHU",
    TV = "TV",
    TVS = "TVS",
    TW = "TW",
    TZ = "TZ",
    UA = "UA",
    UBANK = "UBANK",
    UBS = "UBS",
    UG = "UG",
    UK = "UK",
    UNICOM = "UNICOM",
    UNIVERSITY = "UNIVERSITY",
    UNO = "UNO",
    UOL = "UOL",
    UPS = "UPS",
    US = "US",
    UY = "UY",
    UZ = "UZ",
    VA = "VA",
    VACATIONS = "VACATIONS",
    VANA = "VANA",
    VANGUARD = "VANGUARD",
    VC = "VC",
    VE = "VE",
    VEGAS = "VEGAS",
    VENTURES = "VENTURES",
    VERISIGN = "VERISIGN",
    VERSICHERUNG = "VERSICHERUNG",
    VET = "VET",
    VG = "VG",
    VI = "VI",
    VIAJES = "VIAJES",
    VIDEO = "VIDEO",
    VIG = "VIG",
    VIKING = "VIKING",
    VILLAS = "VILLAS",
    VIN = "VIN",
    VIP = "VIP",
    VIRGIN = "VIRGIN",
    VISA = "VISA",
    VISION = "VISION",
    VIVA = "VIVA",
    VIVO = "VIVO",
    VLAANDEREN = "VLAANDEREN",
    VN = "VN",
    VODKA = "VODKA",
    VOLKSWAGEN = "VOLKSWAGEN",
    VOLVO = "VOLVO",
    VOTE = "VOTE",
    VOTING = "VOTING",
    VOTO = "VOTO",
    VOYAGE = "VOYAGE",
    VU = "VU",
    VUELOS = "VUELOS",
    WALES = "WALES",
    WALMART = "WALMART",
    WALTER = "WALTER",
    WANG = "WANG",
    WANGGOU = "WANGGOU",
    WATCH = "WATCH",
    WATCHES = "WATCHES",
    WEATHER = "WEATHER",
    WEATHERCHANNEL = "WEATHERCHANNEL",
    WEBCAM = "WEBCAM",
    WEBER = "WEBER",
    WEBSITE = "WEBSITE",
    WED = "WED",
    WEDDING = "WEDDING",
    WEIBO = "WEIBO",
    WEIR = "WEIR",
    WF = "WF",
    WHOSWHO = "WHOSWHO",
    WIEN = "WIEN",
    WIKI = "WIKI",
    WILLIAMHILL = "WILLIAMHILL",
    WIN = "WIN",
    WINDOWS = "WINDOWS",
    WINE = "WINE",
    WINNERS = "WINNERS",
    WME = "WME",
    WOLTERSKLUWER = "WOLTERSKLUWER",
    WOODSIDE = "WOODSIDE",
    WORK = "WORK",
    WORKS = "WORKS",
    WORLD = "WORLD",
    WOW = "WOW",
    WS = "WS",
    WTC = "WTC",
    WTF = "WTF",
    XBOX = "XBOX",
    XEROX = "XEROX",
    XFINITY = "XFINITY",
    XIHUAN = "XIHUAN",
    XIN = "XIN",
    XN11B4C3D = "XN--11B4C3D",
    XN1CK2E1B = "XN--1CK2E1B",
    XN1QQW23A = "XN--1QQW23A",
    XN2SCRJ9C = "XN--2SCRJ9C",
    XN30RR7Y = "XN--30RR7Y",
    XN3BST00M = "XN--3BST00M",
    XN3DS443G = "XN--3DS443G",
    XN3E0B707E = "XN--3E0B707E",
    XN3HCRJ9C = "XN--3HCRJ9C",
    XN3OQ18VL8PN36A = "XN--3OQ18VL8PN36A",
    XN3PXU8K = "XN--3PXU8K",
    XN42C2D9A = "XN--42C2D9A",
    XN45BR5CYL = "XN--45BR5CYL",
    XN45BRJ9C = "XN--45BRJ9C",
    XN45Q11C = "XN--45Q11C",
    XN4DBRK0CE = "XN--4DBRK0CE",
    XN4GBRIM = "XN--4GBRIM",
    XN54B7FTA0CC = "XN--54B7FTA0CC",
    XN55QW42G = "XN--55QW42G",
    XN55QX5D = "XN--55QX5D",
    XN5SU34J936BGSG = "XN--5SU34J936BGSG",
    XN5TZM5G = "XN--5TZM5G",
    XN6FRZ82G = "XN--6FRZ82G",
    XN6QQ986B3XL = "XN--6QQ986B3XL",
    XN80ADXHKS = "XN--80ADXHKS",
    XN80AO21A = "XN--80AO21A",
    XN80AQECDR1A = "XN--80AQECDR1A",
    XN80ASEHDB = "XN--80ASEHDB",
    XN80ASWG = "XN--80ASWG",
    XN8Y0A063A = "XN--8Y0A063A",
    XN90A3AC = "XN--90A3AC",
    XN90AE = "XN--90AE",
    XN90AIS = "XN--90AIS",
    XN9DBQ2A = "XN--9DBQ2A",
    XN9ET52U = "XN--9ET52U",
    XN9KRT00A = "XN--9KRT00A",
    XNB4W605FERD = "XN--B4W605FERD",
    XNBCK1B9A5DRE4C = "XN--BCK1B9A5DRE4C",
    XNC1AVG = "XN--C1AVG",
    XNC2BR7G = "XN--C2BR7G",
    XNCCK2B3B = "XN--CCK2B3B",
    XNCCKWCXETD = "XN--CCKWCXETD",
    XNCG4BKI = "XN--CG4BKI",
    XNCLCHC0EA0B2G2A9GCD = "XN--CLCHC0EA0B2G2A9GCD",
    XNCZR694B = "XN--CZR694B",
    XNCZRS0T = "XN--CZRS0T",
    XNCZRU2D = "XN--CZRU2D",
    XND1ACJ3B = "XN--D1ACJ3B",
    XND1ALF = "XN--D1ALF",
    XNE1A4C = "XN--E1A4C",
    XNECKVDTC9D = "XN--ECKVDTC9D",
    XNEFVY88H = "XN--EFVY88H",
    XNFCT429K = "XN--FCT429K",
    XNFHBEI = "XN--FHBEI",
    XNFIQ228C5HS = "XN--FIQ228C5HS",
    XNFIQ64B = "XN--FIQ64B",
    XNFIQS8S = "XN--FIQS8S",
    XNFIQZ9S = "XN--FIQZ9S",
    XNFJQ720A = "XN--FJQ720A",
    XNFLW351E = "XN--FLW351E",
    XNFPCRJ9C3D = "XN--FPCRJ9C3D",
    XNFZC2C9E2C = "XN--FZC2C9E2C",
    XNFZYS8D69UVGM = "XN--FZYS8D69UVGM",
    XNG2XX48C = "XN--G2XX48C",
    XNGCKR3F0F = "XN--GCKR3F0F",
    XNGECRJ9C = "XN--GECRJ9C",
    XNGK3AT1E = "XN--GK3AT1E",
    XNH2BREG3EVE = "XN--H2BREG3EVE",
    XNH2BRJ9C = "XN--H2BRJ9C",
    XNH2BRJ9C8C = "XN--H2BRJ9C8C",
    XNHXT814E = "XN--HXT814E",
    XNI1B6B1A6A2E = "XN--I1B6B1A6A2E",
    XNIMR513N = "XN--IMR513N",
    XNIO0A7I = "XN--IO0A7I",
    XNJ1AEF = "XN--J1AEF",
    XNJ1AMH = "XN--J1AMH",
    XNJ6W193G = "XN--J6W193G",
    XNJLQ480N2RG = "XN--JLQ480N2RG",
    XNJLQ61U9W7B = "XN--JLQ61U9W7B",
    XNJVR189M = "XN--JVR189M",
    XNKCRX77D1X4A = "XN--KCRX77D1X4A",
    XNKPRW13D = "XN--KPRW13D",
    XNKPRY57D = "XN--KPRY57D",
    XNKPUT3I = "XN--KPUT3I",
    XNL1ACC = "XN--L1ACC",
    XNLGBBAT1AD8J = "XN--LGBBAT1AD8J",
    XNMGB9AWBF = "XN--MGB9AWBF",
    XNMGBA3A3EJT = "XN--MGBA3A3EJT",
    XNMGBA3A4F16A = "XN--MGBA3A4F16A",
    XNMGBA7C0BBN0A = "XN--MGBA7C0BBN0A",
    XNMGBAAKC7DVF = "XN--MGBAAKC7DVF",
    XNMGBAAM7A8H = "XN--MGBAAM7A8H",
    XNMGBAB2BD = "XN--MGBAB2BD",
    XNMGBAH1A3HJKRD = "XN--MGBAH1A3HJKRD",
    XNMGBAI9AZGQP6J = "XN--MGBAI9AZGQP6J",
    XNMGBAYH7GPA = "XN--MGBAYH7GPA",
    XNMGBBH1A = "XN--MGBBH1A",
    XNMGBBH1A71E = "XN--MGBBH1A71E",
    XNMGBC0A9AZCG = "XN--MGBC0A9AZCG",
    XNMGBCA7DZDO = "XN--MGBCA7DZDO",
    XNMGBCPQ6GPA1A = "XN--MGBCPQ6GPA1A",
    XNMGBERP4A5D4AR = "XN--MGBERP4A5D4AR",
    XNMGBGU82A = "XN--MGBGU82A",
    XNMGBI4ECEXP = "XN--MGBI4ECEXP",
    XNMGBPL2FH = "XN--MGBPL2FH",
    XNMGBT3DHD = "XN--MGBT3DHD",
    XNMGBTX2B = "XN--MGBTX2B",
    XNMGBX4CD0AB = "XN--MGBX4CD0AB",
    XNMIX891F = "XN--MIX891F",
    XNMK1BU44C = "XN--MK1BU44C",
    XNMXTQ1M = "XN--MXTQ1M",
    XNNGBC5AZD = "XN--NGBC5AZD",
    XNNGBE9E0A = "XN--NGBE9E0A",
    XNNGBRX = "XN--NGBRX",
    XNNODE = "XN--NODE",
    XNNQV7F = "XN--NQV7F",
    XNNQV7FS00EMA = "XN--NQV7FS00EMA",
    XNNYQY26A = "XN--NYQY26A",
    XNO3CW4H = "XN--O3CW4H",
    XNOGBPF8FL = "XN--OGBPF8FL",
    XNOTU796D = "XN--OTU796D",
    XNP1ACF = "XN--P1ACF",
    XNP1AI = "XN--P1AI",
    XNPGBS0DH = "XN--PGBS0DH",
    XNPSSY2U = "XN--PSSY2U",
    XNQ7CE6A = "XN--Q7CE6A",
    XNQ9JYB4C = "XN--Q9JYB4C",
    XNQCKA1PMC = "XN--QCKA1PMC",
    XNQXA6A = "XN--QXA6A",
    XNQXAM = "XN--QXAM",
    XNRHQV96G = "XN--RHQV96G",
    XNROVU88B = "XN--ROVU88B",
    XNRVC1E0AM3E = "XN--RVC1E0AM3E",
    XNS9BRJ9C = "XN--S9BRJ9C",
    XNSES554G = "XN--SES554G",
    XNT60B56A = "XN--T60B56A",
    XNTCKWE = "XN--TCKWE",
    XNTIQ49XQYJ = "XN--TIQ49XQYJ",
    XNUNUP4Y = "XN--UNUP4Y",
    XNVERMGENSBERATERCTB = "XN--VERMGENSBERATER-CTB",
    XNVERMGENSBERATUNGPWB = "XN--VERMGENSBERATUNG-PWB",
    XNVHQUV = "XN--VHQUV",
    XNVUQ861B = "XN--VUQ861B",
    XNW4R85EL8FHU5DNRA = "XN--W4R85EL8FHU5DNRA",
    XNW4RS40L = "XN--W4RS40L",
    XNWGBH1C = "XN--WGBH1C",
    XNWGBL6A = "XN--WGBL6A",
    XNXHQ521B = "XN--XHQ521B",
    XNXKC2AL3HYE2A = "XN--XKC2AL3HYE2A",
    XNXKC2DL3A5EE0H = "XN--XKC2DL3A5EE0H",
    XNY9A3AQ = "XN--Y9A3AQ",
    XNYFRO4I67O = "XN--YFRO4I67O",
    XNYGBI2AMMX = "XN--YGBI2AMMX",
    XNZFR164B = "XN--ZFR164B",
    XXX = "XXX",
    XYZ = "XYZ",
    YACHTS = "YACHTS",
    YAHOO = "YAHOO",
    YAMAXUN = "YAMAXUN",
    YANDEX = "YANDEX",
    YE = "YE",
    YODOBASHI = "YODOBASHI",
    YOGA = "YOGA",
    YOKOHAMA = "YOKOHAMA",
    YOU = "YOU",
    YOUTUBE = "YOUTUBE",
    YT = "YT",
    YUN = "YUN",
    ZA = "ZA",
    ZAPPOS = "ZAPPOS",
    ZARA = "ZARA",
    ZERO = "ZERO",
    ZIP = "ZIP",
    ZM = "ZM",
    ZONE = "ZONE",
    ZUERICH = "ZUERICH",
    ZW = "ZW"
}

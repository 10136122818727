import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DokuPay } from './../../../_model/payment-model';

@Component({
  selector: 'app-paymentform-redirect',
  templateUrl: './paymentform-redirect.component.html',
  styleUrls: ['./paymentform-redirect.component.scss']
})
export class FormRedirectComponent implements OnInit, OnChanges {
  @ViewChild('formRedirect') formRef !: ElementRef;
  @Input() paymentData !: DokuPay;
  formGroup !: FormGroup;
  changes !: any;
  controlGroup = <any> {};
  metaData = <any> {};

  constructor(
    public fb: FormBuilder,
    public ref: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.paymentData.currentValue) {
      this.initializeFormGroup().then(() => {
        const form = this.formRef.nativeElement as HTMLFormElement;
        form.submit();
      });
    }
  }

  ngOnInit(): void {
    // Intentionally Empty
  }

  public async initializeFormGroup(): Promise<void> {
    this.metaData = this.paymentData.paymentGatewayLink.metaData;

    Object.keys(this.metaData).forEach((key) => {
      this.controlGroup[key] = new FormControl(this.metaData[key] || null);
    });
    this.formGroup = this.fb.group(this.controlGroup);
    this.ref.detectChanges();
  }
}

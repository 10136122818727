import { Component, OnInit } from '@angular/core';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { DbsRedirectionService } from 'src/app/_shared/dbs-redirection.service';

@Component({
  selector: 'app-data-error',
  templateUrl: './data-error.component.html',
  styleUrls: ['./data-error.component.scss']
})
export class DataErrorComponent implements OnInit {
  errorMsg = '';
  MohonMaafClicked = false;
  config: CdsIconConfig = {
    size: 'md',
    color: 'default',
  };

  buttonConfig: CdsButtonConfig = {
    label: 'Kembali ke digibank',
    buttonType: 'button',
    style: 'primary'
  };

  constructor(
    private _dbsshared: DbsRedirectionService
  ) {
    if (sessionStorage.getItem('current-error') === null) {
      this.errorMsg = history.state.msg;
      sessionStorage.setItem('current-error', JSON.stringify(this.errorMsg));
    } else {
      this.errorMsg = sessionStorage.getItem('current-error') || '';
    }
  }

  ngOnInit(): void {
    // This is intentional
    this.errorMsg = sessionStorage.getItem('Error Status') || 'No Error Logs';
  }

  onBackClicked(): void {
    sessionStorage.clear();
    this._dbsshared.navigateToDashboard();
  }

  isMohonMaafClicked(): void {
    this.MohonMaafClicked = false;
  }
}

import { HttpHeaders, HttpInterceptor } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  constructor(public injector: Injector) { }

  intercept(req: any, next: any): any {
    let headers = new HttpHeaders({
      'Content-type': 'application/json',
      'X-Content-Type-Options': 'nosniff',
      'Cache-Control': 'public, max-age=63072000;',
      'X-XSS-Protection': '1; mode=block'
    });
    const tokenizedReq = req.clone({
      headers
    });
    return next.handle(tokenizedReq);
  }
}

import { Component, LOCALE_ID, OnInit, OnDestroy } from '@angular/core';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { registerLocaleData, DatePipe } from '@angular/common';
import localeId from '@angular/common/locales/id';
import { SharedService } from '../_shared/shared.service';
import { Subject } from 'rxjs';
import { DbsRedirectionService } from '../_shared/dbs-redirection.service';
import { MliAnalyticsService } from '@mli/analytics';
import { GeneralService } from '../_services/general.service';
import { takeUntil } from 'rxjs/operators';
import { ErrorServicesService } from '../_services/error-services.service';
import { CustomerInfoDetails } from '../_model/customer-info-details.model';
registerLocaleData(localeId, 'id');

@Component({
  selector: 'app-completion',
  templateUrl: './completion.component.html',
  styleUrls: ['./completion.component.scss'],
  providers: [{ provide: LOCALE_ID, useValue: 'id-ID' }],
})
export class CompletionComponent implements OnInit, OnDestroy {
  savedId: string = JSON.parse(sessionStorage.getItem('CustomerDetails') || '{}').savedId;
  productComputation: any = {};
  backDigibank: CdsButtonConfig = {
    label: 'Kembali ke digibank',
    buttonType: 'button',
    style: 'secondary',
    size: 'sm'
  };
  currentDate: any = new Date();
  dueDate: any = new Date();
  policyDetails: any = {
    policyNumber: sessionStorage.getItem('policyId')
  };

  model: any = {};
  paymentSession: any = {};
  paymentStatus: any = {};
  destroySub = new Subject();
  paymentCompleted = false;
  errStat = '';
  customerDetails: any = {};
  dbsParams: any = {};
  customerInfoDetailsReq: CustomerInfoDetails = new CustomerInfoDetails();


  constructor(
    private datePipe: DatePipe,
    private _shared: SharedService,
    private _service: GeneralService,
    private _dbsshared: DbsRedirectionService,
    private mliAnalyticsService: MliAnalyticsService,
    private _error: ErrorServicesService
  ) {
    this._shared?.implementTimeout(false, this.destroySub);
  }

  ngOnInit(): void {
    console.log('Completion Page is Called');
    this.customerInfoDetailsReq = {
      fields: ['customerEmail', 'premiumAmount', 'customerID', 'adobeMarketingCloudId', 'adobeId']
    };
    try {
      this._service.findById(this.savedId, this.customerInfoDetailsReq)
        .pipe(takeUntil(this.destroySub))
        .subscribe(response => {
          this.customerDetails = {
            email: response.customerEmail ? response.customerEmail : '-'
          };
          this.productComputation = {
            firstValue: response.premiumAmount ? this.formatValue(response.premiumAmount) : '-'
          };
          this.dbsParams = {
            adobeId: response.adobeId,
            marketingId: response.adobeMarketingCloudId,
            authCode: response.customerID
          };
        }, (error: any) => {
          this._error.handleError(error, {
            details: '/v1/IDN/customer-info/customerId Error: ' + error.message,
            secondary_details: 'API Payload: ' + this.savedId
          });
        });
    } catch (e) {
      console.log('ngOnInit: /v1/IDN/customer-info/customerId Error Response ==> ', e);
    }

    sessionStorage.setItem('paymentCompleted', 'true');

    const newYear = +(this._shared?.getDueDate(this.currentDate, this.datePipe)[2])!;
    console.log('ngOnInit: getDueDate Due Date Year ==> ', newYear);
    const newMonth = +(this._shared?.getDueDate(this.currentDate, this.datePipe)[1])! - 1;
    console.log('ngOnInit: getDueDate Due Date Month ==> ', newMonth);
    const newDay = +(this._shared?.getDueDate(this.currentDate, this.datePipe)[0])!;
    console.log('ngOnInit: getDueDate Due Date Day ==> ', newDay);

    this.dueDate = new Date(newYear, newMonth, newDay) instanceof Date ? new Date(newYear, newMonth, newDay) : new Date();
    console.log('ngOnInit: getDueDate Due Date ==> ', this.dueDate);
  }

  backToDigibank(): any {
    this.mliAnalyticsService?.trackEvent({
      event_label: 'Digibank Page',
      event_category: 'Succeed Page',
      event_type: 'Click button_back to Digibank',
      dbs_adobe_id: this.dbsParams.adobeId,
      dbs_marketing_id: this.dbsParams.marketingId,
      dbs_authcode: this.dbsParams.authCode
    });
    console.log('backToDigibank: backToDigibank is Called');
    sessionStorage.clear();
    this._dbsshared.navigateToDashboard();
  }

  ngOnDestroy(): void {
    console.log('ngOnDestroy: ngOnDestroy is Called');
    this.destroySub.next();
    this.destroySub.complete();
  }

  formatValue(value: number): string {
    return 'Rp ' + ('' + value).replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }
}

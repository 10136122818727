import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { BnNgIdleService } from 'bn-ng-idle';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RemainingTimeComponent } from '../_modal/remaining-time/remaining-time.component';
import { EligibilityInfo } from '../_model/eligibility-info.model';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private subject = new Subject<any>();

  constructor(
    private bnIdle: BnNgIdleService,
    private cdsPopup: CdsPopupService
  ) {
    // This is intentional
  }

  setData(data: number): void {
    this.subject.next(data);
  }
  getData(): Observable<any> {
    return this.subject.asObservable();
  }

  reformatValue(value: string): number {
    let newValue = value?.split(',');
    if (newValue?.length > 1) {
      const wholeValue = newValue[0]?.split(/[\s.]+/);
      let firstValue = '';
      if (wholeValue[0] === 'Rp') {
        wholeValue.shift();
        firstValue = wholeValue.join('');
      } else {
        firstValue = wholeValue.join('');
      }

      const decimalValue = newValue[1]?.split('');
      if (+(decimalValue[0]) >= 5) {
        firstValue = '' + (+(firstValue) + 1);
        return +(firstValue);
      } else {
        return +(firstValue);
      }
    } else {
      if (value) {
        newValue = value?.split(/[\s.]+/);
        if (newValue[0] === 'Rp') {
          newValue.shift();
          return +(newValue.join(''));
        } else {
          return +(newValue.join(''));
        }
      } else {
        return 0;
      }
    }
  }

  getDueDate(currentDate: Date, datePipe: DatePipe): any {
    let day = (datePipe.transform(currentDate, 'dd/MM/yyyy'))?.split('/')[0];
    let month = (datePipe.transform(currentDate, 'dd/MM/yyyy'))?.split('/')[1];
    let year = (datePipe.transform(currentDate, 'dd/MM/yyyy'))?.split('/')[2];

    year = +(month!) === 12 ? '' + (+(year!) + 1) : year;
    month = +(month!) === 12 ? '1' : '' + (+(month!) + 1);
    day = (+(day!) === 29) || (+(day!) === 31) ? '30' : day;

    return [day, month, year];
  }

  implementTimeout(hasTimeoutOpen: boolean, destroySub: any): void {
    this.bnIdle.startWatching(240).subscribe((res) => {
      if (res && hasTimeoutOpen === false) {
        hasTimeoutOpen = true;
        const popupRef = this.cdsPopup?.open(RemainingTimeComponent, {
          size: 'md',
          data: {
            name: 'John Doe',
          },
        });

        popupRef?.afterClosed().pipe(takeUntil(destroySub)).subscribe((data: any) => {
          if (data.agree === true) {
            hasTimeoutOpen = false;
          }
        });
      }
    });
  }

  maskKtpAddress(ktpNumber: string): string {
    return ktpNumber.slice(0, 6) + '******' + ktpNumber.slice(ktpNumber?.length - 3, ktpNumber?.length);
  }

  initializedData(customerInfo: any, model: any): void {
    console.log('initializedData: initializedData is Called');
    try {
      customerInfo.customerId = model.customerID;
      customerInfo.msgId = model.msgId;
      customerInfo.timeStamp = model.timeStamp;
      customerInfo.fullName = model.fullName;
      customerInfo.aliasName = model.fullName;
      customerInfo.dob = this.formatDate(model.dob);
      customerInfo.gender = model.gender;
      customerInfo.nationalId = model.nationalId;
      customerInfo.nationality = model.nationality;
      customerInfo.email = model.customerEmail;
      customerInfo.addressStreet1 = this.formatAddress(model.addressStreet1);
      customerInfo.addressStreet2 = this.formatAddress(model.addressStreet2);
      customerInfo.addressCity = model.addressCity;
      customerInfo.addressCountry = model.addressCountry;
      customerInfo.addressProvince = this.formatAddress(model.addressProvince);
      customerInfo.addressType = model.addressType;
      customerInfo.addressState = model.addressState;
      customerInfo.postalCode = model.postalCode;
      customerInfo.addressLine1Work = this.formatAddress(model.addressLine1Work);
      customerInfo.addressLine2Work = this.formatAddress(model.addressLine2Work);
      customerInfo.cityWork = model.cityWork;
      customerInfo.countryWork = model.countryWork;
      customerInfo.addressLine3Work = this.formatAddress(model.addressLine3Work);
      customerInfo.addressTypeWork = model.addressTypeWork;
      customerInfo.stateWork = model.stateWork;
      customerInfo.postalCodeWork = model.postalCodeWork;
      customerInfo.phone = this.formatPhone(model.phone);
      customerInfo.placeOfBirth = model.placeOfBirth;
      customerInfo.maritalStatus = model.maritalStatus;
      customerInfo.annualIncome = model.annualIncome;
      customerInfo.sourceOfFund = model.sourceOfFund;
      customerInfo.occupation = model.occupation;
      customerInfo.cif = model.cif;
      customerInfo.ktpAddress = model.ktpAddress;
      customerInfo.hashCode = model.hashCode;
      customerInfo.salutation = model.salutation;

      console.log('initializedData: customerInfo Value ==> ', customerInfo);
    } catch (e) {
      console.log('initializedData: Error ==> ', e);
    }
  }

  formatDate(date?: string): any {
    const initialDate = date ? date?.split('-') : ['0', '0', '0'];
    return initialDate[0] + '-' + initialDate[1] + '-' + initialDate[2] + 'T00:00:00+00:00';
  }

  formatAddress(address: string): string {
    return address?.replace(/[^a-zA-Z0-9 ]/g, " ");
  }

  formatPhone(phoneNo: string): string {
    return phoneNo?.replace(/[^a-zA-Z0-9 ]/g, "");
  }

  initializeEligibilityData(eligibilityData: any, customerDetails: any, eligibilityPayload: EligibilityInfo): void {
    eligibilityData.fullName = customerDetails.fullName;
    eligibilityData.dob = this.formatDate(customerDetails.dob);
    eligibilityData.gender = customerDetails.gender;
    eligibilityData.nationality = customerDetails.nationality;
    eligibilityData.phone = this.formatPhone(customerDetails.phone);
    eligibilityData.addressStreet1 = this.formatAddress(customerDetails.addressStreet1);
    eligibilityData.addressStreet2 = this.formatAddress(customerDetails.addressStreet2);
    eligibilityData.addressCity = customerDetails.addressCity;
    eligibilityData.addressState = customerDetails.addressState;
    eligibilityData.addressCountry = customerDetails.addressCountry;
    eligibilityData.addressProvince = this.formatAddress(customerDetails.addressProvince);
    eligibilityData.addressType = customerDetails.addressType;
    eligibilityData.hashCode = customerDetails.hashCode;

    eligibilityPayload.customerInfo = eligibilityData;
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MliAnalyticsService } from '@mli/analytics';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  eventCategory = '';
  dbsParams: any = {};

  constructor(
    private mliAnalyticsService: MliAnalyticsService,
    public router: Router
  ) {

   }

  ngOnInit(): void {
    this.dbsParams = JSON.parse(sessionStorage.getItem('dbsParams') || '{}');
  }

  isContactClicked(contact: string): void {
    this.eventCategory = this.getEventCategory(this.router.url);
    if (contact === 'first') {
      this.mliAnalyticsService?.trackEvent({
        event_label: 'Contact center',
        event_category: this.eventCategory,
        event_type: 'Click button_call contact center 1',
        dbs_adobe_id: this.dbsParams.adobeId,
        dbs_marketing_id: this.dbsParams.marketingId,
        dbs_authcode: this.dbsParams.authCode,
      });
    } else {
      this.mliAnalyticsService?.trackEvent({
        event_label: 'Contact center',
        event_category: this.eventCategory,
        event_type: 'Click button_call contact center 2',
        dbs_adobe_id: this.dbsParams.adobeId,
        dbs_marketing_id: this.dbsParams.marketingId,
        dbs_authcode: this.dbsParams.authCode,
      });
    }
  }

  getEventCategory(route: string): string {
    switch (route) {
      case '/mi-flip/build-your-plan':
        return 'Build your plan';
      case '/mi-flip/health-declaration':
        return 'Health declaration';
      case '/mi-flip/add-other-information':
        return 'User information';
      case '/mi-flip/application-summary':
        return 'Registration form';
      case '/completion-loader':
        return 'Succeed Page';
      default:
        return '';
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { Subject } from 'rxjs';
import { DbsRedirectionService } from '../_shared/dbs-redirection.service';
import { SharedService } from '../_shared/shared.service';

@Component({
  selector: 'app-failed-completion',
  templateUrl: './failed-completion.component.html',
  styleUrls: ['./failed-completion.component.scss']
})
export class FailedCompletionComponent implements OnInit {
  backDigibank: CdsButtonConfig = {
    label: 'Kembali ke digibank',
    buttonType: 'button',
    style: 'secondary',
    size: 'sm'
  };
  destroySub = new Subject();

  constructor(
    private _dbsshared: DbsRedirectionService,
    private _shared: SharedService
  ) {
    this._shared?.implementTimeout(false, this.destroySub);
  }

  ngOnInit(): void {
    sessionStorage.setItem('paymentCompleted', 'false');
  }

  backToDigibank(): void {
    sessionStorage.clear();
    this._dbsshared.navigateToDashboard();
  }
}
